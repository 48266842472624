import React, { Component } from 'react';
import CodeMirror from 'react-codemirror';
import _ from 'underscore';

//Components
import SaveCancel from '../../components/SaveCancel/SaveCancel';

// addons
import "codemirror/addon/fold/foldgutter.js"
import "codemirror/addon/fold/brace-fold.js"
import "codemirror/addon/fold/xml-fold.js"
import "codemirror/addon/fold/indent-fold.js"
import "codemirror/addon/fold/markdown-fold.js"
import "codemirror/addon/fold/comment-fold.js"
import "codemirror/mode/xml/xml.js"
import "codemirror/mode/markdown/markdown.js"
import "codemirror/addon/search/searchcursor"
import "codemirror/addon/search/search"
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
// import cmaddon15 from "codemirror/addon/selection/active-line"

/**
 * XMLEditor component
 * @class XMLEditor
 * @extends {Component}
 */

let errorHistroy = []
class XMLEditor extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            errorIndex: 0,
            editorSelector: 0,
            xmlString: '',
            isEdited: false,
            projectLines: [],
            errorWithProjectPos: [],
            projectIndex: 0,
            prevSearch: []
        }


    }
    /**
     * Get CodeMirror text area
     * @returns 
     */


    getCM = () => {
        if (this.codeMirror && this.codeMirror.getCodeMirror()) {
            return this.codeMirror.getCodeMirror();
        }
        return null;
    }
    /**
     * Get Project lines
     * 
     * [1]  -   Get codeMirror text area and CodeMirror doc
     * [2]  -   Tags that represents start and end of project
     * [3]  -   looping tags to find project lines
     * [4]  -   Search the tag in CodeMirror text area
     * [5]  -   Find start of the projects
     * [6]  -   Find end of the projects
     * [7]  -   Mapping start and end of projects
     * [8]  -   Map projects with lines
     */
    getProjectsLine = () => {
        let { errors } = this.props
        // [1]
        let cm = this.getCM();
        const doc = cm.getDoc();
        let projectLines = [];
        let openTags = [];
        let closeTags = [];
        // [2]
        let tags = ['<Table1_Import_Record>', '</Table1_Import_Record>'];
        // [3]
        tags.forEach((tag, i) => {
            // [4]
            const cursor = doc.getSearchCursor(tag, false);
            while (cursor.findNext()) {
                // [5]
                if (i === 0) {
                    openTags.push({ from: cursor.from(), to: cursor.to() })
                }
                // [6]
                if (i === 1) {
                    closeTags.push({ from: cursor.from(), to: cursor.to() })
                }
            }
        })
        // [7]
        openTags.forEach((tag, i) => {
            projectLines[i] = { from: tag.from, to: closeTags[i].to }
        })
        // [8]
        errors.forEach((error, i) => {
            errors[i].projectPos = projectLines[error.projectIndex]
        })
        this.setState({ projectLines: projectLines, errorWithProjectPos: errors });
    }
    /**
     * Code Mirror
     * 
     * [1]  -   Get codeMirror text area and CodeMirror doc
     * [2]  -   Get project lines
     * [3]  -   Error classifications
     * [3.1] -  Error Text mark for HOH name scenarios
     * [4]  -   Error Text mark for report year
     * [5]  -   Error Text mark for errors with line number
     * [6]  -   Error Text mark for errors with search tags
     * [7]  -   Error Text mark for errors with unitTransferedToError tags
     */
    cm = () => {
        const { errors } = this.props;
        let { projectLines, projectIndex } = this.state;

        if (this.getCM()) {
            // [1]
            let cm = this.getCM();
            const doc = cm.getDoc();
            // [2]
            projectLines.length === 0 && this.getProjectsLine();
            // [3]
            if (errors.length > 0 && projectLines.length > 0) {
                let errorsWithLine = [];
                let unitTransferedToError = [];
                let errorsWithSearch = [];
                projectIndex < projectLines.length && errors.forEach((error, i) => {
                    // [4]
                    if (error.tag === 'ReportYear') {
                        error.search.forEach(tag => {
                            const cursor = doc.getSearchCursor(tag, false);
                            while (cursor.findNext()) {
                                doc.markText(cursor.from(), cursor.to(),
                                    { css: "background-color:#e32;color: white", attributes: { title: error.message } },
                                    { addToHistory: true }
                                );
                            }
                        })
                    }
                    if(error.title==="Error Message"){
                        doc.markText(
                            { line: 3, ch: 0 },
                            { line: 6, ch: 100 },
                            { css: "background-color:#e32;color: white", attributes: { title: error.message } },
                            { addToHistory: true }
                        );
                    }
                    //[3.1]
                    if (error.title === 'Household Full Name' || error.title === 'Household First Name' || error.title === 'Household Last Name') {
                        let lineNumber = null;
                        for(i=error.projectPos.from.line;i<=error.projectPos.to.line;i++){
                            if(doc.getLine(i).trim()===error.project[0].trim()){
                                lineNumber=i
                            }
                        }
                        if(lineNumber===null){
                            doc.markText(
                                { line: error.projectPos.from.line+1, ch: 0 },
                                { line: error.projectPos.from.line+5, ch: 0 },
                                { css: "background-color:#e32;color: white", attributes: { title: error.message } },
                                { addToHistory: true }
                            );
                        }else{
                        //const start = errors[error.errorIndex].line - 1;
                        if(error.title === 'Household Full Name' || error.title === 'Household First Name' || error.title === 'Household Last Name'){
                            doc.markText(
                                { line: lineNumber, ch: 0 },
                                { line: lineNumber, ch: 100 },
                                { css: "background-color:#e32;color: white", attributes: { title: error.message } },
                                { addToHistory: true }
                            );
                        }else{
                        doc.markText(
                            { line: lineNumber, ch: 0 },
                            { line: lineNumber, ch: 100 },
                            { css: "background-color:#e32;color: white", attributes: { title: error.message } },
                            { addToHistory: true }
                        );
                        }
                    }
                    }
                    if (error.line) {
                        errorsWithLine.push({ ...error, errorIndex: i });
                    }
                    if (error.search && error.tag !== 'ReportYear') {
                        if (error.search[0] === '<UnitTransferedTo>') {
                            unitTransferedToError.push({ ...error, errorIndex: i });
                        }
                        if (error.search[0] !== '<UnitTransferedTo>') {
                            errorsWithSearch.push({ ...error, errorIndex: i });
                        }
                    }
                })
                // [5]
                errorsWithLine.length > 0 && errorsWithLine.forEach(error => {
                    const start = errors[error.errorIndex].line - 1;
                    const lineContentLength = doc.getLine(start).length;
                    doc.markText(
                        { line: start, ch: 0 },
                        { line: start, ch: lineContentLength },
                        { css: "background-color:#e32;color: white", attributes: { title: error.message } },
                        { addToHistory: true }
                    );
                })
                // [6]
                errorsWithSearch.length > 0 && errorsWithSearch.forEach((error, i) => {
                    error.search && error.search.forEach((tag, index) => {
                        let split1 = tag.split('<');
                        let split2 = split1[1].split('>');
                        let regexTag = RegExp(`<${split2[0]}[^>]*>${split2[1]}</${split2[0]}>`);
                        const cursor = doc.getSearchCursor(regexTag, false);
                        while (cursor.findNext()) {
                            let tagPos = { from: cursor.from(), to: cursor.to() };
                            if (tagPos.from.line >= error.projectPos.from.line && tagPos.to.line <= error.projectPos.to.line) {
                                doc.markText(cursor.from(), cursor.to(),
                                    { css: "background-color:#e32;color: white", attributes: { title: error.message } },
                                    { addToHistory: true }
                                );
                            }
                        }
                    })
                })
                // [7]
                unitTransferedToError.length > 0 && unitTransferedToError.forEach((error, i) => {
                    error.search.forEach((tag, index) => {
                        let regexTag = null;
                        if (index === 0 || index === error.search.length - 1) {
                            regexTag = tag;
                        } else {
                            let split1 = tag.split('<');
                            let split2 = split1[1].split('>');
                            regexTag = RegExp(`<${split2[0]}[^>]*>${split2[1]}</${split2[0]}>`);
                        }
                        const cursor = doc.getSearchCursor(regexTag, false);
                        while (cursor.findNext()) {
                            let tagPos = { from: cursor.from(), to: cursor.to() };
                            if (tagPos.from.line >= error.projectPos.from.line && tagPos.to.line <= error.projectPos.to.line) {
                                doc.markText(cursor.from(), cursor.to(),
                                    { css: "background-color:#e32;color: white", attributes: { title: error.message } },
                                    { addToHistory: true }
                                );
                            }
                        }
                    })
                })
            }
        }
    }
    /**
     * Change in Editor
     * @param {*} newData
     * 
     * [1]  -   Get cursor position 
     * [2]  -   Marking the line
     */
    changeInEditor = (newData) => {
        this.setState({ xmlString: newData, isEdited: true })
        let cm = this.getCM();
        // [1]
        const cursorPos = cm.getCursor();
        // [2]
        this.changeMarkText(cursorPos);
    }
    /**
     * Mark the line that changes
     * @param {*} pos 
     * 
     * [1]  -   Get codeMirror text area and CodeMirror doc
     * [2]  -   Line Highlight
     */
    changeMarkText = (pos) => {
        const { errors } = this.props;
        // [1]
        let cm = this.getCM();
        const doc = cm.getDoc();
        // [2]
        if (errors.length > 0) {
            const start = pos.line;
            const lineContentLength = doc.getLine(start).length;
            doc.markText(
                { line: start, ch: 0 },
                { line: start, ch: lineContentLength },
                { css: "background-color:#4CAF50;color: white,border-color:white" },
                { addToHistory: true }
            );
        }
    }
    /**
     * Remove all marks
     * 
     * [1]  -   Get codeMirror text area and CodeMirror doc
     * [2]  -   removing marks
     */
    removeAllMarks = () => {
        // [1]
        let cm = this.getCM();
        const doc = cm.getDoc();
        // [2]
        let markers = doc.getAllMarks();
        if (markers.length > 0) {
            markers.map(marker => marker.clear());
        }
    }
    /**
     * Scroll to error line
     * @param {*} error 
     * @param {*} index 
     * 
     * [0]  -   Search HOH tag positions
     * [1]  -   Get codeMirror text area and CodeMirror doc
     * [2]  -   Search the tag position
     * [3]  -   Scroll to the tag line
     */
    scrollToError = (error, index) => {
        let { errorWithProjectPos, prevSearch,prevprojectPos,prevLine } = this.state;
        let { search, line, projectPos } = errorWithProjectPos[index] || error || null;
        let cm = this.getCM();
        const doc = cm.getDoc();
        //[0]
        if(error.title === 'Household Full Name' || error.title === 'Household First Name' || error.title === 'Household Last Name'){
            let lineNumber = null;
                        for(let i=error.projectPos.from.line;i<=error.projectPos.to.line;i++){
                            if(doc.getLine(i).trim()===error.project[0].trim()){
                                lineNumber=i+1
                            }
                        }
        line = (line===null && lineNumber===null)?projectPos.from.line+2:(line===null && lineNumber!==null)?lineNumber: line
        }

        if (!_.isEqual(search, prevSearch) || !_.isEqual(projectPos, prevprojectPos) || !_.isEqual(line, prevLine)) {
            this.setState({ prevSearch: search, prevprojectPos: projectPos, prevLine: line })
            errorHistroy.push(prevSearch)
        }
        // [1]
        
        let pos = null;
        // [2]
        if (!_.isEqual(search, prevSearch) || !_.isEqual(projectPos, prevprojectPos) || !_.isEqual(line, prevLine)) {

        if (this.state.prevprojectPos || this.state.prevLine) {
            if(prevSearch&&prevSearch.length>0){
            prevSearch && prevSearch.forEach((tag, index) => {
                const prevCursor = doc.getSearchCursor(tag, false);
                while (prevCursor.findNext()) {
                    let prevTagPos = { from: prevCursor.from(), to: prevCursor.to() };
                    if (prevTagPos.from.line >= this.state.prevprojectPos.from.line && prevTagPos.to.line <= this.state.prevprojectPos.to.line) {
                        doc.markText({ line: prevTagPos.from.line, ch: this.state.prevprojectPos.from.line - 1 }, { line: prevTagPos.to.line, ch: this.state.prevprojectPos.from.line },
                            { css: "background-color:#e32;color: white;border:none !important" },
                            { addToHistory: true }
                        );
                        doc.markText({ line: prevTagPos.from.line, ch: prevCursor.to().ch - 1 }, { line: prevTagPos.to.line, ch: prevCursor.to().ch },
                            { css: "background-color:#e32;color: white;border:none !important" },
                            { addToHistory: true }
                        );
                        doc.markText(prevCursor.from(), prevCursor.to(),
                            { css: "background-color:#e32;color: white;border:none" },
                            { addToHistory: true }
                        );
                    }
                }
            })
        }else{
                const lineContentLength = doc.getLine(this.state.prevLine-1).length;
                doc.markText({ line: this.state.prevLine-1, ch:0 }, { line: this.state.prevLine-1, ch: 1 },
                    { css: "background-color:#e32;color: white;border:none !important" },
                    { addToHistory: true }
                );
                doc.markText({ line: this.state.prevLine-1, ch: 1 }, { line: this.state.prevLine-1, ch: lineContentLength-1 },
                            { css: "background-color:#e32;color: white;border:none !important" },
                            { addToHistory: true }
                        );
                        doc.markText({ line: this.state.prevLine-1, ch: lineContentLength-1 }, { line: this.state.prevLine-1, ch: lineContentLength },
                            { css: "background-color:#e32;color: white;border:none !important" },
                            { addToHistory: true }
                        );
        }
        }
    }
        if (projectPos || line) {
            if(search&&search.length>0){
            search && search.forEach((tag, index) => {
                let split1 = tag.split('<');
                let split2 = split1[1].split('>');
                let regexTag = RegExp(`<${split2[0]}[^>]*>${split2[1]}</${split2[0]}>`);
                
                const cursor = doc.getSearchCursor(regexTag, false);
                while (cursor.findNext()) {
                    let tagPos = { from: cursor.from(), to: cursor.to() };
                    error.tag === 'ReportYear' && projectPos === undefined ? projectPos = tagPos : projectPos = projectPos;
                    if (tagPos.from.line >= projectPos.from.line && tagPos.to.line <= projectPos.to.line) {
                        doc.markText({ line: tagPos.from.line, ch: projectPos.from.line - 1 }, { line: tagPos.to.line, ch: projectPos.from.line },
                            { css: "background-color:#e32;color: white;border-width: 2px 0px 2px 2px !important; border-style: solid !important; border-color: black !important" },
                            { addToHistory: true }
                        );
                        doc.markText({ line: tagPos.from.line, ch: cursor.to().ch - 1 }, { line: tagPos.to.line, ch: cursor.to().ch },
                            { css: "background-color:#e32;color: white;border-width: 2px 2px 2px 0px !important; border-style: solid !important; border-color: black !important" },
                            { addToHistory: true }
                        );
                        doc.markText(cursor.from(), cursor.to(),
                            { css: "border-style: solid;border-color: black;border-width:2px 0px;background-color:#e32;color: white", attributes: { title: error.message } },
                            { addtohistory: true });
                    }
                    // }
                    if (index === 0 && tagPos.from.line >= projectPos.from.line && tagPos.to.line <= projectPos.to.line) {
                        pos = tagPos;
                    }
                }
            })
        }else{
                const lineContentLength = doc.getLine(line-1).length;
                doc.markText({ line: line-1, ch:0 }, { line: line-1, ch: 1 },
                    { css: "background-color:#e32;color: white;border-width: 2px 0px 2px 2px !important; border-style: solid !important; border-color: black !important" },
                    { addToHistory: true }
                );
                doc.markText({ line: line-1, ch: 1 }, { line: line- 1, ch: lineContentLength-1 },
                    { css: "border-style: solid !important;border-color: black !important;border-width:2px 0px !important;background-color:#e32;color: white", attributes: { title: error.message } },
                    { addToHistory: true }
                        );
                        doc.markText({ line: line-1, ch: lineContentLength-1 }, { line: line-1, ch: lineContentLength },
                            { css: "background-color:#e32;color: white;border-width: 2px 2px 2px 0px !important; border-style: solid !important; border-color: black !important" },
                            { addToHistory: true }
                        );
        }
            // [3]
            let scrollTo = line ? line : pos ? pos : projectPos;
            if (scrollTo) {
                cm.scrollIntoView(scrollTo, 100);
            }
        }
    }
    /**
     * Error list
     * @param {*} error 
     * @param {*} index 
     * @returns 
     */
    // renderErrors = (error, index) => {
    //     let message = error.error || error.message;
    //     return (
    //         <div
    //             key={index}
    //             className="error-list"
    //             onClick={() => this.scrollToError(error, index)}>
    //             {message}
    //         </div>
    //     )
    // }
    /**
     * Handle Save Click Action
     */
    handleSave = () => {

        const { xmlString } = this.state;
        this.removeAllMarks();
        this.setState({ isEdited: false })
        this.props.onSave(xmlString)
    }
    /**
     * Bind HTMl to reactDOM
     * @returns 
     */


    render() {
        const { xmlFormattedString, errors, saveEditXml } = this.props;
        const { isEdited } = this.state
        setTimeout(() => {
            if (!isEdited && this.getCM()) {
                if (errors.length > 0) {
                    this.cm();
                }
            }
        }, 500)
        if (saveEditXml) {
            this.handleSave();
        }
        return (
            <React.Fragment>
                {/* <div className="xml-preview"  > */}

                <div className="xml-codemirror" >
                    <CodeMirror
                        ref={(c) => { this.codeMirror = c }}
                        value={xmlFormattedString}
                        onChange={(newData) => this.changeInEditor(newData)}
                        options={{
                            lineNumbers: true,
                            lineWrapping: true,
                            // styleActiveLine: true,
                            extraKeys: { "Ctrl-Q": function (cm) { cm.foldCode(cm.getCursor()); } },
                            foldGutter: true,
                            gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
                            foldOptions: {
                                rangeFinder: (cm, pos) => {
                                    let start = pos.line;
                                    let lineContent = cm.getDoc().getLine(start);
                                    let end = start + 1;
                                    if (lineContent.indexOf("<Table1_Import_Record>") >= 0) {
                                        let loop = true;
                                        do {
                                            lineContent = cm.getDoc().getLine(end);
                                            if (lineContent.indexOf("</Table1_Import_Record>") >= 0) {
                                                loop = false;
                                            } else {
                                                end = end + 1;
                                            }
                                        } while (loop);
                                        return { from: { line: start }, to: { line: end } };
                                    }
                                    return;
                                }
                            }
                        }}
                    />
                    <Grid container justifyContent="flex-end" alignItems="center">
                        {/* <Grid>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={skipWarnings}
                                                    // value={2}
                                                    color="primary"
                                                    onChange={() => this.props.handleSkipWarning()}
                                                />
                                            }
                                            label="Skip Warnings"
                                        />
                                    </Grid> */}
                        {/* <Grid item>
                                        <SaveCancel
                                            handleSaveButtonClick={() => { this.handleSave() }}
                                            handleCancelButtonClick={this.props.onCancel}
                                            saveText={"Save"}
                                            cancelText={"Cancel"}
                                        />
                                    </Grid> */}
                    </Grid>
                </div>


                {/* </div> */}
            </React.Fragment>
        )
    }
}
/** Default props */
XMLEditor.defaultProps = {
    errors: [],
}
/** Export Component */
export default XMLEditor;