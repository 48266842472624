import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Item from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import copy from 'clipboard-copy';

//import Component
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import TextBox from '../../components/TextBox/TextBox';
import DropDown from '../../components/DropDown/DropDown';
import FileUpload from '../../components/FileUpload/FileUpload';
import SaveCancel from '../../components/SaveCancel/SaveCancel';
import PageLoader from '../../components/PageLoader/PageLoader';
import XMLEditor from "./XMLEditor";
import XMLEditorError from "./XMLEditorError";
import HelpFloater from "../../components/HelpFloater/HelpFloater";

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Actions
import { uploadActions } from '../../actions/uploads';
import { helpActions } from '../../actions';
import { Table1LogActions } from '../../actions/Table1Log';


// helpers
import { formatDateWithPrefixZero,formatDate, getUser, history, globalVariable, xmlFormatter, authHeader, handleBulkResponse } from '../../helpers';
import ValidatorForm from "../../helpers/fieldValidations";
import { SatelliteSharp, Save } from '@material-ui/icons';

// Imports Components
import ToolTip from '../../components/ToolTip/ToolTip';

// SVG Icons
import { XmlCopyIcon, XmlFolderIcon, DownloadIcon, XmlSettingsIcon, FilterIcon, HelpIcon, XmlWarningIcon, XmlZoomInIcon, XmlZoomOutIcon, PlusIcon, XmlIcon, SaveIcon , DownloadIconInXMLNotification } from '../../components/SvgIcons/SvgIcons';
import { alertActions } from '../../actions';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import XmlHelpDialog from "../../components/XmlHelpDialog/XmlHelpDialog";
/**
 * Table1Import container
 * @class Table1Import
 * @extends {Component}
 */
class Table1Import extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        const { firstName, lastName, org, id } = getUser();
        this.state = {
            user_name: `${firstName} ${lastName}`,
            user_org: org ? org.name : '',
            user_id: id,
            importDate: formatDateWithPrefixZero(new Date().toISOString()),
            importYear: new Date().getFullYear(),
            importYears: [{_id:1,key:new Date().getFullYear(),name:new Date().getFullYear(),label:new Date().getFullYear()}, {_id:2,key:new Date().getFullYear()-1,name:new Date().getFullYear()-1,label:new Date().getFullYear()-1}, {_id:3,key:new Date().getFullYear()-2,name:new Date().getFullYear()-2,label:new Date().getFullYear()-2}],
            data: null,
            file: null,
            startImported: false,
            label: 'Select XML file to upload',
            isLoading: false,
            xmlFormattedString: '',
            editorOpened: false,
            saveEditXml: false,
            fullScreenXmlEdittor: false,
            fullScreenXmlErrorEdittor: false,
            fullScreenFlag: false,
            editXmlFlag: true,
            saveDownloadFlag:false,
            initialUpload:true,
            initialUploadSuccessFlag:false,
            isXmlEdit:false,
            openHelp: false,
            xmlErrorsContent:null,
            checkXmlInitialDownload:false,
            noValidateDownloadFlag:false,
            openValidationPopup:false,
            validationTitle:'',
            validationMessage:''
            // skipWarnings: false
        };

    }

    UNSAFE_componentWillMount() {
    // if(history.location.state.pageData.screen&&history.location.state.pageData.screen==='edit'){
    //     this.setState({initialUpload:false})
    // }
    }
    /**
     * Handle File Select Action
     * @param {*} acceptedFiles 
     * @param {*} rejectedFiles 
     * 
     * [1]  -   Reject the invalid xml file
     * [2]  -   Create a formedData for a valid xml
     */
    handleFileChange = (acceptedFiles, rejectedFiles) => {
        // [1]
        if (rejectedFiles.length === 0 && acceptedFiles.length === 1) {
            // [2]
            if(this.state.isXmlEdit){
                const { data} = this.state;
                const file = acceptedFiles[0];
                if (file.type !== 'text/xml') {
                    this.props.errorAlert('Please upload a XML file');
                    return;
                }
                 let formObject = Object.fromEntries(data.entries());
                data.has('name') ? data.set('name',file.name) : data.append('name',file.name);
                this.setState({ data: data,file: file, startImported: false, xmlFormattedString: null,initialUpload:false });
                let newFileUpadate  = true;
                setTimeout(()=>{
                    this.handleEditXmlFileUpload(newFileUpadate);
                },100)
            }else{
                const data = new FormData();
                const file = acceptedFiles[0];
                if (file.type !== 'text/xml') {
                    this.props.errorAlert('Please upload a XML file');
                    return;
                }
                data.append('name', file.name);
                data.append('actionStatus', `table1_import_${this.state.user_id}_${new Date().getTime()}`);
                data.has('handleSaveDownloadTag') ? data.set('handleSaveDownloadTag', 1) : data.append('handleSaveDownloadTag', 1);
                this.setState({ data: data, file: file, startImported: false, xmlFormattedString: null,initialUpload:true,isXmlEdit:true });
                setTimeout(()=>{
                    this.handleFileUpload();
                },100)
            }
           
        } else {
            this.setState({ data: null, startImported: false, label: 'Please select a valid XML file.' });
        }
    }
    /**
     * Handle Validate & Import Click Action
     * 
     * [1]  -   If formedData has importDate, then Update importDate, else insert importDate
     * [2]  -   If formedData has reportYear, then Update reportYear, else insert reportYear
     * [3]  -   If formedData has actionStatus, then Update actionStatus, else insert reportYear
     * [4]  -   If xml is a formatted string
     * [5]  -   create a new blob with formated file content
     * [6]  -   remove old file and append with formatted file
     * [7]  -   Preview
     * [8]  -   Import
     * [9]  -   read xml file and format it
     * [10]  -  format the file contents
     * [11]  -  create a new blob with formated file content
     * [12]  -  remove old file and append with formatted file
     * [13]  -   Preview
     * [14]  -   Import
     */
    handleFileUpload = (e) => {
        
        let { importProcess,location: { state } } = this.props;
        let pageDataFromEdit = state && state.pageData && state.pageData.item && state.pageData.item.filename;
        let dummyYear = parseInt('0000');
        if(this.state.initialUploadSuccessFlag){
            dummyYear =this.state.importYear;
        }
        if(this.state.saveDownloadFlag){
            dummyYear =this.state.importYear;
        }
       
      
        let { data, xmlFormattedString ,initialUpload,file } = this.state;
        if(xmlFormattedString===''){
            this.props.errorAlert('Please upload a XML file')
        }
        if(this.contentScroll&&this.contentScroll.state&&this.contentScroll.state.isEdited===true){
            xmlFormattedString = this.contentScroll&&this.contentScroll.state&&this.contentScroll.state.xmlString;
        }
        this.setState({ editXmlFlag: false});
        // this.setState({editorOpened: false });
        if (data !== null) {
            // [1]
            data.has('importDate') ? data.set('importDate', this.state.importDate) : data.append('importDate', this.state.importDate);
            // [2]
            data.has('reportYear') ? data.set('reportYear', dummyYear) : data.append('reportYear',dummyYear);
            data.has('organization') ? data.set('organization', this.state.user_org) : data.append('organization', this.state.user_org);
            data.has('initialUploadSuccessFlag') ? data.set('initialUploadSuccessFlag', this.state.initialUploadSuccessFlag) : data.append('initialUploadSuccessFlag', this.state.initialUploadSuccessFlag);
            // data.has('skipWarnings') ? data.set('skipWarnings', skipWarnings) : data.append('skipWarnings', skipWarnings);
            // [3]
            let formObject = Object.fromEntries(data.entries());
            
            data.has('existname') ? data.set('existname', formObject.existname) : data.append('existname', formObject.actionStatus);
            if (!importProcess.data || (importProcess.data && !importProcess.data.dummyResponse)) {
                data.has('actionStatus') ? data.set('actionStatus', `table1_import_${this.state.user_id}_${new Date().getTime()}`) : data.append('actionStatus', `table1_import_${this.state.user_id}_${new Date().getTime()}`);
            }
            data.has('handleSaveExecutionTag') ? data.set('handleSaveExecutionTag', 0) : data.append('handleSaveExecutionTag', 0);
           if(e){
            data.has('existname') ? data.set('existname', formObject.actionStatus) : data.append('existname', formObject.actionStatus);
            data.has('handleSaveDownloadTag') ? data.set('handleSaveDownloadTag', 1) : data.append('handleSaveDownloadTag', 1);
            data.has('handleSaveExecutionTag') ? data.set('handleSaveExecutionTag', 1) : data.append('handleSaveExecutionTag', 1);
           }
        //    else{
        //     data.has('handleSaveDownloadTag') ? data.set('handleSaveDownloadTag', 0) : data.append('handleSaveDownloadTag', 0);
        //    }
           data.has('initialUploadTag') ? data.set('initialUploadTag', initialUpload) : data.append('initialUploadTag', initialUpload);
        //    if(initialUpload){
        //       console.log(initialUpload);
        //    }else{
        //       console.log(initialUpload);
        //    }

            // [4]
            let fileName = file.name==='blob'? pageDataFromEdit : file.name;
            if (xmlFormattedString) {
                console.log('entered3')
                // [5]
                let blob = new Blob([xmlFormattedString], { type: 'text/xml' });
                // [6]
                data.delete('file');
                data.append('file', blob);
                data.has('name') ? data.set('name',fileName) : data.append('name',fileName);
                // [7]
                this.setState({ data: data, xmlFormattedString: xmlFormattedString, startImported: true, editorOpened: true });
                // [8]
                this.props.table1Import(data);
            } else {
                // [9]
                console.log('entered4')
                const reader = new FileReader()
                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                reader.onload = () => {
                    // [10]
                    const binaryStr = reader.result;
                    const xmlFormattedString = xmlFormatter(binaryStr);
                    // [11]
                    let blob = new Blob([xmlFormattedString], { type: 'text/xml' });
                    // [12]
                    data.delete('file');
                    data.append('file', blob);
                    data.has('name') ? data.set('name',fileName) : data.append('name',fileName);
                    // [13]
                    this.setState({ data: data, xmlFormattedString, startImported: true, editorOpened: true });
                    // [14]
                    this.props.table1Import(data);
                }

            

                reader.readAsText(this.state.file);
            }
        }
    }

    handleEditXmlFileUpload = (e) => {
          this.setState({isXmlEdit:false }); 
          this.setState({ saveEditXml: false,initialUpload:false, initialUploadSuccessFlag:true,
            // importYear:new Date().getFullYear() 
        });
          if(e){
            this.setState({ 
                initialUpload:true,importYear:parseInt('0000') 
            });
          }
        // this.setState({ saveEditXml: false,initialUpload:false, initialUploadSuccessFlag:true });
        // if(e){
        // this.setState({ initialUpload:true });
        // }
          let { data, xmlFormattedString } = this.state;
          let formObject = Object.fromEntries(data.entries());
          // [1]
          data.has('importDate') ? data.set('importDate', this.state.importDate) : data.append('importDate', this.state.importDate);
          // [2]
          data.has('organization') ? data.set('organization', this.state.user_org) : data.append('organization', this.state.user_org);
          data.has('reportYear') ? data.set('reportYear', this.state.importYear) : data.append('reportYear', this.state.importYear);
          data.has('actionStatus') ? data.set('actionStatus', `table1_import_${this.state.user_id}_${new Date().getTime()}`) : data.append('actionStatus', `table1_import_${this.state.user_id}_${new Date().getTime()}`);
          data.has('handleSaveDownloadTag') ? data.set('handleSaveDownloadTag', 0) : data.append('handleSaveDownloadTag', 0);
          data.has('existname') ? data.set('existname', formObject.actionStatus) : data.append('existname', formObject.actionStatus);
          data.has('initialUploadSuccessFlag') ? data.set('initialUploadSuccessFlag', this.state.initialUploadSuccessFlag) : data.append('initialUploadSuccessFlag', this.state.initialUploadSuccessFlag);
        


          const reader = new FileReader()
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {
              // [10]
              const binaryStr = reader.result;
              const xmlFormattedString = xmlFormatter(binaryStr);
              // [11]
              let blob = new Blob([xmlFormattedString], { type: 'text/xml' });
              // [12]
              data.delete('file');
              data.append('file', blob);
              // [13]
              this.setState({ data: data, xmlFormattedString, startImported: true, editorOpened: true });
              // [14]
              this.props.table1Import(data);
       
          }
      

          reader.readAsText(this.state.file);


    }
    /**
     * Handle Copy Action Click
     */

    handleCopy = () => {
        const { xmlFormattedString } = this.state;
        const { successAlert, errorAlert } = this.props;
       //if no change in editor get file from state or from ref state
        let xmlString = this.contentScroll.state.xmlString === '' ? xmlFormattedString : this.contentScroll.state.xmlString;
        copy(xmlString).then(copied=>
            {successAlert('File has been copied successfully!')}
        ).catch(err=>{
            errorAlert('File copy failed!')
        })
    }
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        const { editorOpened } = this.state
        if (editorOpened) {
            this.setState({ editorOpened: false, data: null, file: null, startImported: false, label: 'Please select a valid XML file.', xmlFormattedString: null })
            history.goBack();
        } else {
            history.goBack();
        }
    }
    /**
     * Handle Save Click Action
     * @param {*} newCode 
     * 
     * [1]  -   create a new blob with formated file content
     * [2]  -   remove old file and append with formatted file
     * [3]  -   Preview
     */
    handleSubmit = (e) => {
        console.log('entered');
        this.setState({ fullScreenXmlErrorEdittor: false ,fullScreenXmlEdittor:false,fullScreenFlag: false});
        setTimeout(()=>{
            this.setState({ saveEditXml: true,initialUpload:false , initialUploadSuccessFlag:true });
        },100)
       
    }

    handleSave = (newCode) => {
           
        this.setState({ saveEditXml: false,initialUpload:false, initialUploadSuccessFlag:true,isXmlEdit:true,noValidateDownloadFlag:false });
        let { data, xmlFormattedString } = this.state;
        xmlFormattedString = newCode ? newCode : xmlFormattedString;
        let formObject = Object.fromEntries(data.entries());
      
        // [1]
        data.has('importDate') ? data.set('importDate', this.state.importDate) : data.append('importDate', this.state.importDate);
        // [2]
        data.has('organization') ? data.set('organization', this.state.user_org) : data.append('organization', this.state.user_org);
        if(this.state.importYear == 0){
            data.has('reportYear') ? data.set('reportYear',new Date().getFullYear()) : data.append('reportYear', new Date().getFullYear());
        }else{
            data.has('reportYear') ? data.set('reportYear', this.state.importYear) : data.append('reportYear', this.state.importYear);
        }
        data.has('actionStatus') ? data.set('actionStatus', `table1_import_${this.state.user_id}_${new Date().getTime()}`) : data.append('actionStatus', `table1_import_${this.state.user_id}_${new Date().getTime()}`);
        data.has('handleSaveDownloadTag') ? data.set('handleSaveDownloadTag', 0) : data.append('handleSaveDownloadTag', 0);
        data.has('existname') ? data.set('existname', formObject.actionStatus) : data.append('existname', formObject.actionStatus);
        data.has('initialUploadSuccessFlag') ? data.set('initialUploadSuccessFlag', this.state.initialUploadSuccessFlag) : data.append('initialUploadSuccessFlag', this.state.initialUploadSuccessFlag);
        data.has('handleSaveExecutionTag') ? data.set('handleSaveExecutionTag', 0) : data.append('handleSaveExecutionTag', 0);
        // [1]
        let blob = new Blob([xmlFormattedString], { type: 'text/xml' });
        // [2]
        data.delete('file');
        data.append('file', blob);
        // [3]
        this.setState({ data: data, xmlFormattedString: xmlFormattedString, startImported: true, editorOpened: true});
        // [8]
        this.props.table1Import(data);

    }



    expandEdittor = (e) => {
        this.setState({ fullScreenXmlEdittor: true });
        this.setState({ fullScreenFlag: true });
    }

    minimizeEdittor = (e) => {
        this.setState({ fullScreenXmlEdittor: false });
        this.setState({ fullScreenFlag: false });
    }

    expandErrorEdittor = (e) => {
        this.setState({ fullScreenXmlErrorEdittor: true });
        this.setState({ fullScreenFlag: true });
    }

    minimizeErrorEdittor = (e) => {
        this.setState({ fullScreenXmlErrorEdittor: false });
        this.setState({ fullScreenFlag: false });
    }

    setsuccessfullFlag = (e) => {
        history.push({
            pathname: `/table1-import-success`,
            state: {
                page: 'table1ImportSuccess',
                pageData: {}
            }
        });
     }  

     executeEditXml = (item) => {
              this.setState({ editXmlFlag: false,initialUpload:false ,importYear:item.reportYear,initialUploadSuccessFlag:true,isXmlEdit:true }); 
              if(item.status ==  'PENDING' || item.status == 'Pending' ){
                this.setState({initialUpload:true,importYear:this.state.importYear }); 
              }
              const requestOptions = {
                        method: 'POST',
                        headers: {
                            ...authHeader()
                        },
                    
                    body: JSON.stringify({item })
                };

            fetch(`${globalVariable.ms_apiURL}/table1-xml-file`, requestOptions).then(response => {
                response.blob().then(blob => {
                    var dataform = new FormData();
                    dataform.append('file', blob);

                    const datasend = new FormData();

                    const file = dataform.get('file');
                    datasend.append('file', file);
                    datasend.append('name', item.filename);
                    datasend.append('actionStatus', `table1_import_${this.state.user_id}_${new Date().getTime()}`);
                    const existName = item.newfileName.split('.')[0];
                    datasend.has('organization') ? datasend.set('organization', this.state.user_org) : datasend.append('organization', this.state.user_org);
                    datasend.has('existname') ? datasend.set('existname',existName) : datasend.append('existname',existName);
                    this.setState({ data: datasend, file: file, startImported: false, xmlFormattedString: null,editXmlFlag: false });

                    this.handleFileUpload();
                
                });

            });

     }
     //Function to download the error file

    //  handleDownloadHtmActionClick = (item) => {
    //     const file = `${item.data.fileName.split('.')[0]}.htm`
    //     this.props.Table1ErrorLog({fileName : file})
    // };
    handleDownloadHtmActionClick = (item) => {
        const {tablelogLoading} = this.props;
        if (!tablelogLoading) {
            const file = `${item.data.fileName.split('.')[0]}.htm`
            this.props.Table1ErrorLog({fileName : file})
            
        } else {
            this.props.successAlert('Please wait...Download already in progress');
            setTimeout(() => {
                this.props.clearAlert();
            }, 3000)
        }
       
    }
       
    


     
     handleSaveDownload = () => {
        console.log('ENTERED1')
        if(!this.state.xmlFormattedString){
            this.props.errorAlert('Please upload a XML file');
            return;
        }
        let xmlString = this.contentScroll.state.xmlString;
         this.setState({ saveDownloadFlag: true,initialUpload:false,xmlFormattedString:xmlString,checkXmlInitialDownload:true});
         let handleSaveDownloadTag = true;
         this.handleFileUpload(handleSaveDownloadTag);

     }


     handleSaveDownloadXmlInEdit = (xmlerrorsData) => {
        
            if(!this.state.xmlFormattedString){
                this.props.errorAlert('Please upload a XML file');
                return;
            }
            let xmlString = this.contentScroll.state.xmlString;
            this.setState({ saveDownloadFlag: true,initialUpload:false,xmlFormattedString:xmlString,xmlErrorsContent:xmlerrorsData,checkXmlInitialDownload:false,noValidateDownloadFlag:true});
            this.handleSaveDownloadFunction(xmlString);
    }
     

    
    handleSaveDownloadFunction = (newCode) => {
        
        this.setState({ saveEditXml: false,initialUpload:true, initialUploadSuccessFlag:true,isXmlEdit:true });
        let { data, xmlFormattedString } = this.state;
        xmlFormattedString = newCode ? newCode : xmlFormattedString;
        let formObject = Object.fromEntries(data.entries());
      
        // [1]
        data.has('importDate') ? data.set('importDate', this.state.importDate) : data.append('importDate', this.state.importDate);
        // [2]
        data.has('organization') ? data.set('organization', this.state.user_org) : data.append('organization', this.state.user_org);
        if(this.state.importYear == 0){
            data.has('reportYear') ? data.set('reportYear',new Date().getFullYear()) : data.append('reportYear', new Date().getFullYear());
        }else{
            data.has('reportYear') ? data.set('reportYear', this.state.importYear) : data.append('reportYear', this.state.importYear);
        }
        data.has('actionStatus') ? data.set('actionStatus', `table1_import_${this.state.user_id}_${new Date().getTime()}`) : data.append('actionStatus', `table1_import_${this.state.user_id}_${new Date().getTime()}`);
        data.has('handleSaveDownloadTag') ? data.set('handleSaveDownloadTag', 0) : data.append('handleSaveDownloadTag', 0);
        data.has('existname') ? data.set('existname', formObject.actionStatus) : data.append('existname', formObject.actionStatus);
        data.has('initialUploadSuccessFlag') ? data.set('initialUploadSuccessFlag', this.state.initialUploadSuccessFlag) : data.append('initialUploadSuccessFlag', this.state.initialUploadSuccessFlag);
        data.has('handleSaveExecutionTag') ? data.set('handleSaveExecutionTag', 1) : data.append('handleSaveExecutionTag', 1);
     
        // [1]
        let blob = new Blob([xmlFormattedString], { type: 'text/xml' });
        // [2]
        data.delete('file');
        data.append('file', blob);
        // [3]
        this.setState({ data: data, xmlFormattedString: xmlFormattedString, startImported: true, editorOpened: true});
        // [8]
        this.props.table1Import(data);

    }





     handleSaveDownloadXml = (xmlFormattedString,xmlErrors,filename) => {

        if(this.state.checkXmlInitialDownload){
            this.setState({ saveDownloadFlag: false,initialUpload:true});
        }
        else{
            this.setState({ saveDownloadFlag: false});
        }
       
        // this.setState({ editorOpened: false });
        
        console.log('Entered!!!!!!!!')
       console.log("🚀 ~ file: Table1.js ~ line 332 ~ Table1Import ~ render ~ xmlFormattedString", xmlFormattedString)
       let blob = new Blob([xmlFormattedString], { type: 'text/xml' });
       let formData = new FormData()
       formData.append('file', blob);
       console.log(formData)
       const requestOptions = {
           method: 'POST',
           headers: {
               ...authHeader()
           },
           body: JSON.stringify({
            xmlFormattedString,
               errors: xmlErrors
           })
       };
       
       fetch(`${globalVariable.ms_apiURL}/table1-error`, requestOptions).then(response => {
           response.blob().then(blob => {
               let url = window.URL.createObjectURL(blob);
               let a = document.createElement('a');
               a.href = url;
               a.download = filename.split('.')[0]+'.html';
               a.click();
           });
       
       });


    }

     
        
            
    /**
     * Formatted response
     * @param {*} responseData 
     * @returns 
     */
    formatReponse(responseData) {
        // const { skipWarnings } = this.state;
        if (responseData) {
            // if (skipWarnings) this.setState({ skipWarnings: false })
            return <div dangerouslySetInnerHTML={{ __html: responseData }}></div>
        }
        return null;
    }

    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }

    handleAutoCompleteState = (selectedOption) => {
        this.setState({ importYear: selectedOption.name });
    }


    helpIconMessage = (error,index) => {
      const errorTitle = (error.title != undefined) ? error.title : 'Error Message';
      const errorDescription = (error.description != undefined) ? error.description : error.message;
      this.setState({ openValidationPopup: true,  validationTitle:errorTitle, validationMessage:errorDescription});
    }
    handleCloseAction = () => {
        this.setState({ openValidationPopup: false });
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { location: { state } } = this.props;
        let { classes, importProcess: { isLoading, data }, pageAccess,helpData } = this.props;
        let { user_name, user_org, importDate, importYear, importYears, file, startImported, label, xmlFormattedString, editorOpened, saveEditXml, fullScreenXmlEdittor, fullScreenXmlErrorEdittor, fullScreenFlag, editXmlFlag ,saveDownloadFlag,initialUpload,initialUploadSuccessFlag,openHelp,xmlErrorsContent} = this.state;
        data = data || {};
        if (pageAccess.indexOf("SAVE") === -1 && pageAccess.length > 0) {
            return "NO ACCESS";
        }
        if (state && state.pageData) {
            if (state.pageData.item) {
               if(editXmlFlag){
                let item = state.pageData.item;
                  this.executeEditXml(item);
                }
            }
        }
        if (data && data.errors) {
            if (data.errors.length == 0 && editorOpened == true && initialUpload==false) {
                this.setsuccessfullFlag();
            }
        }
        if (startImported && data.dummyResponse && initialUploadSuccessFlag ) {
            this.setsuccessfullFlag();
        }
        let pageDataFromEdit = state && state.pageData && state.pageData.item && state.pageData.item.filename;
        if(saveDownloadFlag){
            if(xmlFormattedString != null){
                 let xmlString = this.contentScroll.state.isEdited === false ? this.state.xmlFormattedString : this.contentScroll.state.xmlString;
                 this.setState({xmlFormattedString:xmlString})
                 let fileName = file.name==='blob'?pageDataFromEdit:file.name;
                 this.handleSaveDownloadXml(xmlString,xmlErrorsContent,fileName);
            }  
         }

        editorOpened = data && data.errors ? editorOpened : false;
        let pageTitle = !editorOpened ? "XML Editor" : file && file.name === 'blob' ? "XML Editor" : "XML Editor";
        //let pageTitle = "XML Data Import";
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={pageTitle}
                needPadding={true}
                isSubPage={false}
                handleBackActionClick={this.handleBackActionClick}
                handleSubmit={this.handleSubmit}
                expandEdittor={this.expandEdittor}
                minimizeEdittor={this.minimizeEdittor}
                handleHelpActionClick={this.handleHelpActionClick}
                expandErrorEdittor={this.expandErrorEdittor}
                minimizeErrorEdittor={this.minimizeErrorEdittor}
                handleSaveDownload={this.handleSaveDownload}
               handleSaveDownloadXmlInEdit={this.handleSaveDownloadXmlInEdit}
               helpIconMessage = {this.helpIconMessage}
            >


                {!fullScreenFlag && <ValidatorForm
                    className="marginTopImport"
                    name="Table1Import"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleFileUpload}
                    onError={errors => { }} >
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={4}>
                            <TextBox
                                name="user_org"
                                value={user_org}
                                label="Organization"
                                className={`${classes.textField} table1LogDisable`}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <TextBox
                                name="importDate"
                                value={importDate}
                                label="Import Date"
                                className={`${classes.textField} table1LogDisable`}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <AutoComplete
                                placeholder='Import Year'
                                className="autocomplete-fixed"
                                name="dashboardReportYear"
                                suggestions={importYears}
                                handleAutoCompolete={this.handleAutoCompleteState}
                                selectedValue={this.state.importYear?{ name: this.state.importYear,id:this.state.importYear}:{name:new Date().getFullYear(),id:new Date().getFullYear()}}
                                // disabled={true}
                                validators={[]}
                                errorMessages={[]}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Grid item xs={12} style={{ height: '55vh', margin: '5px', boxShadow: 'none', border: '1px solid #a3a0a0', alignItems: 'center' }}>
                                <Grid container spacing={2} style={{ paddingLeft: '5px', paddingTop: '5px', fontWeight: 'bold' }}>
                                    <Grid item xs={4} md={6}>
                                    <ToolTip title={!file ? '' : file.name === "blob" ? pageDataFromEdit : file.name} placement="top"   >
                                    <Item style={{ marginTop: '8px',marginLeft:'8px' }}>{!file ? 'Input XML' : file.name === "blob" ? pageDataFromEdit.length>40 && window.screen.width>1400?`${pageDataFromEdit.slice(0,40)}...`:pageDataFromEdit.length>40 && window.screen.width<1400?`${pageDataFromEdit.slice(0,15)}...`:pageDataFromEdit : file.name&&file.name.length>40&& window.screen.width>1400?`${file.name.slice(0,40)}...`:file.name&&file.name.length>40&& window.screen.width<1400?`${file.name.slice(0,15)}...`:file.name}</Item>
                                    </ToolTip>
                                    </Grid>
                                    <Grid item xs={8} md={6} sm={6} direction={"row"}>

                                        <Grid container spacing={1} justifyContent='space-between' alignItems='center'>                                            
                                        <Grid item sm={1}></Grid>

                                                <Grid item sm={5} md={5}>
                                                <a
                                                    href={`${globalVariable.apiURL}/table1-documentation/wbar_table1_import__2023.xsd`}
                                                    target="_blank"
                                                    style={{ color: '#36aebe' }}
                                                    title={"WBARS - Schema - Revised on Nov 03 2023"}
                                                    rel="noopener noreferrer"
                                                >
                                                    {"Sample Schema"}
                                                </a>
                                                </Grid>
                                                <Grid item sm={2}>
                                                <ToolTip title="Upload New XML" placement="bottom"   >
                                                    <Button style={{ width: '36px', height: '36px', boxShadow: 'none', background: ' #e0e0e0', borderRadius: '50%', minHeight: '36px', minWidth: '36px', padding: '2px' }} variant="circular" size="small" color="default" aria-label="Merge" className={classes.button} component="label" >
                                                        <input
                                                            type="file"
                                                            accept={["text/xml"]}
                                                            onChange={(e) => this.handleFileChange(e.target.files, [])}
                                                            onClick={(e) => e.target.value = null }
                                                            name={'xmlfileupload'}
                                                            hidden
                                                        />
                                                        <XmlCopyIcon />
                                                    </Button>

                                                </ToolTip>
                                                </Grid>
                                            <Grid item sm={2}>
                                                <ToolTip title="Copy XML" placement="bottom">

                                                    <Button onClick={(e) => this.handleCopy(e)} variant="circular" size="small" color="default" aria-label="Merge" style={{ width: '36px', height: '36px', boxShadow: 'none', background: ' #e0e0e0', borderRadius: '50%', minHeight: '36px', minWidth: '36px', padding: '2px' }}>
                                                        <XmlFolderIcon />
                                                    </Button>

                                                </ToolTip>
                                            </Grid>
                                            <Grid item sm={2}>
                                                <ToolTip title="Maximize" placement="bottom">
                                                    <Button style={{ width: '36px', height: '36px', boxShadow: 'none', background: ' #e0e0e0', borderRadius: '50%', minHeight: '36px', minWidth: '36px', padding: '2px' }} variant="circular" size="small" color="default" aria-label="Merge" className={classes.button} onClick={() => { this.expandEdittor() }}  >
                                                        <XmlZoomInIcon />
                                                    </Button>
                                                </ToolTip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>



                                <div className="xml-preview">
                                    {editorOpened && <XMLEditor
                                        xmlFormattedString={xmlFormattedString}
                                        title={file ? file.name : 'XML Preview'}
                                        ref={instance => { this.contentScroll = instance; }}
                                        onChange={(newCode) => {
                                            this.handleSave(newCode)
                                        }}
                                        onSave={(newCode) => this.handleSave(newCode)}
                                        onCancel={(newCode) => this.handleSave(newCode)}
                                        errors={this.state.initialUpload?[]:data.errors}
                                        saveEditXml={saveEditXml}
                                    />}
                                </div>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}  >
                            <Grid container>
                                <Grid item xs={12} className="minimizeErrSec" style={{ height: '55vh', margin: '5px', boxShadow: 'none', border: '1px solid #a3a0a0' }}>
                                    <Grid>
                                        <Grid container spacing={2} justifyContent='space-between' style={{ padding: '5px', fontWeight: 'bold', paddingBottom: '1px' }}>
                                            <Grid item xs={4} md={2} sm={2}>
                                                <Item style={{ marginTop: '8px',marginLeft: '8px' }}>Errors</Item>
                                            </Grid>
                                            <Grid item xs={4} md={4} sm={4}></Grid>
                                            <Grid item xs={4} md={6} sm={6}>
                                                        <Grid container justifyContent='flex-end' alignItems='center'>
                                                            <Grid item sm={2} md={2}></Grid>
                                                          

                                                        <Grid item xs={6} sm={6} md={6} >
                                                            {!initialUpload?
                                                            <div  style={{textAlign:'left',color: '#ee3322'}}>
                                                                {editorOpened &&
                                                                    <XmlWarningIcon />
                                                                }
                                                                {' '}
                                                             {editorOpened ? data.errors.length + '  Errors Found' : ''}
                                                            </div>:<></>
                                                            } 
                                                        </Grid>
                                                        <Grid item xs={2} sm={2} md={2}>
                                                        {!initialUpload?
                                                            <div  style={{textAlign:'left',color: '#ee3322'}}>
                                                            <ToolTip title="Download Error Details" placement="bottom">
                                                                <Button style={{ width: '36px', height: '36px', boxShadow: 'none', background: ' #e0e0e0', borderRadius: '50%', minHeight: '36px', minWidth: '36px', padding: '2px' }} variant="circular" size="small" color="default" aria-label="Merge" className={classes.button} component="label" onClick={() => { this.handleDownloadHtmActionClick(this.props.importProcess)}}  >
                                                                    <DownloadIconInXMLNotification />
                                                                </Button>
                                                            </ToolTip>
                                                            </div>:<></>
                                                            }
                                                        </Grid>
                                                            <Grid item xs={2} sm={2} md={2}>
                                                                <ToolTip title="Maximize" placement="bottom">
                                                                    <Button style={{ height: '36px', boxShadow: 'none', background: ' #e0e0e0', borderRadius: '50%', minHeight: '36px', minWidth: '36px', padding: '2px' }} variant="circular" size="small" color="default" aria-label="Merge" className={`${classes.button}`} onClick={() => { this.expandErrorEdittor() }}  >
                                                                        <XmlZoomInIcon />
                                                                    </Button>
                                                                </ToolTip>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item >
                                        <div className="xml-preview"  >
                                            {editorOpened && <XMLEditorError
                                                scrollToErrorLine={(error, index) =>{
                                                    this.contentScroll.scrollToError(error, index)
                                                }}
                                                helpIconMessagePopup={(error, index) =>{
                                                    this.helpIconMessage(error, index)
                                                }}
                                                errors={this.state.initialUpload?[]:data.errors}
                                            />}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ValidatorForm>}
                {fullScreenXmlEdittor &&
                    <Grid item xs={12} sm={12} className="fullXMLEDT">
                        <Grid item xs={12} style={{ height: '70vh', margin: '5px', boxShadow: 'none', border: '1px solid #a3a0a0', alignItems: 'center', marginBottom: "15px" }}>
                            <Grid container spacing={2} style={{ paddingLeft: '5px', paddingTop: '5px', fontWeight: 'bold' }}>
                                <Grid item xs={4} md={9} sm={9}>
                                    <Item style={{ marginTop: '8px',marginLeft: '8px' }}>{!file ? 'Input XML' : file.name === "blob" ? pageDataFromEdit : file.name}</Item>
                                </Grid>
                                <Grid item xs={8} md={3} sm={3} direction={"row"}>

                                    <Grid container spacing={1}>
                                        <Grid item sm={1}></Grid>
                                        <Grid item sm={5} >
                                            <Item style={{ marginTop: '8px' }}>   <a
                                                href={`${globalVariable.apiURL}/table1-documentation/wbar_table1_import__2023.xsd`}
                                                target="_blank"
                                                style={{ color: '#36aebe' }}
                                                title={"WBARS - Schema - Revised on Nov 03 2023"}
                                                rel="noopener noreferrer"
                                            >
                                                {"Sample Schema"}
                                            </a>
                                            </Item>
                                        </Grid>
                                        <Grid item sm={2} >
                                            <ToolTip title="Upload New XML" placement="bottom"   >
                                                <Button style={{ width: '36px', height: '36px', boxShadow: 'none', background: ' #e0e0e0', borderRadius: '50%', minHeight: '36px', minWidth: '36px', padding: '2px' }} variant="circular" size="small" color="default" aria-label="Merge" className={classes.button} component="label" >
                                                    <input
                                                        type="file"
                                                        accept={["text/xml"]}
                                                        onChange={(e) => this.handleFileChange(e.target.files, [])}
                                                        onClick={(e) => e.target.value = null }
                                                        name={'xmlfileupload'}
                                                        hidden
                                                    />
                                                    <XmlCopyIcon />
                                                </Button>

                                            </ToolTip>
                                        </Grid>
                                        <Grid item sm={2}>
                                            <ToolTip title="Copy XML" placement="bottom">

                                                <Button onClick={(e) => this.handleCopy(e)} variant="circular" size="small" color="default" aria-label="Merge" style={{ width: '36px', height: '36px', boxShadow: 'none', background: ' #e0e0e0', borderRadius: '50%', minHeight: '36px', minWidth: '36px', padding: '2px' }}>
                                                    <XmlFolderIcon />
                                                </Button>

                                            </ToolTip>
                                        </Grid>
                                        <Grid item sm={2}>
                                            <ToolTip title="Minimize" placement="bottom">
                                                <Button style={{ width: '36px', height: '36px', boxShadow: 'none', background: ' #e0e0e0', borderRadius: '50%', minHeight: '36px', minWidth: '36px', padding: '2px' }} variant="circular" size="small" color="default" aria-label="Merge" className={classes.button} onClick={() => { this.minimizeEdittor() }}  >
                                                    <XmlZoomOutIcon />
                                                </Button>
                                            </ToolTip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>



                            <div className="xml-preview" style={{ marginBottom: "10px" }}>
                                {editorOpened && <XMLEditor
                                    xmlFormattedString={xmlFormattedString}
                                    title={file ? file.name : 'XML Preview'}
                                    ref={instance => { this.contentScroll = instance; }}
                                    onChange={(newCode) => {
                                        this.handleSave(newCode)
                                    }}
                                    onSave={(newCode) => this.handleSave(newCode)}
                                    onCancel={(newCode) => this.handleSave(newCode)}
                                    errors={this.state.initialUpload?[]:data.errors}
                                    saveEditXml={saveEditXml}
                                    fullScreenStylePopup = {false}
                                />}
                            </div>

                        </Grid>
                    </Grid>

                }

                {fullScreenXmlErrorEdittor &&
                    <Grid container className="fullErrXml" xs={12} sm={12} style={{ marginBottom: '15px' }}>
                        <Grid item xs={12} style={{ height: '70vh', margin: '5px', boxShadow: 'none', border: '1px solid #a3a0a0' }}>

                            <Grid container spacing={2} justifyContent='space-between' style={{ padding: '5px', fontWeight: 'bold' }} alignItems='center'>
                                <Grid item xs={4} md={9} sm={9}>
                                    <Item style={{marginTop: '8px',marginLeft: '8px'}}>Errors</Item>
                                </Grid>
                                <Grid item xs={6} md={3} sm={3} style={{ color: '#ee3322' }}>
                                    <Grid container spacing={1}  justifyContent='flex-end'>
                                        <Grid item sm={2} md={2}></Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                        {!initialUpload?
                                        <div  style={{textAlign:'left',padding:'5px'}}>
                                            {editorOpened &&
                                                <XmlWarningIcon />
                                            }
                                            {' '}
                                            {editorOpened ? data.errors.length + '  Errors Found' : ''}
                                        </div>:<></>
                                        }
                                        </Grid> 
                                        <Grid item xs={2} sm={2} md={2}>
                                                        {!initialUpload?
                                                            <div  style={{textAlign:'left',color: '#ee3322'}}>
                                                            <ToolTip title="Download Error Details" placement="bottom">
                                                                <Button style={{ width: '36px', height: '36px', boxShadow: 'none', background: ' #e0e0e0', borderRadius: '50%', minHeight: '36px', minWidth: '36px', padding: '2px' }} variant="circular" size="small" color="default" aria-label="Merge" className={classes.button} component="label" onClick={() => { this.handleDownloadHtmActionClick(this.props.importProcess) }}>
                                                                    <DownloadIconInXMLNotification />
                                                                </Button>
                                                            </ToolTip>
                                                            </div>:<></>
                                                            }
                                        </Grid>
                                        <Grid item xs={2} md={2} sm={2}>
                                            <ToolTip title="Minimize" placement="bottom">
                                                <Button style={{ width: '36px', height: '36px', boxShadow: 'none', background: ' #e0e0e0', borderRadius: '50%', minHeight: '36px', minWidth: '36px', padding: '2px' }} variant="circular" size="small" color="default" aria-label="Merge" className={classes.button} onClick={() => { this.minimizeErrorEdittor() }}  >
                                                    <XmlZoomOutIcon />
                                                </Button>
                                            </ToolTip>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <div className="xml-preview"  >
                                {editorOpened && <XMLEditorError
                                    scrollToErrorLine={(error, index) => this.contentScroll.scrollToError(error, index)}
                                    helpIconMessagePopup={(error, index) =>{
                                        this.helpIconMessage(error, index)
                                    }}
                                    errors={this.state.initialUpload?[]:data.errors}
                                    fullScreenStylePopup = {true}
                                />}
                            </div>
                        </Grid>
                    </Grid>

                }



                <Grid item>
                    <Grid container spacing={2} justifyContent='flex-end' style={{padding:'5px'}}>
                        {initialUpload && <Button variant="contained" style={{ background: '#faaa91', color: '#fff', margin: '5px' }} onClick={() => {this.handleSaveDownload()  }}>{'Save & Download'}</Button>}
                        
                        {!initialUpload &&  <Button variant="contained" style={{ background: '#faaa91', color: '#fff', margin: '5px' }} onClick={() => {this.handleSaveDownloadXmlInEdit(data.errors)  }}>{'Save & Download'}</Button>}

                        {!editorOpened &&   <Button variant="contained" style={{ background: '#36aebe', color: '#fff', margin: '5px' }} onClick={() => {  xmlFormattedString === '' ?   this.props.errorAlert('Please upload a XML file') : this.refs.form.submit()  }} >{"Save & Submit"}</Button>}
                        {editorOpened && <Button variant="contained" style={{ background: '#36aebe', color: '#fff', margin: '5px' }} onClick={() => { this.handleSubmit() }}>{"Save & Submit"}</Button>}
                        <Button variant="contained" style={{ color: '#070707', margin: '5px' }} onClick={() => { history.goBack() }}>{"cancel"}</Button>

                    </Grid>
                </Grid>


                <PageLoader show={isLoading || this.state.isLoading} />
                {/* {
                    startImported && data.dummyResponse && this.formatReponse(data.data)
                } */}
                {
                    openHelp && <HelpFloater
                        handleCloseFloater={() => this.setState({ openHelp: false })}
                        {...helpData}
                        title="XML Import"
                    />
                }

                  <XmlHelpDialog
                    open={this.state.openValidationPopup !== false}
                    content= {<div dangerouslySetInnerHTML={{ __html: `${this.state.validationMessage}`}}></div>}
                    title={this.state.validationTitle}
                    handleCloseAction={this.handleCloseAction}
                   />
            </ContentWrapper>
        );
    }
}
/** Porps types */
Table1Import.propTypes = {
    classes: PropTypes.object.isRequired,
    isSubPage: PropTypes.bool,
    pageAccess: PropTypes.array
}
/** Default props */
Table1Import.defaultProps = {
    pageAccess: [],
    isReadOly: false,
    isSubPage: [],
}
/**
 * Match state to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ bulkUploads, pageAccess,help,table1Log}, ownProps) => {
    const helpData = help.data[0]
    const tablelogLoading = table1Log.doubleClick
    return {
        importProcess: bulkUploads.table1,
        pageAccess: pageAccess['table1Import'] || [],
        helpData,
        tablelogLoading
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {

    return {
        table1Import: (formData) => dispatch(uploadActions.table1Import(formData)),
        errorAlert: (msg) => dispatch(alertActions.error(msg)),
        successAlert: (msg) => dispatch(alertActions.success(msg)),
        getHelp: () => dispatch(helpActions.getAll({ _id: 25 })),
        Table1ErrorLog: (fileName) => dispatch(Table1LogActions.getErrorLogFile(fileName)),
        clearAlert: () => dispatch(alertActions.clear())
    }
};
/** Export Components */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(Table1Import));