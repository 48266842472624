import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

// Set SVG Viewport
const viewPort = "0 0 18 18";
// Set SVG font-size
const fontSize = 20;

/**
 * Svg Menu Icon
 * @param props
 * @returns {*}
 * @constructor
 */
export function MenuIcon(Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
            <path id="Untitled-2" d="M24,3.6a1.126,1.126,0,0,1-1,1.2H1A1.126,1.126,0,0,1,0,3.6V1.2A1.126,1.126,0,0,1,1,0H23a1.126,1.126,0,0,1,1,1.2Zm0,9.6a1.126,1.126,0,0,1-1,1.2H1a1.126,1.126,0,0,1-1-1.2V10.8A1.126,1.126,0,0,1,1,9.6H23a1.126,1.126,0,0,1,1,1.2Zm0,9.6A1.126,1.126,0,0,1,23,24H1a1.126,1.126,0,0,1-1-1.2V20.4a1.126,1.126,0,0,1,1-1.2H23a1.126,1.126,0,0,1,1,1.2Z" fill="#999" />
        </svg>
    )
}

/**
 * Svg Dashboard Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function DashboardIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height="16px" width="16px" viewBox="0 0 24 24" >
            <path fill='#909090' d="M24,14.6c0,2.3-0.6,4.4-1.9,6.5c-0.2,0.3-0.4,0.4-0.7,0.4H2.6c-0.3,0-0.6-0.1-0.8-0.4C0.6,19,0,16.9,0,14.6
                c0-1.6,0.3-3.2,0.9-4.7c0.7-1.6,1.6-2.8,2.6-3.8C4.6,5,5.8,4.2,7.3,3.5c1.5-0.6,3.1-0.9,4.7-0.9s3.1,0.3,4.6,0.9
                c1.5,0.7,2.8,1.5,3.8,2.6c1,1,1.9,2.3,2.5,3.8C23.7,11.4,24,13,24,14.6z M5.2,14.6c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.5-1.2-0.5
                S2.5,13,2.2,13.4c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5
                C5,15.4,5.2,15,5.2,14.6z M7.7,8.6c0-0.5-0.2-0.9-0.5-1.2C6.9,7,6.5,6.8,6,6.8S5.1,7,4.8,7.4C4.4,7.7,4.3,8.1,4.3,8.6
                c0,0.4,0.2,0.8,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5C7.5,9.5,7.7,9,7.7,8.6z M14.7,9.2c-0.1-0.2-0.3-0.3-0.5-0.4
                C14,8.8,13.8,8.8,13.5,9c-0.2,0.1-0.4,0.3-0.4,0.5l-1.4,5.1c-0.5,0-1,0.2-1.4,0.6c-0.4,0.4-0.7,0.8-0.8,1.3c-0.2,0.7-0.1,1.3,0.2,2
                c0.4,0.6,0.9,1,1.6,1.2c0.7,0.2,1.3,0.1,2-0.3c0.6-0.4,1-0.9,1.2-1.6c0.1-0.5,0.1-1-0.1-1.5c-0.2-0.5-0.5-0.9-0.9-1.2l1.3-5.1
                C14.9,9.7,14.8,9.5,14.7,9.2z M10.8,7.2c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2
                c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.5-1.2-0.5s-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2S10.4,6.9,10.8,7.2z M18,10.3
                c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2C18.8,7,18.4,6.8,18,6.8c-0.5,0-0.9,0.2-1.2,0.5
                c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.4,0.2,0.8,0.5,1.2C17.1,10.1,17.5,10.3,18,10.3z M22.3,14.6c0-0.5-0.2-0.9-0.5-1.2
                c-0.3-0.3-0.7-0.5-1.2-0.5c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.4,0.2,0.8,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5
                c0.5,0,0.9-0.2,1.2-0.5C22.1,15.4,22.3,15,22.3,14.6z"
            />
        </svg>
    );
}


export function DashboardIconHO(props) {
    return (
        <svg version="1.1" id="mortgage-loan" xmlns="http://www.w3.org/2000/svg" height="16px" width="16px" viewBox="0 0 24 24" >
            <path fill='#909090' d="M24,14.6c0,2.3-0.6,4.4-1.9,6.5c-0.2,0.3-0.4,0.4-0.7,0.4H2.6c-0.3,0-0.6-0.1-0.8-0.4C0.6,19,0,16.9,0,14.6
                c0-1.6,0.3-3.2,0.9-4.7c0.7-1.6,1.6-2.8,2.6-3.8C4.6,5,5.8,4.2,7.3,3.5c1.5-0.6,3.1-0.9,4.7-0.9s3.1,0.3,4.6,0.9
                c1.5,0.7,2.8,1.5,3.8,2.6c1,1,1.9,2.3,2.5,3.8C23.7,11.4,24,13,24,14.6z M5.2,14.6c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.5-1.2-0.5
                S2.5,13,2.2,13.4c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5
                C5,15.4,5.2,15,5.2,14.6z M7.7,8.6c0-0.5-0.2-0.9-0.5-1.2C6.9,7,6.5,6.8,6,6.8S5.1,7,4.8,7.4C4.4,7.7,4.3,8.1,4.3,8.6
                c0,0.4,0.2,0.8,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5C7.5,9.5,7.7,9,7.7,8.6z M14.7,9.2c-0.1-0.2-0.3-0.3-0.5-0.4
                C14,8.8,13.8,8.8,13.5,9c-0.2,0.1-0.4,0.3-0.4,0.5l-1.4,5.1c-0.5,0-1,0.2-1.4,0.6c-0.4,0.4-0.7,0.8-0.8,1.3c-0.2,0.7-0.1,1.3,0.2,2
                c0.4,0.6,0.9,1,1.6,1.2c0.7,0.2,1.3,0.1,2-0.3c0.6-0.4,1-0.9,1.2-1.6c0.1-0.5,0.1-1-0.1-1.5c-0.2-0.5-0.5-0.9-0.9-1.2l1.3-5.1
                C14.9,9.7,14.8,9.5,14.7,9.2z M10.8,7.2c0.3,0.3,0.7,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2
                c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.5-1.2-0.5s-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2S10.4,6.9,10.8,7.2z M18,10.3
                c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2C18.8,7,18.4,6.8,18,6.8c-0.5,0-0.9,0.2-1.2,0.5
                c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.4,0.2,0.8,0.5,1.2C17.1,10.1,17.5,10.3,18,10.3z M22.3,14.6c0-0.5-0.2-0.9-0.5-1.2
                c-0.3-0.3-0.7-0.5-1.2-0.5c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.4,0.2,0.8,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5
                c0.5,0,0.9-0.2,1.2-0.5C22.1,15.4,22.3,15,22.3,14.6z"
            />
        </svg>
    );
}

/**
 * Svg Projects Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function ProjectsIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height={Props.height ? Props.height : '16px'} width={Props.width ? Props.width : '16px'}
            viewBox="0 0 24 24">

            <path fill={Props.fill ? Props.fill : '#909090'} d="M21.9,9.7V6.9h-2.8V5.4h-1.9v1.5h-2.8v2.8H13V4.4h-2.4V1.6H7.5V0H5.5v1.6H2.4v2.8H0V24h24V9.7
             L21.9,9.7z M5.6,21.4H3.7V20h1.9V21.4z M5.6,18.8H3.7v-1.4h1.9V18.8z M5.6,16.2H3.7v-1.4h1.9V16.2z M5.6,13.6H3.7v-1.4h1.9V13.6z
            M5.6,10.9H3.7V9.5h1.9V10.9z M5.6,8.3H3.7V6.9h1.9V8.3z M9.3,21.4H7.4V20h1.9V21.4z M9.3,18.8H7.4v-1.4h1.9V18.8z M9.3,16.2H7.4
             v-1.4h1.9V16.2z M9.3,13.6H7.4v-1.4h1.9V13.6z M9.3,10.9H7.4V9.5h1.9V10.9z M9.3,8.3H7.4V6.9h1.9V8.3z M17.1,21.4h-1.9V20h1.9V21.4z
            M17.1,18.8h-1.9v-1.4h1.9V18.8z M17.1,16.2h-1.9v-1.4h1.9V16.2z M17.1,13.6h-1.9v-1.4h1.9V13.6z M20.8,21.4h-1.9V20h1.9L20.8,21.4z
            M20.8,18.8h-1.9v-1.4h1.9L20.8,18.8z M20.8,16.2h-1.9v-1.4h1.9L20.8,16.2z M20.8,13.6h-1.9v-1.4h1.9L20.8,13.6z"/>
        </svg>
    );
}

/**
 * Svg Sites Icon
 * @param props
 * @returns {*}
 * @constructor
 */
export function SitesIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height={Props.height ? Props.height : '16px'} width={Props.width ? Props.width : '16px'}
            viewBox="0 0 24 24" >

            <path fill={Props.fill ? Props.fill : '#909090'} d="M0,4h5.5v13.7H3.8v3.9H0V4z M2,8.4h2.3V6.1H2V8.4z M2,12.1h2.3V9.8H2V12.1z M2,15.9h2.3v-2.3H2V15.9z M6.5,2.3
       h11v19.4h-4.2v-4.4h-2.5v4.4H6.5V2.3z M8.7,7.1h2.5V4.6H8.7V7.1z M8.7,11.2h2.5V8.7H8.7V11.2z M8.7,15.3h2.5v-2.6H8.7V15.3z
        M12.8,7.1h2.6V4.6h-2.6V7.1z M12.8,11.2h2.6V8.7h-2.6V11.2z M12.8,15.3h2.6v-2.6h-2.6V15.3z M24,4v17.7h-3.9v-3.9h-1.6V4H24z
        M22,6.1h-2.3v2.3H22V6.1z M22,9.8h-2.3v2.3H22V9.8z M22,13.5h-2.3v2.3H22V13.5z"/>
        </svg>
    );
}

/**
 * Svg buildings Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function BuildingsIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height={Props.height ? Props.height : '16px'} width={Props.width ? Props.width : '16px'}
            viewBox="0 0 24 26" >

            <path fill={Props.fill ? Props.fill : '#909090'} d="M24,25v1H0v-1h1.6v-2.6l-0.3-0.5l3-1.8l6.8,2.1l-0.3,1V25H12v-2.6l-0.3-0.5l3-1.8l6.8,2.1l-0.3,1V25H24z
	 M1.9,7.4L1.3,6.4l3-1.8l6.8,2.1l-0.3,1L4.5,5.8L1.9,7.4z M1.9,9.3L1.3,8.3l3-1.8l6.8,2.1l-0.3,1.1l-6.3-2L1.9,9.3z M1.9,11.2
	l-0.5-0.9l3-1.7l6.8,2.1l-0.3,1L4.5,9.7L1.9,11.2z M1.9,13.1l-0.5-0.9l3-1.7l6.8,2.1l-0.3,1l-6.3-1.9L1.9,13.1z M1.9,15.1l-0.5-1
	l3-1.7l6.8,2.1l-0.3,1l-6.3-1.9L1.9,15.1z M1.9,17l-0.5-1l3-1.7l6.8,2.1l-0.3,1l-6.3-1.9L1.9,17z M1.9,18.9L1.3,18l3-1.8l6.8,2.1
	l-0.3,1l-6.3-1.9L1.9,18.9z M1.9,20.8l-0.5-0.9l3-1.8l6.8,2.1l-0.3,1l-6.3-1.9L1.9,20.8z M9.2,25v-1.4l-3.3-0.8V25H9.2z M12.2,3.5
	l-0.5-1l3-1.7l6.8,2.1l-0.3,1L14.9,2L12.2,3.5z M12.2,5.4l-0.5-0.9l3-1.8l6.8,2.1l-0.3,1l-6.3-1.9L12.2,5.4z M12.2,7.4l-0.5-0.9
	l3-1.8l6.8,2.1l-0.3,1l-6.3-1.9L12.2,7.4z M12.2,9.3l-0.5-0.9l3-1.8l6.8,2.1l-0.3,1.1l-6.3-2L12.2,9.3z M12.2,11.2l-0.5-0.9l3-1.7
	l6.8,2.1l-0.3,1l-6.3-1.9L12.2,11.2z M12.2,13.1l-0.5-0.9l3-1.7l6.8,2.1l-0.3,1l-6.3-1.9L12.2,13.1z M12.2,15.1l-0.5-1l3-1.7
	l6.8,2.1l-0.3,1l-6.3-1.9L12.2,15.1z M12.2,17l-0.5-1l3-1.7l6.8,2.1l-0.3,1l-6.3-1.9L12.2,17z M12.2,18.9L11.7,18l3-1.8l6.8,2.1
	l-0.3,1l-6.3-1.9L12.2,18.9z M12.2,20.8l-0.5-0.9l3-1.8l6.8,2.1l-0.3,1l-6.3-1.9L12.2,20.8z M19.6,25v-1.4l-3.3-0.8V25H19.6z"/>
        </svg>

    );
}

/**
 * Svg Units Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function UnitsIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height={Props.height ? Props.height : '16px'} width={Props.width ? Props.width : '16px'}
            viewBox="0 0 24 24" >
            <path fill={Props.fill ? Props.fill : '#909090'} d="M24,22.2v1.4H0v-1.4h1.2v-1.9h1.5c0.6,0,1.1,0.2,1.6,0.5c0.5,0.4,0.8,0.8,0.9,1.4h1.5c-0.2-0.9-0.6-1.7-1.4-2.4
	c-0.8-0.6-1.6-1-2.6-1H2.4v-8.6H1v-2H23v2h-1.4v8.6h-0.3c-1,0-1.8,0.3-2.6,1c-0.8,0.6-1.2,1.4-1.4,2.4h1.5c0.2-0.6,0.5-1,0.9-1.4
	c0.5-0.4,1-0.5,1.6-0.5h1.5v1.9H24z M22.3,6.9H1.7l2.7-5h11.4v1.7h1.4V0.4h4v4.4L22.3,6.9z M7.2,12.1H5.8v2h1.4V12.1z M7.2,15.5H5.8
	v2h1.4V15.5z M14.4,16.4H9.6v5.8H11v-4.4H13v4.4h1.4V16.4z M11.3,14.1h1.4v-2h-1.4V14.1z M16.8,14.1h1.4v-2h-1.4V14.1z M16.8,17.6
	h1.4v-2h-1.4V17.6z"/>
        </svg>
    );
}

/**
 * Svg organizations Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function OrganizationIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height={props.height ? props.height : '16px'} width={props.width ? props.width : '16px'}
            viewBox="0 0 24 24" className={props.className}>

            <path fill={props.fill ? props.fill : '#909090'} d="M24,12.4H0V7.3c0-1.2,1-2.1,2.1-2.1h4.7V3c0-0.7,0.6-1.3,1.3-1.3h7.7c0.7,0,1.3,0.6,1.3,1.3v2.1h4.7
   c1.2,0,2.1,1,2.1,2.1V12.4z M24,20.1c0,1.2-1,2.1-2.1,2.1H2.1c-1.2,0-2.1-1-2.1-2.1v-6.4h9v2.1c0,0.5,0.4,0.9,0.9,0.9h4.3
   c0.5,0,0.9-0.4,0.9-0.9v-2.1h9V20.1z M15.4,5.1V3.4H8.6v1.7H15.4z M13.7,15.4h-3.4v-1.7h3.4V15.4z"/>
        </svg>
    )
}

/**
 * Svg users Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function UsersIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height={props.fill ? props.fill : '16px'} width={props.fill ? props.fill : '16px'}
            viewBox="0 0 24 24" >

            <path fill={props.fill ? props.fill : '#909090'} d="M4.1,13.6H2.4c-1.2,0-2.4-0.6-2.4-2c0-1,0-4.4,1.5-4.4c0.3,0,1.6,1.1,3.2,1.1c0.6,0,1.1-0.1,1.7-0.3
       c0,0.3-0.1,0.5-0.1,0.8c0,1.1,0.4,2.3,1,3.2C6.1,12,4.9,12.6,4.1,13.6z M4.8,7.2C3,7.2,1.6,5.8,1.6,4S3,0.8,4.8,0.8S8,2.2,8,4
       S6.6,7.2,4.8,7.2z M17.5,23.2H6.5c-2,0-3.3-1.2-3.3-3.2c0-2.8,0.7-7.2,4.3-7.2c0.4,0,2,1.7,4.5,1.7s4-1.7,4.5-1.7
       c3.7,0,4.3,4.3,4.3,7.2C20.8,22,19.5,23.2,17.5,23.2z M12,13.6c-2.6,0-4.8-2.1-4.8-4.8S9.4,4,12,4s4.8,2.1,4.8,4.8
       S14.6,13.6,12,13.6z M19.2,7.2C17.4,7.2,16,5.8,16,4s1.4-3.2,3.2-3.2c1.8,0,3.2,1.4,3.2,3.2S21,7.2,19.2,7.2z M21.6,13.6h-1.7
       c-0.8-1-2-1.6-3.3-1.6c0.6-0.9,1-2.1,1-3.2c0-0.3,0-0.5-0.1-0.8c0.5,0.2,1.1,0.3,1.7,0.3c1.7,0,3-1.1,3.2-1.1c1.6,0,1.5,3.4,1.5,4.4
       C24,13,22.8,13.6,21.6,13.6z"/>
        </svg>
    )
}
/**
 * Svg Compliance Icon
 * @param props
 * @returns {*}
 * @constructor
 */
export function ComplianceIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height="16px" width="16px"
            viewBox="0 0 24 28" >

            <path fill='#909090' d="M24,10v16.5c0,0.8-0.7,1.5-1.5,1.5h-21C0.7,28,0,27.3,0,26.5v-25C0,0.7,0.7,0,1.5,0H14v8.5
           c0,0.8,0.7,1.5,1.5,1.5H24z M18,12.5c0-0.3-0.2-0.5-0.5-0.5h-11C6.2,12,6,12.2,6,12.5v1C6,13.8,6.2,14,6.5,14h11
           c0.3,0,0.5-0.2,0.5-0.5V12.5z M18,16.5c0-0.3-0.2-0.5-0.5-0.5h-11C6.2,16,6,16.2,6,16.5v1C6,17.8,6.2,18,6.5,18h11
           c0.3,0,0.5-0.2,0.5-0.5V16.5z M18,20.5c0-0.3-0.2-0.5-0.5-0.5h-11C6.2,20,6,20.2,6,20.5v1C6,21.8,6.2,22,6.5,22h11
           c0.3,0,0.5-0.2,0.5-0.5V20.5z M23.4,8H16V0.6c0.2,0.1,0.4,0.3,0.6,0.4l6.4,6.4C23.1,7.6,23.2,7.8,23.4,8z"/>

        </svg>
    );
}

/**
 * Svg Explore Data Icon
 * @param props
 * @returns {*}
 * @constructor
 */
export function ExploreDataIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height="16px" width="16px"
            viewBox="0 0 24 24" className={props.className}>

            <path fill={props.fill ? props.fill : '#909090'} d="M24,21H0V3h1.5v16.5H24V21z M22.5,18H3v-6.8l5.2-6.8l6.8,6.8l4.5-3.8L22.5,18z" />
        </svg>
    );
}


/**
 * Svg Configurations Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function ConfigurationsIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height="16px" width="16px"
            viewBox="0 0 24 24" >
            
            <path fill='#909090' d="M16,13.2c0,0.2-0.1,0.3-0.3,0.4l-1.9,0.3c-0.1,0.3-0.2,0.6-0.4,1c0.3,0.5,0.7,1,1.1,1.4
       c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.2c-0.3,0.3-1.7,1.9-2,1.9c-0.1,0-0.2,0-0.3-0.1l-1.4-1.1c-0.3,0.2-0.6,0.3-1,0.4
       c-0.1,0.6-0.1,1.3-0.3,1.9c0,0.2-0.2,0.3-0.4,0.3H6.8c-0.2,0-0.3-0.1-0.4-0.3l-0.3-1.9c-0.3-0.1-0.6-0.2-0.9-0.4l-1.5,1.1
       c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1c-0.3-0.3-1.8-1.6-1.8-2c0-0.1,0-0.2,0.1-0.2c0.4-0.5,0.7-0.9,1.1-1.4
       c-0.2-0.3-0.3-0.7-0.4-1l-1.9-0.3c-0.2,0-0.3-0.2-0.3-0.4v-2.3c0-0.2,0.1-0.3,0.3-0.4l1.9-0.3c0.1-0.3,0.2-0.6,0.4-0.9
       c-0.3-0.5-0.7-1-1.1-1.4C1.4,7.7,1.4,7.6,1.4,7.5s0-0.2,0.1-0.3c0.3-0.3,1.7-1.9,2-1.9c0.1,0,0.2,0,0.3,0.1l1.4,1.1
       c0.3-0.2,0.6-0.3,1-0.4c0.1-0.6,0.1-1.3,0.3-1.9C6.5,4.1,6.7,4,6.8,4h2.3c0.2,0,0.3,0.1,0.4,0.3l0.3,1.9c0.3,0.1,0.6,0.2,0.9,0.4
       l1.5-1.1c0.1-0.1,0.2-0.1,0.2-0.1c0.1,0,0.2,0,0.3,0.1c0.3,0.3,1.8,1.7,1.8,2c0,0.1,0,0.2-0.1,0.2c-0.4,0.5-0.7,0.9-1.1,1.4
       c0.2,0.3,0.3,0.7,0.4,1l1.9,0.3c0.2,0,0.3,0.2,0.3,0.4V13.2z M8,8.8c-1.8,0-3.2,1.4-3.2,3.2c0,1.8,1.4,3.2,3.2,3.2s3.2-1.4,3.2-3.2
       C11.2,10.2,9.8,8.8,8,8.8z M24,6.5c0,0.2-1.6,0.4-1.9,0.4c-0.1,0.2-0.2,0.5-0.4,0.7c0.1,0.3,0.6,1.5,0.6,1.7c0,0,0,0.1-0.1,0.1
       c-0.2,0.1-1.5,0.9-1.5,0.9c-0.2,0-1.1-1.2-1.2-1.4c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.4,0c-0.1,0.2-1.1,1.4-1.2,1.4
       c-0.1,0-1.4-0.8-1.5-0.9c0,0-0.1-0.1-0.1-0.1c0-0.2,0.5-1.5,0.6-1.7c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0-1.9-0.2-1.9-0.4V4.7
       c0-0.2,1.6-0.4,1.9-0.4c0.1-0.2,0.2-0.5,0.4-0.7C16.5,3.4,16,2.2,16,2c0,0,0-0.1,0.1-0.1C16.2,1.8,17.5,1,17.6,1
       c0.2,0,1.1,1.2,1.2,1.4c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0c0.3-0.5,0.7-1,1.1-1.4l0.1,0c0.1,0,1.4,0.8,1.5,0.9
       c0,0,0.1,0.1,0.1,0.1c0,0.2-0.5,1.5-0.6,1.7c0.2,0.2,0.3,0.4,0.4,0.7c0.2,0,1.9,0.2,1.9,0.4V6.5z M24,19.3c0,0.2-1.6,0.4-1.9,0.4
       c-0.1,0.2-0.2,0.4-0.4,0.6c0.1,0.2,0.6,1.5,0.6,1.7c0,0,0,0.1-0.1,0.1c-0.2,0.1-1.5,0.9-1.5,0.9c-0.2,0-1.1-1.3-1.2-1.4
       c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.4,0c-0.1,0.2-1.1,1.4-1.2,1.4c-0.1,0-1.4-0.8-1.5-0.9c0,0-0.1-0.1-0.1-0.1
       c0-0.2,0.5-1.5,0.6-1.7c-0.2-0.2-0.3-0.4-0.4-0.6c-0.2,0-1.9-0.2-1.9-0.4v-1.8c0-0.2,1.6-0.4,1.9-0.4c0.1-0.2,0.2-0.5,0.4-0.6
       C16.5,16.2,16,15,16,14.8c0,0,0-0.1,0.1-0.1c0.2-0.1,1.5-0.9,1.5-0.9c0.2,0,1.1,1.2,1.2,1.4c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0
       c0.3-0.5,0.7-1,1.1-1.4l0.1,0c0.1,0,1.4,0.8,1.5,0.9c0,0,0.1,0.1,0.1,0.1c0,0.2-0.5,1.5-0.6,1.7c0.2,0.2,0.3,0.4,0.4,0.6
       c0.2,0,1.9,0.2,1.9,0.4V19.3z M19.2,4c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6S20.1,4,19.2,4z
        M19.2,16.8c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6C20.8,17.5,20.1,16.8,19.2,16.8z"/>
        </svg>
    );
}

/**
 * Svg Archive Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function ArchiveIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height="16px" width="16px"
            viewBox="0 0 24 24" >

            <g id="_13_Archive_1_" transform="translate(0 -11.264)">
                <g id="Group_348_1_">
                    <g id="Group_347_1_">
                        <path id="Path_103_1_" fill='#909090' d="M1.2,19.2v14.6c0,0.5,0.4,0.9,0.9,0.9h19.8c0.5,0,0.9-0.4,0.9-0.9V19.2H1.2z M16.5,24.8
				c0,0.5-0.4,0.9-0.9,0.9H8.4c-0.5,0-0.9-0.4-0.9-0.9V23c0-0.5,0.4-0.9,0.9-0.9h7.2c0.5,0,0.9,0.4,0.9,0.9V24.8z"/>
                    </g>
                </g>
                <g id="Group_350_1_">
                    <g id="Group_349_1_">
                        <path id="Path_104_1_" fill='#909090' d="M23.1,11.9H0.9c-0.5,0-0.9,0.4-0.9,0.9V18h24v-5.2C24,12.3,23.6,11.9,23.1,11.9z" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

/**
 * Svg MailTemplate Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function MailTemplateIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height="16px" width="16px"
            viewBox="0 0 24 24" className={props.className}>

            <g>
                <path id="Path_101_1_" fill='#909090' d="M0.5,4.5c3.3,2.8,9.1,7.7,10.8,9.2c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3
		c1.7-1.5,7.5-6.4,10.8-9.2c0.2-0.2,0.2-0.5,0.1-0.7C23.2,3.3,22.6,3,22,3H2C1.4,3,0.8,3.3,0.4,3.8C0.3,4,0.3,4.3,0.5,4.5z"/>
                <path id="Path_102_1_" fill='#909090' d="M23.7,6c-0.2-0.1-0.4-0.1-0.5,0.1c-3.7,3.1-8.3,7.1-9.8,8.4c-0.8,0.7-2,0.7-2.8,0
		C9,13,3.8,8.6,0.8,6C0.6,5.9,0.3,5.9,0.1,6.1C0,6.2,0,6.3,0,6.4V19c0,1.1,0.9,2,2,2h20c1.1,0,2-0.9,2-2V6.4C24,6.2,23.9,6.1,23.7,6
		z"/>
            </g>
        </svg>
    )
}

export function ErrorWarning(props){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27.77" height="24.684" viewBox="0 0 27.77 24.684">
  <g id="warning" transform="translate(-19.072 -22.708)">
    <path id="Path_5" data-name="Path 5" d="M30.906,23.892,19.39,43.838a2.369,2.369,0,0,0,2.052,3.554H44.473a2.369,2.369,0,0,0,2.052-3.554L35.009,23.892a2.369,2.369,0,0,0-4.1,0Z" transform="translate(0)" fill="#fff"/>
    <g id="Group_14" data-name="Group 14" transform="translate(31.468 30.044)">
      <rect id="Rectangle_12" data-name="Rectangle 12" width="2.979" height="9.079" rx="1.489" transform="translate(0)" fill="#E6492D"/>
      <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.489" cy="1.489" r="1.489" transform="translate(0 11.177)" fill="#E6492D"/>
    </g>
  </g>
</svg>
    )
}

/**
 * Svg Occupants Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function OccupantsIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height="16px" width="16px"
            viewBox="0 0 24 24">

            <path fill='#909090' d="M9.7,22.6c0,0.1,0,0.2,0,0.2c-0.2,0.7-0.6,1-1.3,1c-0.3,0-0.6-0.1-0.9-0.4c-0.2-0.2-0.4-0.5-0.4-0.9v-5.7h-1
	v5.7c0,0.3-0.1,0.6-0.4,0.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4c-0.2-0.2-0.4-0.5-0.4-0.9v-6l-0.3-4.3l-1,2.1
	c-0.1,0.3-0.4,0.5-0.7,0.6C1.3,15,1,15,0.7,14.9c-0.3-0.2-0.5-0.4-0.6-0.7c-0.1-0.3-0.1-0.6,0.1-1l3.1-6.3c0.4-0.7,0.9-1,1.7-1h3.6
	c0.7,0,1.2,0.3,1.5,0.8l-3.7,5.8C6.1,13,6,13.5,6.2,14c0.2,0.6,0.5,1,0.9,1.3c0.4,0.2,0.8,0.3,1.3,0.3v0.1c-0.2,0.5,0,0.9,0.3,1.4
	c0.2,0.3,0.6,0.5,1,0.6V22.6z M5,4.4C4.5,3.9,4.3,3.3,4.3,2.6C4.3,2,4.5,1.4,5,0.9C5.5,0.4,6,0.2,6.7,0.2c0.7,0,1.3,0.2,1.8,0.7
	C9,1.4,9.3,2,9.3,2.6c0,0.7-0.2,1.3-0.7,1.8C8,4.9,7.4,5.1,6.7,5.1C6,5.1,5.5,4.9,5,4.4z M13.2,16.9H13v5.7c0,0.3-0.1,0.6-0.4,0.9
	c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4c-0.2-0.2-0.4-0.5-0.4-0.9v-5.7H10c-0.2,0-0.5-0.1-0.7-0.3
	c-0.2-0.2-0.2-0.5-0.2-0.8l0.5-2.2l-0.4,0.6c-0.2,0.3-0.4,0.5-0.7,0.6c-0.3,0.1-0.7,0-1-0.2c-0.3-0.2-0.5-0.5-0.5-0.8
	c-0.1-0.3,0-0.7,0.2-1L11,6.6C11.2,6.2,11.6,6,12,6c0,0,0.5,0,1.4,0c0.9,0,1.4,0,1.5,0c0.4,0,0.8,0.2,1,0.6l0.1,0.2
	c0,0.2,0,0.4,0,0.7c0,0.7,0.2,1.2,0.7,1.8c-0.3,0.1-0.5,0.3-0.7,0.6l-3.1,5C12.6,15.5,12.7,16.2,13.2,16.9z M11.8,4.8
	c-0.5-0.5-0.7-1-0.7-1.7c0-0.7,0.2-1.2,0.7-1.7c0.5-0.5,1-0.7,1.7-0.7c0.7,0,1.2,0.2,1.7,0.7c0.5,0.5,0.7,1.1,0.7,1.7
	c0,0.7-0.2,1.3-0.7,1.7s-1,0.7-1.7,0.7C12.8,5.5,12.3,5.2,11.8,4.8z M23.5,16.6c-0.6,0.3-1,0.2-1.4-0.3l-0.3-0.5l0.4,1.7
	c0.1,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.3,0.3-0.6,0.3h-0.4v4.5c0,0.3-0.1,0.5-0.3,0.7s-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3
	s-0.3-0.4-0.3-0.7v-4.5h-0.8v4.5c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7
	v-4.5h-0.4c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.2-0.4-0.1-0.6l0.4-1.7l-0.3,0.5c-0.3,0.6-0.8,0.7-1.4,0.3c-0.5-0.4-0.6-0.9-0.3-1.4
	l3.1-4.9c0.2-0.3,0.5-0.5,0.8-0.5l0.2,0c0.1,0,0.3,0,0.5,0c0.2,0,0.4,0,0.5,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0l0.1,0
	c0.4,0,0.7,0.2,0.8,0.5l3.1,4.9C24.1,15.7,24,16.2,23.5,16.6z M15.6,22.9c0,0.3,0.1,0.6,0.2,0.8c-0.1,0.1-0.3,0.1-0.6,0.1
	c-0.3,0-0.6-0.1-0.9-0.4c-0.2-0.2-0.4-0.5-0.4-0.9v-5.2c0.2,0.1,0.4,0.1,0.5,0.1c-0.1,0.4,0,0.8,0.3,1.1c0.2,0.2,0.5,0.4,0.8,0.5
	V22.9z M16.8,7.5c0-0.5,0.2-1,0.5-1.4c0.4-0.4,0.8-0.6,1.3-0.6c0.5,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.8,0.6,1.4c0,0.7-0.3,1.2-0.9,1.6
	c-0.3,0.2-0.6,0.3-1,0.3c-0.4,0-0.7-0.1-1-0.3C17.1,8.7,16.8,8.2,16.8,7.5z"/>
        </svg>
    );
}



export function SendIconnew(props) {
    return (
        <SvgIcon {...props} viewBox={viewPort} style={{ fontSize: fontSize }}>
            <defs>
                {/*<style>*/}
                {/*.cls-1 {*/}
                {/*clip - path: url(#clip-Web_1366_1);*/}
                {/*}*/}

                {/*.cls-2 {*/}
                {/*fill: #fff;*/}
                {/*}*/}
                {/*</style>*/}
                <clipPath id="clip-Web_1366_1">
                    <rect width="18" height="18" />
                </clipPath>
            </defs>
            <g id="Web_1366_1" data-name="Web 1366 – 1" className="cls-1">
                <rect className="cls-2" width="18" height="18" />
                <path id="Forma_1" data-name="Forma 1"
                    d="M18.038-1.267l-18,9.022,4.777,2.511,4.77-3.4L5.976,10.708,6.5,16.733c.026.009,2.73-2.934,2.73-2.934l3.251,1.461Z"
                    transform="translate(-0.038 1.267)" />
            </g>
        </SvgIcon>
    );
}


export function SvgLoadIcon(props) {
    return (
        <SvgIcon {...props} viewBox={"0 0 500 500"} >

            <g>
                <circle className="st0" cx="250" cy="250" r="220.4">
                    <animateTransform attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        from="0 250 250"
                        to="360 250 250"
                        dur="10s"
                        repeatCount="indefinite"
                    />
                </circle>
            </g>
            <path className="st1" d="M414.8,206.6l-42.4-25.2v-41.9c0-2.9-3.2-5.3-7.2-5.3H322c-4,0-7.2,2.4-7.2,5.3v7.6l-60.3-35.9
	c-2.6-1.6-6.4-1.6-9.1,0L85.2,206.6c-3.1,1.8-3.6,5.2-1.1,7.4c0,0,0,0,0,0l18.1,16.4c2.5,2.3,7,2.6,10.1,0.8l15.2-9.1v129.1H106V362
	h288v-10.6h-21.6V222.2l15.2,9.1c3.1,1.8,7.6,1.5,10.1-0.8l18.1-16.4C418.4,211.8,417.9,208.5,414.8,206.6L414.8,206.6z"/>
            <path className="st1" d="M391.1,199.7" />
            <path className="st2" d="M187.1,291.9h-4.5c-2.5,0-4.5-2-4.5-4.5v-46.8c0-2.5,2-4.5,4.5-4.5h4.5c2.5,0,4.5,2,4.5,4.5v46.8
	C191.6,289.9,189.6,291.9,187.1,291.9z">
                <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="translate"
                    begin=".1"
                    dur="1s"
                    values="0,15; 0,-15; 0,15"
                    repeatCount="indefinite"
                />
            </path>
            <path className="st2" d="M219.7,291.9h-4.5c-2.5,0-4.5-2-4.5-4.5v-46.8c0-2.5,2-4.5,4.5-4.5h4.5c2.5,0,4.5,2,4.5,4.5v46.8
	C224.2,289.9,222.2,291.9,219.7,291.9z">
                <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="translate"
                    begin=".2"
                    dur="1s"
                    values="0,15; 0,-15; 0,15"
                    repeatCount="indefinite"
                />

            </path>
            <path className="st2" d="M252.3,291.9h-4.5c-2.5,0-4.5-2-4.5-4.5v-46.8c0-2.5,2-4.5,4.5-4.5h4.5c2.5,0,4.5,2,4.5,4.5v46.8
	C256.8,289.9,254.7,291.9,252.3,291.9z">
                <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="translate"
                    begin=".3"
                    dur="1s"
                    values="0,15; 0,-15; 0,15"
                    repeatCount="indefinite"
                />
            </path>
            <path className="st2" d="M284.8,291.9h-4.5c-2.5,0-4.5-2-4.5-4.5v-46.8c0-2.5,2-4.5,4.5-4.5h4.5c2.5,0,4.5,2,4.5,4.5v46.8
	C289.3,289.9,287.3,291.9,284.8,291.9z">
                <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="translate"
                    begin=".4"
                    dur="1s"
                    values="0,15; 0,-15; 0,15"
                    repeatCount="indefinite"
                />
            </path>
            <path className="st2" d="M317.4,291.9h-4.5c-2.5,0-4.5-2-4.5-4.5v-46.8c0-2.5,2-4.5,4.5-4.5h4.5c2.5,0,4.5,2,4.5,4.5v46.8
	C321.9,289.9,319.9,291.9,317.4,291.9z">
                <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="translate"
                    begin=".5"
                    dur="1s"
                    values="0,15; 0,-15; 0,15"
                    repeatCount="indefinite"
                />
            </path>
        </SvgIcon>
    );
}





export function RetriveIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 17.27 19.053">
            <path id="Path_65" data-name="Path 65" d="M17.215,23.049a8.41,8.41,0,1,0,.37-12.234l1.269-.182a.56.56,0,1,1,.159,1.11l-2.961.417h-.078a.558.558,0,0,1-.554-.639l.421-2.948a.56.56,0,0,1,1.11.159l-.188,1.316a9.527,9.527,0,1,1-.338,13.8.56.56,0,0,1,.791-.791Zm5.951-11.62a.56.56,0,0,0-.56.56v5.1a.558.558,0,0,0,.164.4l3.185,3.19a.563.563,0,1,0,.8-.8l-3.028-3.015V11.989a.56.56,0,0,0-.558-.56Z" transform="translate(-15.414 -7.583)" fill="#35aebe" />
        </svg>
    )
}

export function SendIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15">
            <path id="Path_73" data-name="Path 73" d="M17.764.1a.563.563,0,0,0-.586-.04L.3,8.876A.563.563,0,0,0,.38,9.907l4.691,1.6,9.991-8.542L7.331,12.283l7.862,2.687a.576.576,0,0,0,.181.03.562.562,0,0,0,.556-.479L17.994.646A.563.563,0,0,0,17.764.1Z" transform="translate(0 0)" fill="#36aebe" />
        </svg>
    )
}

export function VerifyData(porps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
            <g id="checked_2_" data-name="checked (2)" transform="translate(0.5 0.5)">
                <g id="Group_222" data-name="Group 222" transform="translate(4.749 2.513)">
                    <g id="Group_221" data-name="Group 221">
                        <path id="Path_63" data-name="Path 63" d="M136.247,64.549a.754.754,0,0,0-1.066,0l-9.043,9.019-3.263-3.543a.754.754,0,1,0-1.109,1.021l3.794,4.12a.753.753,0,0,0,.538.243h.016a.755.755,0,0,0,.532-.22l9.6-9.573A.754.754,0,0,0,136.247,64.549Z" transform="translate(-121.568 -64.327)" fill="#36aebe" stroke="#36aebe" stroke-width="1" />
                    </g>
                </g>
                <g id="Group_224" data-name="Group 224">
                    <g id="Group_223" data-name="Group 223">
                        <path id="Path_64" data-name="Path 64" d="M19.246,9.246a.754.754,0,0,0-.754.754A8.492,8.492,0,1,1,10,1.508.754.754,0,1,0,10,0,10,10,0,1,0,20,10,.754.754,0,0,0,19.246,9.246Z" fill="#36aebe" stroke="#36aebe" stroke-width="1" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export function LogsIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 26.154">
            <g id="document_6_" data-name="document (6)" transform="translate(-60)">
                <path id="Path_27" data-name="Path 27" d="M77.692,3.077V0H60V23.846h3.077v2.308H74.934L80,21.088V3.077ZM61.538,22.308V1.538H76.154V3.077H63.077V22.308ZM64.615,4.615H78.462V20H73.846v4.615H64.615ZM77.374,21.538l-1.989,1.989V21.538Z" fill="#909090" />
                <path id="Path_28" data-name="Path 28" d="M180,120h10.769v1.538H180Z" transform="translate(-113.846 -113.846)" fill="#909090" />
                <path id="Path_29" data-name="Path 29" d="M180,180h10.769v1.538H180Z" transform="translate(-113.846 -170.769)" fill="#909090" />
                <path id="Path_30" data-name="Path 30" d="M180,240h10.769v1.538H180Z" transform="translate(-113.846 -227.692)" fill="#909090" />
                <path id="Path_31" data-name="Path 31" d="M180,300h10.769v1.538H180Z" transform="translate(-113.846 -284.615)" fill="#909090" />
                <path id="Path_32" data-name="Path 32" d="M180,360h5.385v1.538H180Z" transform="translate(-113.846 -341.538)" fill="#909090" />
            </g>
        </svg>
    )
}

export function SettingsIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 20 20">
            <g id="settings_7_" data-name="settings (7)" transform="translate(0 0.001)">
                <g id="Group_101" data-name="Group 101" transform="translate(0 0)">
                    <path id="Path_21" data-name="Path 21" d="M14.448,10.966,13.114,12.3h0L3.9,3.088,4.61,2.38,1.675.014,0,1.689,2.366,4.624l.708-.708,9.212,9.212-1.334,1.334,1.9,1.9,3.5-3.5Z" transform="translate(0 -0.014)" fill="#909090" />
                    <path id="Path_22" data-name="Path 22" d="M353.779,350.3l-3.5,3.5,2.1,2.1a2.472,2.472,0,0,0,3.5-3.5Z" transform="translate(-336.6 -336.614)" fill="#909090" />
                </g>
                <path id="Path_23" data-name="Path 23" d="M10.122,255.615l-2.73-2.73-2,2a4.155,4.155,0,1,0,2.73,2.73ZM5.107,259.8a1.345,1.345,0,1,1,0-1.9A1.344,1.344,0,0,1,5.107,259.8Z" transform="translate(0 -243.007)" fill="#909090" />
                <g id="Group_102" data-name="Group 102" transform="translate(9.878 -0.001)">
                    <path id="Path_24" data-name="Path 24" d="M262.723,2.506l-.327-.834-2.1,2.1-.846-.227-.227-.846,2.1-2.1-.834-.327a3.948,3.948,0,0,0-5.242,4.746l-2.374,2.374,2.73,2.73,2.374-2.374a3.948,3.948,0,0,0,4.746-5.242Z" transform="translate(-252.873 0.001)" fill="#909090" />
                </g>
            </g>
        </svg>
    )
}

export function AssignFunder(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19.284" height="19.82" viewBox="0 0 19.284 19.82" className={props.className}>
            <g id="shield-user-interface-symbol-for-security" transform="translate(-8.282)">
                <path id="Path_10" data-name="Path 10" d="M16.166,9.333A4.666,4.666,0,1,0,11.5,4.666,4.666,4.666,0,0,0,16.166,9.333Zm2.181-2.811a2.22,2.22,0,0,1-2.211,1.229c-.634,0,.291.281,1.142.378.6.068,1.158.042,1.069-.251Zm9.218,7.284c-.1,2.764-1.224,5.14-3.849,6.015-2.625-.875-3.749-3.251-3.849-6.015a.369.369,0,0,1,.386-.381,4.618,4.618,0,0,0,3.235-1.118.36.36,0,0,1,.457,0,4.617,4.617,0,0,0,3.235,1.118A.369.369,0,0,1,27.565,13.805Zm-8.5.029a9.174,9.174,0,0,0,.709,3.358,32.23,32.23,0,0,1-3.607.186c-4.5,0-6.7-.735-7.508-1.094a.7.7,0,0,1-.376-.586V14.16a4.519,4.519,0,0,1,3.994-4.473.373.373,0,0,1,.224.062,6.226,6.226,0,0,0,7.331,0,.373.373,0,0,1,.224-.062A4.516,4.516,0,0,1,23.2,11.544a1.146,1.146,0,0,0-.227.143,3.811,3.811,0,0,1-2.507.938c-.06,0-.12,0-.181,0h-.05a1.173,1.173,0,0,0-1.174,1.215Z" transform="translate(0 0)" fill={props.fill ? props.fill : '#fff'} />
            </g>
        </svg>
    )
}

export function EditTableHeaders(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20.678 20.678">
            <g id="edit-table_1_" data-name="edit-table (1)" transform="translate(0 0)">
                <path id="Path_952" data-name="Path 952" d="M180.022,261.187a.423.423,0,0,0-.115.206l-.939,3.986a.43.43,0,0,0,.42.529.394.394,0,0,0,.1-.012l3.985-.939a.43.43,0,0,0,.206-.115l5.636-5.636-3.656-3.656Zm0,0" transform="translate(-171.729 -245.23)" fill="#36aebe" />
                <path id="Path_953" data-name="Path 953" d="M372.839,181.078l-1.218-1.218a1.765,1.765,0,0,0-2.437,0l-1.675,1.675,3.656,3.656,1.675-1.675a1.724,1.724,0,0,0,0-2.437Zm0,0" transform="translate(-352.665 -172.127)" fill="#36aebe" />
                <path id="Path_954" data-name="Path 954" d="M5.893,16.37H2.154a.431.431,0,0,1-.431-.431V13.785H8.185a.862.862,0,0,0,.862-.862V9.478h3.507a.862.862,0,0,0,0-1.723H9.047V5.17h5.6v.862a.862.862,0,1,0,1.723,0V1.939A1.94,1.94,0,0,0,14.432,0H1.939A1.94,1.94,0,0,0,0,1.939v14a2.157,2.157,0,0,0,2.154,2.154H5.893a.862.862,0,0,0,0-1.723Zm1.43-4.308h-5.6V9.478h5.6Zm0-4.308h-5.6V5.17h5.6Zm0,0" fill="#36aebe" />
            </g>
        </svg>
    )
}

/**
 * Svg Contract Activity Icon
 * @param props
 * @returns {*}
 * @constructor
 */
export function ContractActivity(props) {
    return (
        // <SvgIcon {...props} viewBox={viewPort} style={{ fontSize: fontSize }}>
        <svg id="mortgage-loan" xmlns="http://www.w3.org/2000/svg" width="23.053" height="23.052" viewBox="0 0 23.053 23.052">
            <path id="Path_63" data-name="Path 63" d="M19.864,298.741l-4.349,2.931H10.22v-1.351h4.052A2.023,2.023,0,0,0,16.3,298.3v-.675H12.246a.8.8,0,0,1-.608-.162l-.324-.284a4.73,4.73,0,0,0-6.24,0l-2.04,1.8H0v6.754h14.34l8.712-5.876-.378-.567a2.034,2.034,0,0,0-2.809-.54Zm0,0" transform="translate(0.001 -282.672)" fill="#909090" />
            <path id="Path_64" data-name="Path 64" d="M101.455,0,92,8.194h2.7v3.952a5.92,5.92,0,0,1,5.363,1.341l.129.109h.587V12.122a2.023,2.023,0,0,1-1.351-1.9h1.351a.675.675,0,1,0,.675-.675,2.021,2.021,0,0,1-.675-3.928V4.142h1.351V5.617a2.023,2.023,0,0,1,1.351,1.9h-1.351a.675.675,0,1,0-.675.675,2.021,2.021,0,0,1,.675,3.928V13.6l3.377,0v2.339l1.461-.984a3.305,3.305,0,0,1,1.241-.48V8.194h2.7Zm0,0" transform="translate(-87.858)" fill="#909090" />
        </svg>
        // </SvgIcon>
    );
}

/**
 * Svg Delete Icon
 * @param props
 * @returns {*}
 * @constructor
 */
export function DeleteIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height="16px" width="17px"
            viewBox="0 0 24 30" >

            <g>
                <path fill='#36aebe' d="M16.1,10.9c-0.4,0-0.7,0.3-0.7,0.7v13.1c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7V11.6
		C16.8,11.2,16.5,10.9,16.1,10.9z"/>
                <path fill='#36aebe' d="M7.9,10.9c-0.4,0-0.7,0.3-0.7,0.7v13.1c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7V11.6
		C8.6,11.2,8.3,10.9,7.9,10.9z"/>
                <path fill='#36aebe' d="M2,9v17.1c0,1,0.4,2,1,2.6c0.6,0.7,1.5,1.1,2.5,1.1h13.1c0.9,0,1.8-0.4,2.5-1.1c0.6-0.7,1-1.6,1-2.6V9
		c1.3-0.3,2.1-1.6,1.9-2.9s-1.3-2.3-2.6-2.3h-3.5V3c0-0.7-0.3-1.4-0.8-1.9s-1.2-0.8-1.9-0.8H8.9C8.2,0.2,7.5,0.5,7,1S6.2,2.2,6.2,3
		v0.9H2.6c-1.3,0-2.4,1-2.6,2.3C-0.1,7.4,0.7,8.7,2,9z M18.5,28.4h-13c-1.2,0-2.1-1-2.1-2.3v-17h17.3v17
		C20.7,27.4,19.7,28.4,18.5,28.4z M7.6,3c0-0.4,0.1-0.7,0.4-1s0.6-0.4,1-0.4h6.1c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v0.9H7.6
		V3z M2.6,5.2h18.7c0.7,0,1.2,0.6,1.2,1.2S22,7.7,21.4,7.7H2.6C2,7.7,1.4,7.1,1.4,6.5S2,5.2,2.6,5.2z"/>
                <path fill='#36aebe' d="M12,10.9c-0.4,0-0.7,0.3-0.7,0.7v13.1c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7V11.6
		C12.7,11.2,12.4,10.9,12,10.9z"/>
            </g>
        </svg>
    )
}

/**
 * Svg download Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function DownloadIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height={props.height ? props.height : '16px'} width={props.width ? props.width : '16px'}
            viewBox="0 0 24 30" >
            <path id="Path_47" fill={props.fill ? props.fill : '#777777'} d="M24,10.7h-6.9V0.4H6.9v10.3H0l12,12L24,10.7z M0,26.1v3.4h24v-3.4H0z" />
            <path id="Path_47_1_" fill={props.fill ? props.fill : '#777777'} d="M-5.9,10.7h-6.9V0.4H-23v10.3h-6.9l12,12L-5.9,10.7z M-29.9,26.1v3.4h24v-3.4H-29.9z" />
        </svg>

    )
}

/**
 * Svg filter Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function FilterIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height="14px" width="14.4px"
            viewBox="0 0 24 24" >

            <g>
                <g>
                    <g>
                        <path fill={props.fill ? props.fill : '#777777'} d="M13.7,11.5l-1.8-1.7l0.8-1.1c-0.2-0.4-0.4-0.9-0.5-1.3l-1.3-0.2V6.5H0.5L8,13.8c0.1,0.1,0.1,0.1,0.1,0.2v10
				l3.9-1.7V14c0-0.1,0-0.2,0.1-0.3l3-2.9l-0.2-0.1L13.7,11.5z"/>
                        <ellipse cx="17.8" cy="5.9" rx="1.9" ry="1.8" />
                        <path fill={props.fill ? props.fill : '#777777'} d="M22.7,5.2l-0.1-0.3c-0.1-0.6-0.4-1.1-0.7-1.6l-0.1-0.2l0.7-1l-0.7-0.7l-1.1,0.7l-0.3-0.2
				c-0.5-0.3-1.1-0.5-1.7-0.7l-0.3-0.1L18.3,0h-1L17,1.2l-0.3,0.1c-0.6,0.1-1.2,0.3-1.7,0.6l-0.3,0.1l-1-0.7L13,2.1l0.7,1l-0.1,0.3
				c-0.3,0.5-0.5,1-0.7,1.6v0.3l-1.2,0.2v1l1.2,0.2v0.3c0.1,0.6,0.4,1.1,0.7,1.6l0.1,0.3l-0.7,1l0.7,0.7l1-0.7l0.3,0.2
				c0.5,0.3,1.1,0.5,1.7,0.7l0.3,0.1l0.2,1.3h1l0.2-1.3l0.3-0.1c0.6-0.1,1.2-0.4,1.7-0.7l0.3-0.1l1.1,0.7l0.7-0.7l-0.7-1l0.2-0.3
				c0.3-0.5,0.6-1,0.7-1.6l0.1-0.3L24,6.4v-1L22.7,5.2z M17.8,8.5c-1.5,0-2.8-1.2-2.8-2.6c0-1.5,1.2-2.6,2.8-2.6
				c1.5,0,2.8,1.2,2.8,2.6S19.3,8.5,17.8,8.5z"/>
                        <polygon fill={props.fill ? props.fill : '#777777'} points="10.8,4.7 12.2,4.5 12.3,4 0,4 0,5.7 10.8,5.7 			" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

/**
 * Svg Help Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function HelpIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height={props.height ? props.height : '16px'} width={props.width ? props.width : '16px'}
            viewBox="0 0 24 33" >

            <g>
                <path fill={props.fill ? props.fill : '#909090'} d="M14.8,24.8H8.5c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.5-0.3,0.7v6.2c0,0.3,0.1,0.5,0.3,0.7
           C8,33,8.2,33.1,8.5,33.1h6.2c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.3-0.7v-6.2c0-0.3-0.1-0.5-0.3-0.7
           C15.3,24.9,15,24.8,14.8,24.8z"/>
                <path fill={props.fill ? props.fill : '#909090'} d="M22.9,6.2c-0.7-1.3-1.6-2.4-2.8-3.3c-1.1-0.9-2.4-1.6-3.8-2.2c-1.4-0.5-2.8-0.8-4.2-0.8
           c-5.3,0-9.3,2.3-12.1,6.9C0,7,0,7.3,0,7.5c0,0.3,0.2,0.5,0.4,0.6l4.3,3.2c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4
           c1.2-1.6,2.2-2.6,2.8-3c0.7-0.5,1.7-0.8,2.8-0.8c1.1,0,2,0.3,2.8,0.8c0.8,0.6,1.2,1.2,1.2,1.9c0,0.8-0.2,1.5-0.6,2
           c-0.4,0.5-1.2,1-2.2,1.5c-1.4,0.6-2.6,1.5-3.7,2.8c-1.1,1.3-1.7,2.6-1.7,4.1v1.2c0,0.3,0.1,0.7,0.3,1c0.2,0.3,0.4,0.5,0.7,0.5h6.2
           c0.3,0,0.5-0.1,0.7-0.4c0.2-0.3,0.3-0.5,0.3-0.8c0-0.4,0.2-1,0.7-1.7c0.5-0.7,1.1-1.3,1.8-1.7c0.7-0.4,1.2-0.7,1.6-0.9
           c0.4-0.2,0.9-0.6,1.5-1.1c0.6-0.5,1.1-1,1.4-1.5c0.3-0.5,0.6-1.2,0.9-2c0.3-0.8,0.4-1.7,0.4-2.6C24,8.8,23.6,7.5,22.9,6.2z"/>
            </g>
        </svg>
    );
}

/**
 * Svg Plus Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function PlusIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height={props.height ? props.height : '14px'} width={props.width ? props.width : '20px'}
            viewBox="0 0 24 24">

            <g>
                <path fill={props.fill ? props.fill : '#fafafa'} d="M24,9.7v4.6c0,0.3-0.3,0.6-0.6,0.6h-8.5v8.5c0,0.3-0.3,0.6-0.6,0.6H9.7c-0.3,0-0.6-0.3-0.6-0.6v-8.5H0.6
		c-0.3,0-0.6-0.3-0.6-0.6V9.7c0-0.3,0.3-0.6,0.6-0.6h8.5V0.6C9.1,0.3,9.4,0,9.7,0h4.6c0.3,0,0.6,0.3,0.6,0.6v8.5h8.5
		C23.7,9.1,24,9.4,24,9.7z"/>
            </g>
        </svg>
    )
}

/**
 * Svg selected Icon
 * @param props
 * @returns {*}
 * @constructor
 */
export function SelectedIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height='14px' width='14px'
            viewBox="0 0 24 24">

            <g>
                <g>
                    <g>
                        <polygon fill='#777777' points="0,10.5 0,13.5 12.8,13.5 12.1,10.5 			" />
                        <path fill='#777777' d="M0,18.7v3.1h20.6v-2.9c-0.2,0-0.4-0.1-0.6-0.2L0,18.7L0,18.7z" />
                        <polygon fill='#777777' points="12.2,2.8 16.5,5.3 20.6,5.3 20.6,2.2 0,2.2 0,5.3 10.9,5.3 10.6,3.9 			" />
                        <path fill='#777777' d="M12.3,4.2L12,4.4l3,13.3l2.4-4l2.5,3.5c0.5,0.7,1.3,0.8,1.9,0.4c0.6-0.4,0.7-1.3,0.2-1.9l-2.5-3.5l4.6-1.1
				L12.3,4.2z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

/**
 * Svg selected Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function MergeIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height='14px' width='14px'
            viewBox="0 0 24 24" >

            <g>
                <g>
                    <path fill='#777777' d="M23.4,10.8l-4.7-3.4c-0.4-0.3-0.8-0.3-1.2-0.1c-0.4,0.2-0.7,0.6-0.7,1.1v2h-3.6V7.2C13.2,4.3,10.9,2,8,2H6.7
			C6.1,0.7,4.9,0,3.6,0C1.6,0,0,1.6,0,3.6s1.6,3.6,3.6,3.6c1.3,0,2.5-0.7,3.1-2H8c1.1,0,2,0.9,2,2v3.2H6.7c-0.6-1.3-1.7-2-3.1-2
			C1.6,8.4,0,10,0,12s1.6,3.6,3.6,3.6c1.3,0,2.5-0.7,3.1-2H10v3.2c0,1.1-0.9,2-2,2H6.7c-0.6-1.3-1.7-2-3.1-2c-2,0-3.6,1.6-3.6,3.6
			S1.6,24,3.6,24c1.3,0,2.5-0.7,3.1-2H8c2.9,0,5.2-2.3,5.2-5.2V14h3.6v1.6c0,0.5,0.3,0.9,0.7,1.1c0.4,0.2,0.9,0.2,1.2-0.1l4.7-3.4
			c0.4-0.3,0.6-0.7,0.6-1.2C24,11.5,23.8,11.1,23.4,10.8z"/>
                </g>
            </g>
        </svg>
    )
}

/**
 * Svg Pencil Square Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function PencilsquareIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height='16px' width='16px'
            viewBox="0 0 24 24" >

            <g>
                <path fill='#36aebe' d="M23.3,14.6c-0.3,0-0.6,0.3-0.6,0.6v5.6c0,1-0.8,1.9-1.9,1.9H3.1c-1,0-1.9-0.8-1.9-1.9V4.4c0-1,0.8-1.9,1.9-1.9
		h5.6c0.3,0,0.6-0.3,0.6-0.6S9.1,1.3,8.7,1.3H3.1C1.4,1.3,0,2.7,0,4.4v16.4c0,1.7,1.4,3.1,3.1,3.1h17.7c1.7,0,3.1-1.4,3.1-3.1v-5.6
		C23.9,14.9,23.7,14.6,23.3,14.6z"/>
                <path fill='#36aebe' d="M9.8,11.3L19,2.1l3,3l-9.2,9.2L9.8,11.3z" />
                <path fill='#36aebe' d="M8.3,15.7l3.3-0.9l-2.4-2.4L8.3,15.7z" />
                <path fill='#36aebe' d="M22.8,0.5c-0.6-0.6-1.6-0.6-2.2,0l-0.7,0.7l3,3l0.7-0.7c0.6-0.6,0.6-1.6,0-2.2L22.8,0.5z" />
            </g>
        </svg>
    )
}

/**
 * Svg Pdf Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function PdfIcon(props) {
    return (

        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height='24px' width='20px' font-size='24px' viewBox="0 0 24 24" >

            <g>
                <g>
                    <g>
                        <path fill='#777777' d="M20.9,5.1l-5-5c-0.1,0-0.3-0.1-0.4-0.1H5C3.9,0,3,0.9,3,2v20c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5.5
				C21,5.4,20.9,5.2,20.9,5.1z M16,1.7L19.3,5H17c-0.6,0-1-0.4-1-1V1.7z M20,22c0,0.6-0.4,1-1,1H5c-0.6,0-1-0.4-1-1V2
				c0-0.6,0.4-1,1-1h10v3c0,1.1,0.9,2,2,2h3V22z"/>
                        <path fill='#777777' d="M14.5,14.7c-0.5-0.4-0.9-0.7-1.2-1c-0.4-0.4-0.7-0.8-1-1.1c0.5-1.4,0.7-2.2,0.7-2.5c0-1.7-0.6-2-1.5-2
				C10.8,8,10,8.4,10,10c0,0.7,0.4,1.7,1.2,2.7c-0.2,0.6-0.4,1.3-0.7,2.1c-0.1,0.4-0.3,0.7-0.4,1.1c-0.1,0-0.2,0.1-0.3,0.2
				c-0.4,0.2-0.8,0.4-1.1,0.5C7.1,17.4,6,17.9,6,18.8C6,19.6,6.8,20,7.5,20c0.9,0,2.3-1.2,3.4-3.3c1.1-0.4,2.4-0.7,3.4-0.9
				c0.8,0.6,1.8,1.3,2.2,1.3c1.2,0,1.5-0.7,1.5-1.3c0-1.2-1.4-1.2-2-1.2C15.8,14.5,15.3,14.6,14.5,14.7z M7.5,19
				C7.2,19,7,18.9,7,18.8c0-0.4,1.1-0.9,2.1-1.3c0.1,0,0.1-0.1,0.2-0.1C8.5,18.5,7.8,19,7.5,19z M11,10c0-1,0.3-1,0.5-1
				c0.4,0,0.5,0,0.5,1c0,0.2-0.1,0.7-0.4,1.6C11.2,11,11,10.4,11,10z M11.4,15.4c0-0.1,0.1-0.2,0.1-0.3c0.2-0.6,0.4-1.1,0.5-1.5
				c0.2,0.2,0.4,0.5,0.7,0.7c0.1,0.1,0.3,0.3,0.6,0.6C12.7,15.1,12,15.2,11.4,15.4z M17,15.7c0,0.2,0,0.3-0.5,0.3
				c-0.1,0-0.5-0.2-0.8-0.5c0.1,0,0.2,0,0.3,0C16.7,15.5,16.9,15.6,17,15.7z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

/**
 * Svg shield Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function SheildIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height='22px !important' width='15px'
            viewBox="0 0 24 24">

            <g>
                <path fill={props.fill ? props.fill : '#fbab92'} d="M2,3v7.6C2,15.5,6,20.8,12,24c6-3.2,10-8.5,10-13.4V3L12,0L2,3z M12,1.6l8.5,2.6v6.4c0,4.1-3.4,8.7-8.5,11.7
		V1.6z"/>
            </g>
        </svg>
    )
}

/**
 * Svg info Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function InfoIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height={props.height ? props.height : '15px'} width={props.width ? props.width : '15px'}
            viewBox="0 0 24 24" >

            <g>
                <path fill={props.fill ? props.fill : '#fbab92'} d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M14.5,18.6c-0.6,0.2-1.1,0.4-1.5,0.6
		c-0.4,0.1-0.8,0.2-1.3,0.2c-0.7,0-1.3-0.2-1.7-0.5c-0.4-0.4-0.6-0.8-0.6-1.4c0-0.2,0-0.4,0-0.7c0-0.2,0.1-0.5,0.1-0.8l0.8-2.7
		c0.1-0.3,0.1-0.5,0.2-0.7c0-0.2,0.1-0.4,0.1-0.6c0-0.3-0.1-0.6-0.2-0.7c-0.1-0.1-0.4-0.2-0.8-0.2c-0.2,0-0.4,0-0.6,0.1
		c-0.2,0.1-0.4,0.1-0.5,0.2l0.2-0.8c0.5-0.2,1-0.4,1.5-0.5c0.5-0.1,0.9-0.2,1.3-0.2c0.7,0,1.3,0.2,1.7,0.5c0.4,0.4,0.6,0.8,0.6,1.4
		c0,0.1,0,0.3,0,0.6c0,0.3-0.1,0.6-0.2,0.8l-0.8,2.7c-0.1,0.2-0.1,0.5-0.2,0.7c0,0.3-0.1,0.5-0.1,0.6c0,0.4,0.1,0.6,0.2,0.7
		c0.2,0.1,0.4,0.2,0.8,0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.5-0.2L14.5,18.6z M14.4,7.5C14,7.9,13.6,8,13.1,8
		c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.7-0.5-1.2c0-0.5,0.2-0.9,0.5-1.2c0.4-0.3,0.8-0.5,1.3-0.5c0.5,0,0.9,0.2,1.3,0.5
		c0.4,0.3,0.5,0.7,0.5,1.2C14.9,6.8,14.7,7.2,14.4,7.5z"/>
            </g>
        </svg>
    )
}

/**
 * Svg Left Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function PageleftIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width={Props.width ? Props.width : '8px'} height={Props.height ? Props.height : '10px'}
            viewBox="0 0 24 24" >

            <g>
                <g>
                    <path fill={Props.disablePagination?"#808080":'#000000de'} d="M9.7,12l9-9C18.9,2.8,19,2.4,19,2.1c0-0.4-0.1-0.7-0.4-0.9l-0.8-0.8C17.6,0.1,17.3,0,17,0S16.3,0.1,16,0.4
			L5.3,11.1C5.1,11.3,4.9,11.7,5,12c0,0.4,0.1,0.7,0.4,0.9L16,23.6c0.2,0.2,0.6,0.4,0.9,0.4c0.4,0,0.7-0.1,0.9-0.4l0.8-0.8
			c0.5-0.5,0.5-1.3,0-1.9L9.7,12z"/>
                </g>
            </g>
        </svg>
    )
}

/**
 * Svg Right Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function PageRightIcon(Props) {
    return (

        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='8px' height='10px' viewBox="0 0 24 24" >

            <g>
                <g>
                    <path fill={Props.disablePagination?"#808080":'#000000de'} d="M5.3,21c-0.5,0.5-0.5,1.3,0,1.9l0.8,0.8C6.4,23.9,6.7,24,7.1,24c0.4,0,0.7-0.1,0.9-0.4l10.7-10.7
			c0.2-0.2,0.4-0.6,0.4-0.9c0-0.4-0.1-0.7-0.4-0.9L8,0.4C7.7,0.1,7.4,0,7,0C6.7,0,6.4,0.1,6.1,0.4L5.3,1.2C5.1,1.4,5,1.7,5,2.1
			C5,2.4,5.1,2.8,5.3,3l9,9L5.3,21z"/>
                </g>
            </g>
        </svg>
    )
}

/**
 * Svg double left Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function PageDoubleLeftIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='7px' height='9px'
            viewBox="0 0 24 24">

            <g>
                <g>
                    <path fill={Props.disablePagination?"#808080":'#000000de'} d="M0.4,12c0,0.2,0.1,0.4,0.2,0.6l11.2,11.2c0.2,0.2,0.3,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2l1.2-1.2
			c0.2-0.2,0.2-0.3,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6L4.7,12l9.5-9.5c0.2-0.2,0.2-0.3,0.2-0.6s-0.1-0.4-0.2-0.6l-1.2-1.2
			C12.8,0.1,12.6,0,12.4,0c-0.2,0-0.4,0.1-0.6,0.2L0.6,11.4C0.5,11.6,0.4,11.8,0.4,12z"/>
                    <path fill={Props.disablePagination?"#808080":'#000000de'} d="M9.9,12.6l11.2,11.2c0.2,0.2,0.3,0.2,0.6,0.2s0.4-0.1,0.6-0.2l1.2-1.2c0.2-0.2,0.2-0.3,0.2-0.6
			c0-0.2-0.1-0.4-0.2-0.6L13.9,12l9.5-9.5c0.2-0.2,0.2-0.3,0.2-0.6s-0.1-0.4-0.2-0.6l-1.2-1.2C22,0.1,21.8,0,21.6,0
			c-0.2,0-0.4,0.1-0.6,0.2L9.9,11.4c-0.2,0.2-0.2,0.3-0.2,0.6C9.6,12.2,9.7,12.4,9.9,12.6z"/>
                </g>
            </g>
        </svg>
    )
}

/**
 * Svg double Right Icon
 * @param props
 * @returns {*}
 * @constructor
 */
export function PageDoubleRightIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='7px' height='9px'
            viewBox="0 0 24 24">

            <g>
                <g>
                    <path fill={props.disablePagination?"#808080":'#000000de'} d="M23.4,11.4L12.2,0.2C12,0.1,11.8,0,11.6,0c-0.2,0-0.4,0.1-0.6,0.2L9.9,1.4C9.7,1.6,9.6,1.8,9.6,2
			s0.1,0.4,0.2,0.6l9.5,9.5l-9.5,9.5c-0.2,0.2-0.2,0.3-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6l1.2,1.2c0.2,0.2,0.3,0.2,0.6,0.2
			c0.2,0,0.4-0.1,0.6-0.2l11.2-11.2c0.2-0.2,0.2-0.3,0.2-0.6S23.5,11.6,23.4,11.4z"/>
                    <path fill={props.disablePagination?"#808080":'#000000de'} d="M14.4,12c0-0.2-0.1-0.4-0.2-0.6L2.9,0.2C2.8,0.1,2.6,0,2.4,0S2,0.1,1.8,0.2L0.6,1.4C0.5,1.6,0.4,1.8,0.4,2
			s0.1,0.4,0.2,0.6l9.5,9.5l-9.5,9.5c-0.2,0.2-0.2,0.3-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6l1.2,1.2C2,23.9,2.2,24,2.4,24s0.4-0.1,0.6-0.2
			l11.2-11.2C14.3,12.4,14.4,12.2,14.4,12z"/>
                </g>
            </g>
        </svg>
    )
}

/**
 * Svg Search Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function SearchIcon(Props) {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  width={Props.width ? Props.width : '18px'} height={Props.height ? Props.height : '20px'}
            viewBox="0 0 23.53 24">
            <g id="surface1">
                <path fill={Props.fill ? Props.fill : '#fff'} d="M16.63,14.24a9,9,0,1,0-2.78,2.56l6.66,6.61a1.89,1.89,0,0,0,2.74-2.6.46.46,0,0,1-.07-.07Zm-7.37.62A5.82,5.82,0,1,1,15.07,9a5.83,5.83,0,0,1-5.81,5.83Z"
                    transform="translate(-0.24 0)" />
            </g></svg>
    )
}


export function DisclaimerIcon(){
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-exclamation-circle"
            viewBox="0 0 16 16"
            style={{
            marginBottom: "-3px",
            color: "red",
            flex: "none",
            }}
        >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
        </svg>
    )
}

/**
 * Svg Notification Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function NotificationIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height='20px' width='20px'
            viewBox="0 0 24 24" >

            <g>
                <g>
                    <g>
                        <path fill='#fff' d="M21.9,20.2l-1.7-2.9C19.4,16.1,19,14.5,19,13v-2.5c0-3.2-2.1-5.8-5-6.7V2c0-1.1-0.9-2-2-2s-2,0.9-2,2v1.8
				c-2.9,0.9-5,3.5-5,6.7V13c0,1.5-0.4,3.1-1.2,4.4l-1.7,2.9c-0.1,0.2-0.1,0.3,0,0.5C2.2,20.9,2.3,21,2.5,21h19
				c0.2,0,0.3-0.1,0.4-0.3C22,20.6,22,20.4,21.9,20.2z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path fill='#fff' d="M8.9,22c0.6,1.2,1.8,2,3.1,2s2.6-0.8,3.1-2H8.9z" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

/**
 * Svg comments Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function CommentsIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='15px' height='14px'
            viewBox="0 0 24 24" >

            <g>
                <g>
                    <path fill='#777777' d="M14.2,15.4c1.5-0.6,2.6-1.4,3.4-2.5c0.8-1.1,1.3-2.2,1.3-3.4c0-1.2-0.4-2.4-1.3-3.4c-0.8-1.1-2-1.9-3.4-2.5
			c-1.5-0.6-3-0.9-4.7-0.9c-1.7,0-3.3,0.3-4.7,0.9C3.2,4.1,2.1,4.9,1.3,6C0.4,7,0,8.2,0,9.4c0,1.1,0.3,2.1,1,3
			c0.6,0.9,1.5,1.7,2.6,2.4c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.4
			C2.1,16.9,2,17,2,17c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0.1,0,0.1l0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
			c0,0,0,0.1,0,0.1c0,0.1,0.1,0.2,0.2,0.3C2,18,2.1,18,2.2,18h0c0.4-0.1,0.8-0.1,1.2-0.2c1.4-0.4,2.6-0.9,3.7-1.7
			c0.8,0.1,1.6,0.2,2.4,0.2C11.1,16.3,12.7,16,14.2,15.4z M6.7,14.2l-0.6,0.4c-0.3,0.2-0.5,0.3-0.8,0.5l0.5-1.1l-1.3-0.7
			c-0.9-0.5-1.5-1.1-2-1.8C2,10.9,1.7,10.2,1.7,9.4c0-0.9,0.4-1.8,1.1-2.6C3.5,6.1,4.4,5.4,5.6,5c1.2-0.5,2.5-0.7,3.8-0.7
			s2.6,0.2,3.8,0.7c1.2,0.5,2.1,1.1,2.8,1.9c0.7,0.8,1.1,1.6,1.1,2.6c0,0.9-0.4,1.8-1.1,2.6c-0.7,0.8-1.6,1.4-2.8,1.9
			c-1.2,0.5-2.5,0.7-3.8,0.7c-0.7,0-1.4-0.1-2-0.2L6.7,14.2z"/>
                    <path fill='#777777' d="M23,15.9c0.6-0.9,1-1.9,1-3c0-1.1-0.3-2.1-1-3.1c-0.7-1-1.6-1.7-2.7-2.4c0.2,0.7,0.3,1.3,0.3,2
			c0,1.2-0.3,2.3-0.9,3.4c-0.6,1.1-1.5,2-2.6,2.8c-1,0.7-2.2,1.3-3.5,1.7c-1.3,0.4-2.7,0.6-4.1,0.6c-0.3,0-0.7,0-1.2-0.1
			c1.8,1.2,3.9,1.8,6.3,1.8c0.8,0,1.6-0.1,2.4-0.2c1.1,0.8,2.3,1.4,3.7,1.7c0.3,0.1,0.7,0.2,1.2,0.2c0.1,0,0.2,0,0.3-0.1
			c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.1,0-0.1c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.1l0-0.1c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1
			c0,0,0,0-0.1-0.1c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.2-0.3-0.3c-0.2-0.2-0.3-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.3-0.4
			c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.2-0.4-0.3-0.6C21.5,17.6,22.4,16.8,23,15.9z"/>
                </g>
            </g>
        </svg>
    )
}

/**
 * Svg Print Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function PrintIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='14px' height='14px'
            viewBox="0 0 24 24" >

            <g>
                <path fill='#777777' d="M23.2,11c-0.5-0.5-1.2-0.8-2-0.8h-0.9V6.5c0-0.4-0.1-0.8-0.3-1.3c-0.2-0.5-0.4-0.8-0.7-1.1l-2.2-2.2
		c-0.3-0.3-0.6-0.5-1.1-0.7c-0.5-0.2-0.9-0.3-1.3-0.3H5.1c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1v7.8H2.8
		c-0.8,0-1.4,0.3-2,0.8c-0.5,0.5-0.8,1.2-0.8,2v6c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h3.2v2.3c0,0.4,0.1,0.7,0.4,1
		c0.3,0.3,0.6,0.4,1,0.4h13.8c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1v-2.3h3.2c0.1,0,0.2,0,0.3-0.1C24,19.2,24,19,24,18.9v-6
		C24,12.2,23.7,11.5,23.2,11z M18.5,21.2H5.5v-3.7h12.9V21.2z M18.5,12H5.5V2.8h9.2v2.3c0,0.4,0.1,0.7,0.4,1c0.3,0.3,0.6,0.4,1,0.4
		h2.3L18.5,12L18.5,12z M21.9,13.6c-0.2,0.2-0.4,0.3-0.6,0.3c-0.3,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6s0.1-0.5,0.3-0.6
		c0.2-0.2,0.4-0.3,0.6-0.3c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6C22.2,13.2,22.1,13.4,21.9,13.6z"/>
            </g>
        </svg>
    )
}

/**
 * Svg Uncliam Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function UnclaimIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='14px' height='14px'
            viewBox="0 0 24 24">

            <g>
                <path fill='#777777' d="M20,14.5v-6c0-0.4-0.1-0.9-0.3-1.4s-0.5-0.9-0.8-1.2l-4.9-4.9c-0.3-0.3-0.7-0.5-1.2-0.8
		c-0.5-0.2-1-0.3-1.4-0.3h-10C1.1,0,0.7,0.1,0.4,0.4C0.2,0.7,0,1.1,0,1.5v21c0,0.4,0.2,0.8,0.4,1.1C0.7,23.8,1.1,24,1.5,24h17
		c0.4,0,0.8-0.2,1.1-0.4c0.3-0.3,0.4-0.6,0.4-1.1v-6h4v-2H20z M12,2.1c0.3,0.1,0.5,0.2,0.6,0.3l4.9,4.9c0.1,0.1,0.2,0.3,0.3,0.6H12
		V2.1z M18,22H2V2h8v6.5c0,0.4,0.2,0.8,0.4,1.1c0.3,0.3,0.6,0.4,1.1,0.4H18v4.5h-3.8l0-2.5l-4.3,3.2l4.3,3.1l0-1.8H18V22z"/>
            </g>
        </svg>
    )
}

/**
 * Svg Rocket Icon
 * @param props
 * @returns {*}
 * @constructor
 */
export function RocketIcon(Props) {
    return (
        <svg width='14px' height='14px' viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path fill='#777777' d="m23.508.003c-4.685-.084-10.028 2.365-13.41 6.164-3.232.061-6.379 1.386-8.696 3.703-.135.133-.183.332-.124.512.06.181.216.312.404.339l3.854.552-.476.533c-.177.198-.168.499.02.687l6.427 6.427c.097.097.225.146.354.146.119 0 .238-.042.333-.127l.533-.476.552 3.854c.027.188.175.326.354.386.046.015.094.022.143.022.142 0 .287-.062.387-.161 2.285-2.2853.61-5.432 3.671-8.664 3.803-3.389 6.272-8.73 6.163-13.409-.007-.266-.222-.481-.489-.488zm-4.608 8.632c-.487.487-1.127.731-1.768.731s-1.281-.244-1.768-.731c-.974-.975-.974-2.561 0-3.536.975-.975 2.561-.975 3.536 0s.975 2.562 0 3.536z" />
            <path fill='#777777' d="m2.724 16.905c-1.07 1.07-2.539 5.904-2.703 6.451-.053.176-.004.367.125.497.096.096.223.147.354.147.048 0 .096-.007.144-.021.547-.164 5.381-1.633 6.451-2.703 1.205-1.205 1.205-3.166 0-4.371-1.206-1.205-3.166-1.204-4.371 0z" /></svg>
    )
}

/**
 * Svg Unlink Icon
 * @param props
 * @returns {*}
 * @constructor
 */
export function UnlinkIcon(Props) {
    return (
        <svg height="14px" viewBox="0 0 512.101 512.101"
            width="14px" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path fill='#777777' d="m234.783 173.725c3.885 3.883 10.182 3.883 14.067 0l82.858-82.822c24.735-24.724 64.827-24.724 89.562 0 24.729 24.718 24.729 64.805 0 89.523l-82.899 82.863c-3.861 3.86-3.861 10.119 0 13.979l34.026 34.011c3.885 3.883 10.182 3.883 14.067 0l87.188-87.149c51.264-51.241 51.264-134.344 0-185.585-51.277-51.255-134.39-51.255-185.667 0l-87.229 87.19c-3.861 3.86-3.861 10.119 0 13.979z" />
                <path fill='#777777' d="m274.06 341.633c-3.885-3.883-10.182-3.883-14.067 0l-79.6 79.565c-24.735 24.724-64.827 24.724-89.562 0-24.729-24.718-24.729-64.805 0-89.523l79.641-79.606c3.861-3.86 3.861-10.119 0-13.979l-34.026-34.011c-3.885-3.883-10.182-3.883-14.067 0l-83.93 83.893c-51.264 51.241-51.264 134.344 0 185.585 51.277 51.255 134.39 51.255 185.667 0l83.971-83.933c3.861-3.86 3.861-10.119 0-13.979z" />
                <path fill='#777777' d="m11.579 168.401 70.468 17.609c1.221.305 2.444.451 3.648.451 6.722 0 12.838-4.551 14.541-11.367 2.009-8.037-2.878-16.181-10.916-18.189l-70.468-17.609c-8.038-2.005-16.181 2.879-18.189 10.916-2.008 8.036 2.879 16.18 10.916 18.189z" />
                <path fill='#777777' d="m117.947 139.217c2.929 2.928 6.767 4.391 10.604 4.391 3.84 0 7.68-1.465 10.609-4.396 5.856-5.859 5.854-15.357-.004-21.213l-54.612-54.588c-5.86-5.857-15.357-5.855-21.213.004s-5.854 15.357.004 21.213z" />
                <path fill='#777777' d="m156.868 89.399c1.705 6.815 7.82 11.364 14.541 11.364 1.205 0 2.429-.146 3.651-.452 8.037-2.01 12.922-10.155 10.912-18.191l-17.617-70.437c-2.01-8.037-10.154-12.924-18.191-10.912-8.037 2.01-12.922 10.155-10.912 18.191z" />
                <path fill='#777777' d="m500.521 343.7-70.468-17.609c-8.036-2.005-16.18 2.878-18.189 10.917-2.008 8.037 2.879 16.18 10.917 18.189l70.468 17.609c1.221.305 2.443.451 3.647.451 6.722 0 12.838-4.551 14.542-11.367 2.008-8.038-2.879-16.182-10.917-18.19z" />
                <path fill='#777777' d="m394.154 372.884c-5.86-5.857-15.357-5.855-21.213.004s-5.854 15.357.004 21.213l54.613 54.589c2.929 2.928 6.767 4.391 10.604 4.391 3.84 0 7.68-1.465 10.609-4.396 5.856-5.859 5.854-15.357-.004-21.213z" />
                <path fill='#777777' d="m355.233 422.702c-2.01-8.037-10.152-12.923-18.191-10.912-8.037 2.01-12.922 10.155-10.912 18.191l17.617 70.437c1.705 6.815 7.819 11.364 14.541 11.364 1.205 0 2.429-.146 3.651-.452 8.037-2.01 12.922-10.155 10.912-18.191z" />
            </g></svg>
    )
}




/**
 * Svg Rocket Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function TransferIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='14px' height='14px'
            viewBox="0 0 24 24" >

            <g>
                <path fill='#36aebe' d="M22.5,11.6h-7.7c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h7.7c0.4,0,0.8,0.3,0.8,0.8S22.9,11.6,22.5,11.6z" />
                <path fill='#36aebe' d="M20.1,13.9c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l1.8-1.8l-1.8-1.8c-0.3-0.3-0.3-0.8,0-1.1
		s0.8-0.3,1.1,0l2.3,2.3c0.3,0.3,0.3,0.8,0,1.1l-2.3,2.3C20.5,13.9,20.3,13.9,20.1,13.9z"/>
                <path fill='#36aebe' d="M17.8,7.7c0.4,0,0.8-0.3,0.8-0.8V0.8c0-0.4-0.3-0.8-0.8-0.8H1.5c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
		c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0C1,0.3,0.9,0.3,0.9,0.4c0,0,0,0-0.1,0.1c0,0.1-0.1,0.2-0.1,0.3v20.1
		c0,0.4,0.3,0.7,0.6,0.8L12.2,24c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.5-0.2c0.2-0.1,0.3-0.4,0.3-0.6v-1.5h4.6c0.4,0,0.8-0.3,0.8-0.8
		v-6.2c0-0.4-0.3-0.8-0.8-0.8S17,14.3,17,14.7v5.4h-3.9v-17c0-0.4-0.3-0.7-0.6-0.8L8.9,1.5H17V7C17,7.4,17.4,7.7,17.8,7.7z
		 M11.6,22.3l-9.3-2V1.7l9.3,2V22.3z"/>
            </g>
        </svg>
    )
}
/**
 * Svg XMl Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function XmlIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='14px' height='15px'
            viewBox="0 0 24 24" className={props.className}>

            <g>
                <path fill={props.fill ? props.fill : '#000000de'} d="M21.3,8.6h-0.6V5.8c0,0,0,0,0-0.1c0-0.1,0-0.2-0.1-0.3L16,0.2c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
		c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0H4.2C3.7,0,3.3,0.4,3.3,0.9v7.6H2.7C2,8.6,1.4,9.2,1.4,9.9v7c0,0.7,0.6,1.3,1.3,1.3
		h0.6v4.8c0,0.5,0.4,0.9,0.9,0.9h15.5c0.5,0,0.9-0.4,0.9-0.9v-4.8h0.6c0.7,0,1.3-0.6,1.3-1.3v-7C22.6,9.2,22,8.6,21.3,8.6z M4.2,0.9
		h10.9v4.8c0,0.3,0.2,0.5,0.5,0.5h4.2v2.4H4.2V0.9z M16.1,16.3h-1.4l-0.1-2.4c0-0.8-0.1-1.7-0.1-2.6h0c-0.2,0.8-0.5,1.7-0.7,2.4
		l-0.8,2.5h-1.1l-0.7-2.4c-0.2-0.7-0.4-1.6-0.6-2.5h0c0,0.9-0.1,1.8-0.1,2.6l-0.1,2.4H9.1L9.5,10h1.9l0.6,2.1
		c0.2,0.7,0.4,1.5,0.5,2.3h0c0.2-0.7,0.4-1.6,0.6-2.3l0.7-2.1h1.9L16.1,16.3z M3,16.3l1.8-3.2L3.1,10h1.6l0.6,1.2
		c0.2,0.4,0.3,0.7,0.5,1h0c0.1-0.4,0.3-0.7,0.4-1L6.8,10h1.6l-1.8,3.1l1.9,3.2H6.8l-0.6-1.1C6,14.7,5.9,14.4,5.7,14h0
		c-0.1,0.4-0.3,0.7-0.5,1.1l-0.5,1.1L3,16.3L3,16.3z M19.8,22.8H4.2v-4.5h15.5L19.8,22.8L19.8,22.8z M21.1,16.3h-3.9V10h1.4v5.1h2.5
		L21.1,16.3L21.1,16.3z"/>
            </g>
        </svg>

    )
}

/**
 * Svg copy Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function CopyIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height='14px' width='14px'
            viewBox="0 0 24 24" >
            <path fill='#36aebe' d="M20,4V0H0v20h4v4h20V4H20z M1,19V1h18v3H4v15H1z M23,23H5V5h18V23z" />
        </svg>

    )
}

/**
 * Svg Eye Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function EyeIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='14px' height='14px'

            viewBox="0 0 24 24" >

            <g>
                <path fill={Props.fill} d="M23.7,11.2c-0.2-0.2-5.2-6.1-11.7-6.1c-6.4,0-11.5,5.8-11.7,6.1c-0.4,0.5-0.4,1.2,0,1.7
		c0.2,0.2,5.2,6.1,11.7,6.1c6.4,0,11.5-5.8,11.7-6.1C24.1,12.4,24.1,11.6,23.7,11.2z M14,8.6c0.4-0.2,1,0,1.3,0.6
		c0.3,0.6,0.2,1.2-0.2,1.5c-0.4,0.2-1,0-1.3-0.6C13.4,9.5,13.5,8.8,14,8.6z M12,16.8c-4.5,0-8.3-3.5-9.6-4.8
		c0.9-0.9,2.9-2.8,5.5-3.9c-0.5,0.8-0.8,1.7-0.8,2.7c0,2.7,2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9c0-1-0.3-1.9-0.8-2.7
		c2.6,1.1,4.6,3,5.5,3.9C20.3,13.4,16.5,16.8,12,16.8z"/>
            </g>
        </svg>
    )
}

/**
 * Svg Play Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function PlayIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='24px' height='24px'
            viewBox="0 0 24 24">

            <g>
                <path fill='#777777' d="M24,11.6C23.8,5.3,18.7,0.2,12.4,0C9-0.1,5.8,1.2,3.4,3.7C1.1,6-0.1,9.1,0,12.4C0.2,18.7,5.3,23.8,11.6,24
		c0.1,0,0.3,0,0.4,0c3.3,0,6.3-1.3,8.6-3.7C22.9,18,24.1,14.9,24,11.6z M18.8,18.6c-1.8,1.9-4.2,2.9-6.8,2.9c-0.1,0-0.2,0-0.3,0
		c-5-0.2-9-4.2-9.2-9.2c-0.1-2.6,0.9-5,2.7-6.9C7,3.6,9.4,2.5,12,2.5c0.1,0,0.2,0,0.3,0c5,0.2,9,4.2,9.2,9.2
		C21.6,14.3,20.6,16.7,18.8,18.6z"/>
                <path fill='#777777' d="M15.9,11.3l-5.3-3.8c-0.6-0.4-1.4,0-1.4,0.7v7.6c0,0.7,0.8,1.1,1.4,0.7l5.3-3.8C16.4,12.4,16.4,11.6,15.9,11.3
		z"/>
            </g>
        </svg>
    )
}

/**
 * Svg City Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function CityIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height={Props.height ? Props.height : '16px'} width={Props.width ? Props.width : '16px'}
            viewBox="0 0 24 24" >

            <g>
                <g>
                    <g>
                        <path fill={Props.fill ? Props.fill : '#909090'} d="M2.4,17.6C2.2,17.6,2,17.8,2,18v0.8c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4V18C2.8,17.8,2.6,17.6,2.4,17.6z"
                        />
                        <path fill={Props.fill ? Props.fill : '#909090'} d="M2.4,20C2.2,20,2,20.2,2,20.4v0.8c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4v-0.8C2.8,20.2,2.6,20,2.4,20z" />
                        <path fill={Props.fill ? Props.fill : '#909090'} d="M2.4,15.2c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4v-0.8
				C2.8,15.4,2.6,15.2,2.4,15.2z"/>
                        <path fill={Props.fill ? Props.fill : '#909090'} d="M4,17.6c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4V18C4.4,17.8,4.2,17.6,4,17.6z" />
                        <path fill={Props.fill ? Props.fill : '#909090'} d="M4,15.2c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4v-0.8C4.4,15.4,4.2,15.2,4,15.2z"
                        />
                        <path fill={Props.fill ? Props.fill : '#909090'} d="M4,20c-0.2,0-0.4,0.2-0.4,0.4v0.8c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4v-0.8C4.4,20.2,4.2,20,4,20z" />
                        <path fill={Props.fill ? Props.fill : '#909090'} d="M23.2,4.4h-0.8V3.2c0-0.2-0.2-0.4-0.4-0.4h-2.4c-0.2,0-0.4,0.2-0.4,0.4v1.2h-0.8c-0.2,0-0.4,0.2-0.4,0.4v6.4
				h-0.4V10c0-0.2-0.2-0.4-0.4-0.4h-0.4V9.2c0-0.2-0.2-0.4-0.4-0.4S16,9,16,9.2v0.4h-0.4c-0.2,0-0.4,0.2-0.4,0.4v1.2H14v-8
				c0-0.2-0.2-0.4-0.4-0.4h-1.2V0.4C12.4,0.2,12.2,0,12,0H7.2C7,0,6.8,0.2,6.8,0.4v2.4H5.6C5.4,2.8,5.2,3,5.2,3.2v10.4H4.4v-1.2
				C4.4,12.2,4.2,12,4,12H1.6c-0.2,0-0.4,0.2-0.4,0.4v1.2H0.8c-0.2,0-0.4,0.2-0.4,0.4v9.6c0,0.2,0.2,0.4,0.4,0.4h4.8h3.2h9.6h4.8
				c0.2,0,0.4-0.2,0.4-0.4V4.8C23.6,4.6,23.4,4.4,23.2,4.4z M11.6,4.8c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4v6.4h-0.8
				L11.6,4.8L11.6,4.8z M10,2c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v1.2c0,0.2-0.2,0.4-0.4,0.4S10,3.4,10,3.2V2z M10,4.8
				c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v6.4H10L10,4.8L10,4.8z M8.4,2c0-0.2,0.2-0.4,0.4-0.4C9,1.6,9.2,1.8,9.2,2v1.2
				c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4V2z M6.8,4.8c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4v8.8H6.8L6.8,4.8
				L6.8,4.8z M8.4,22.4H7.2c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h1.2V22.4z M8.4,20.8H7.2c-0.2,0-0.4-0.2-0.4-0.4S7,20,7.2,20
				h1.2V20.8z M8.4,19.2H7.2c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h1.2V19.2z M8.4,17.6H7.2c-0.2,0-0.4-0.2-0.4-0.4
				s0.2-0.4,0.4-0.4h1.2V17.6z M8.4,15.2H5.6c-0.2,0-0.4,0.2-0.4,0.4v7.6h-4v-8.8h0.4H4h1.6h1.6h1.2L8.4,15.2L8.4,15.2z M8.4,11.6
				V4.8c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4v6.4H8.8C8.6,11.2,8.4,11.4,8.4,11.6z M18,23.2h-0.8v-10c0-0.2-0.2-0.4-0.4-0.4
				s-0.4,0.2-0.4,0.4v10h-0.8v-10c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4v10H14v-10c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4v10
				h-0.8v-10c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4v10h-0.8v-10c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4v10H9.2V22
				v-1.6v-1.6v-1.6v-1.6V14v-2h1.2H12h1.6h2h1.6H18V23.2z M20.4,21.7c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4v-0.8
				c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4V21.7z M20.4,19.3c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4v-0.8c0-0.2,0.2-0.4,0.4-0.4
				s0.4,0.2,0.4,0.4V19.3z M20.4,16.9c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4v-0.8c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4V16.9z
				 M20.4,14.5c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4v-0.8c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4V14.5z M20.4,12.1
				c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4v-0.8c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4V12.1z M20.4,9.7c0,0.2-0.2,0.4-0.4,0.4
				s-0.4-0.2-0.4-0.4V8.9c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4V9.7z M20.4,7.3c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4V6.5
				c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4V7.3z M22,21.7c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4v-0.8c0-0.2,0.2-0.4,0.4-0.4
				c0.2,0,0.4,0.2,0.4,0.4V21.7z M22,19.3c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4v-0.8c0-0.2,0.2-0.4,0.4-0.4
				c0.2,0,0.4,0.2,0.4,0.4V19.3z M22,16.9c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4v-0.8c0-0.2,0.2-0.4,0.4-0.4
				c0.2,0,0.4,0.2,0.4,0.4V16.9z M22,14.5c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4v-0.8c0-0.2,0.2-0.4,0.4-0.4
				c0.2,0,0.4,0.2,0.4,0.4V14.5z M22,12.1c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4v-0.8c0-0.2,0.2-0.4,0.4-0.4
				c0.2,0,0.4,0.2,0.4,0.4V12.1z M22,9.7c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4V8.9c0-0.2,0.2-0.4,0.4-0.4
				c0.2,0,0.4,0.2,0.4,0.4V9.7z M22,7.3c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4V6.5c0-0.2,0.2-0.4,0.4-0.4
				c0.2,0,0.4,0.2,0.4,0.4V7.3z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

/**
 * Svg Table Icon
 * @param props
 * @returns {*}
 * @constructor
 */
export function TableIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height={props.height ? props.height : '15px'} width={props.width ? props.width : '14px'}
            viewBox="0 0 24 24" className={props.className} >

            <g>

                <path fill={props.fill ? props.fill : '#36aebe'} d="M23.3,2.5c-0.5-0.5-1-0.7-1.6-0.7H2.3c-0.6,0-1.2,0.2-1.6,0.7C0.2,3,0,3.5,0,4.2v15.7c0,0.6,0.2,1.2,0.7,1.6
		c0.5,0.5,1,0.7,1.6,0.7h19.4c0.6,0,1.2-0.2,1.6-0.7c0.5-0.5,0.7-1,0.7-1.6V4.2C24,3.5,23.8,3,23.3,2.5z M7.4,19.8
		c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1H2.3c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-2.8c0-0.1,0-0.2,0.1-0.3
		c0.1-0.1,0.2-0.1,0.3-0.1h4.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3L7.4,19.8L7.4,19.8z M7.4,14.3c0,0.1,0,0.2-0.1,0.3
		c-0.1,0.1-0.2,0.1-0.3,0.1H2.3c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-2.8c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1
		h4.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3L7.4,14.3L7.4,14.3z M7.4,8.8c0,0.1,0,0.2-0.1,0.3C7.2,9.2,7.1,9.2,6.9,9.2H2.3
		c-0.1,0-0.2,0-0.3-0.1C1.9,9,1.8,8.9,1.8,8.8V6c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h4.6c0.1,0,0.2,0,0.3,0.1
		C7.3,5.8,7.4,5.9,7.4,6L7.4,8.8L7.4,8.8z M14.8,19.8c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1H9.7c-0.1,0-0.2,0-0.3-0.1
		c-0.1-0.1-0.1-0.2-0.1-0.3v-2.8c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h4.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3
		L14.8,19.8L14.8,19.8L14.8,19.8z M14.8,14.3c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1H9.7c-0.1,0-0.2,0-0.3-0.1
		c-0.1-0.1-0.1-0.2-0.1-0.3v-2.8c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h4.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3
		L14.8,14.3L14.8,14.3z M14.8,8.8c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1H9.7c-0.1,0-0.2,0-0.3-0.1C9.3,9,9.2,8.9,9.2,8.8V6
		c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h4.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3L14.8,8.8L14.8,8.8z M22.2,19.8
		c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-4.6c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-2.8c0-0.1,0-0.2,0.1-0.3
		c0.1-0.1,0.2-0.1,0.3-0.1h4.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3V19.8z M22.2,14.3c0,0.1,0,0.2-0.1,0.3
		c-0.1,0.1-0.2,0.1-0.3,0.1h-4.6c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-2.8c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1
		h4.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3V14.3z M22.2,8.8c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-4.6
		c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3V6c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h4.6c0.1,0,0.2,0,0.3,0.1
		c0.1,0.1,0.1,0.2,0.1,0.3V8.8z"/>
            </g>
        </svg>
    )
}

/**
 * Svg Upload Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function UploadIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='13px' height='14px'
            viewBox="0 0 24 24" >

            <g>
                <g>
                    <path fill='#777777' d="M23.6,16.6c-0.3-0.3-0.6-0.4-1-0.4h-6.2c-0.2,0.5-0.5,1-1,1.3c-0.5,0.3-1,0.5-1.6,0.5h-3.7
			c-0.6,0-1.1-0.2-1.6-0.5c-0.5-0.3-0.8-0.8-1-1.3H1.4c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.4,0.6-0.4,1v4.6c0,0.4,0.1,0.7,0.4,1
			c0.3,0.3,0.6,0.4,1,0.4h21.2c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1v-4.6C24,17.2,23.9,16.8,23.6,16.6z M18.2,21.4
			c-0.2,0.2-0.4,0.3-0.6,0.3c-0.3,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6c0.2-0.2,0.4-0.3,0.6-0.3
			c0.3,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6C18.5,21,18.4,21.2,18.2,21.4z M21.9,21.4c-0.2,0.2-0.4,0.3-0.6,0.3
			c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6c0.2-0.2,0.4-0.3,0.6-0.3s0.5,0.1,0.6,0.3
			c0.2,0.2,0.3,0.4,0.3,0.6C22.2,21,22.1,21.2,21.9,21.4z"/>
                    <path fill='#777777' d="M5.5,8.8h3.7v6.5c0,0.2,0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3h3.7c0.2,0,0.5-0.1,0.6-0.3
			c0.2-0.2,0.3-0.4,0.3-0.6V8.8h3.7c0.4,0,0.7-0.2,0.9-0.6c0.2-0.4,0.1-0.7-0.2-1l-6.5-6.5c-0.2-0.2-0.4-0.3-0.6-0.3
			c-0.3,0-0.5,0.1-0.6,0.3L4.9,7.2c-0.3,0.3-0.4,0.6-0.2,1C4.9,8.6,5.1,8.8,5.5,8.8z"/>
                </g>
            </g>
        </svg>
    )
}

/**
 * Svg Close Icon
 * @param props
 * @returns {*}
 * @constructor
 */
export function CloseIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='15px' height='24px' onClick={Props.onClick}
            viewBox="0 0 24 24" >



            <path fill={Props.fill ? Props.fill : '#0000008a'} d="M24,2.8L21.2,0L12,9.2L2.8,0L0,2.8L9.2,12L0,21.2L2.8,24l9.2-9.2l9.2,9.2l2.8-2.8L14.8,12L24,2.8z" />
        </svg>
    )
}

/**
 * Svg Chart Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function ChartIcon(Props) {
    return (

        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24" >

            <path d="M53,23.1c0,1-0.8,1.8-1.8,1.8H30.9c-1,0-1.8-0.8-1.8-1.8V4.6c0-1,0.8-1.8,1.8-1.8h1.8V1.4c0-1.3,1-2.3,2.3-2.3
	H36c1.3,0,2.3,1,2.3,2.3v1.4h5.5V1.4c0-1.3,1-2.3,2.3-2.3H47c1.3,0,2.3,1,2.3,2.3v1.4h1.8c1,0,1.8,0.8,1.8,1.8V23.1z M35,12.4V8.3
	h-4.2v4.2H35z M35,18v-4.6h-4.2V18H35z M35,23.1v-4.2h-4.2v4.2H35z M36.4,1.4c0-0.2-0.2-0.5-0.5-0.5H35c-0.2,0-0.5,0.2-0.5,0.5v4.2
	C34.6,5.8,34.8,6,35,6H36c0.2,0,0.5-0.2,0.5-0.5V1.4z M40.6,12.4V8.3H36v4.2H40.6z M40.6,18v-4.6H36V18H40.6z M40.6,23.1v-4.2H36
	v4.2H40.6z M46.1,12.4V8.3h-4.6v4.2H46.1z M46.1,18v-4.6h-4.6V18H46.1z M46.1,23.1v-4.2h-4.6v4.2H46.1z M47.5,1.4
	c0-0.2-0.2-0.5-0.5-0.5h-0.9c-0.2,0-0.5,0.2-0.5,0.5v4.2c0,0.2,0.2,0.5,0.5,0.5H47c0.2,0,0.5-0.2,0.5-0.5V1.4z M51.2,12.4V8.3H47
	v4.2H51.2z M51.2,18v-4.6H47V18H51.2z M51.2,23.1v-4.2H47v4.2H51.2z"/>
            <g>
                <path id="Path_29_1_" d="M1.4,20.9v-19H0v20.1h24v-1.2H1.4z" />
                <path id="Path_30_1_" d="M3,7.3h4.3v12.1H3V7.3z" />
                <path id="Path_31_1_" d="M9.9,4.6h4.3v14.8H9.9V4.6z" />
                <path id="Path_32_1_" d="M16.8,10.1H21v9.3h-4.3V10.1z" />
            </g>
        </svg>
    )
}

/**
 * Svg File Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function FileIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='20px' height='24'
            viewBox="0 0 24 24" >

            <path fill='#777777' d="M21.3,8.6h-0.6V5.8c0,0,0,0,0-0.1c0-0.1,0-0.2-0.1-0.3L16,0.2c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
	c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0H4.2C3.7,0,3.3,0.4,3.3,0.9v7.6H2.7C2,8.6,1.4,9.2,1.4,9.9v7c0,0.7,0.6,1.3,1.3,1.3
	h0.6v4.8c0,0.5,0.4,0.9,0.9,0.9h15.5c0.5,0,0.9-0.4,0.9-0.9v-4.8h0.6c0.7,0,1.3-0.6,1.3-1.3v-7C22.6,9.2,22,8.6,21.3,8.6z M4.2,0.9
	h10.9v4.8c0,0.3,0.2,0.5,0.5,0.5h4.2v2.4H4.2V0.9z M19.8,22.8H4.2v-4.5h15.5L19.8,22.8L19.8,22.8z"/>
        </svg>
    )
}

/**
 * Svg Image Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function ImageIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='24px' height='24'
            viewBox="0 0 24 24" >
            <g>
                <g>
                    <path fill='#777777' d="M2.4,24L2.4,24l19.1,0v0H2.4z" />
                    <path fill='#777777' d="M14.9,0H2.4v24h19.1V6.3L14.9,0z M19.5,5.8l-4.1-0.1V1.9l0.7,0.7L19.5,5.8z M20.5,23H3.4V1h10.9v5.7h0.2
			l5.9,0.2V23z"/>
                </g>
                <path fill='#777777' d="M20.6,6.4" />
                <g>
                    <circle fill='#777777' cx="7.5" cy="11.5" r="2" />
                    <polygon fill='#777777' points="4.9,19.2 9.1,14.5 11.8,15.6 14,12.5 18.8,19.2 18.8,21.7 4.9,21.7 		" />
                </g>
            </g>
        </svg>
    )
}

/**
 * Svg Text Icon
 * @param props
 * @returns {*}
 * @constructor
 */
export function TextIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='24px' height='24'
            viewBox="0 0 24 24">

            <g>
                <g id="XMLID_263_">
                    <g id="XMLID_267_">
                        <path fill='#777777' d="M20.4,7.2H14V0.8h1.6v4.8h4.8V7.2z" />
                        <pat fill='#777777' d="M21.2,24H2.8V0h12.3l6.1,6.1V24z M4.4,22.4h15.2V6.7l-5.1-5.1H4.4L4.4,22.4z" />
                    </g>
                    <path fill='#777777' d="M6.8,18.4h10.4V20H6.8V18.4z" />
                    <path fill='#777777' d="M6.8,14.4h10.4V16H6.8V14.4z" />
                    <path fill='#777777' d="M6.8,10.4h10.4V12H6.8V10.4z" />
                </g>
            </g>
        </svg>
    )
}

/**
 * Svg XL Icon
 * @param props
 * @returns {*}
 * @constructor
 */

export function XlIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='24px' height='24'
            viewBox="0 0 24 24">
            <g>
                <g>
                    <path fill='#777777' d="M2.3,0v24h19.4V6.6L15.5,0L2.3,0z M18.8,5.7H16V2.8L18.8,5.7z M3.8,22.5v-21h10.7v5.7h5.7v15.3H3.8L3.8,22.5z" />
                    <path fill='#777777' d="M12.9,11.3c0.1,0,0.1,0,0.1,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.2c0,0.1-0.1,0.2-0.2,0.3l-1,1.6
			l-1-1.6c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.1-0.1-0.2-0.1-0.3h0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0h0.4l0-0.4v-1H7.4v1.4h0.5
			c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.3C8.7,11.8,8.8,12,9,12.1l1.9,2.8l-2.5,4
			c-0.2,0.3-0.4,0.4-0.5,0.5c-0.1,0.1-0.2,0.1-0.4,0.1H7v1.4h4.6v-1.4h-0.5c-0.4,0-0.5-0.1-0.5-0.1l0-0.1c0,0,0,0,0-0.1
			c0-0.1,0.1-0.1,0.1-0.2l1.4-2.2l1.3,1.9c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.1
			c-0.1,0-0.2,0.1-0.6,0.1h-0.4l0,0.4v1H17v-1.4h-0.5c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
			c-0.1-0.1-0.2-0.3-0.3-0.4l-2.2-3.2l2.2-3.6c0.1-0.2,0.3-0.4,0.4-0.4c0.2-0.1,0.3-0.1,0.5-0.1h0.6V9.9h-4.6v1.4h0.4
			C12.7,11.3,12.8,11.3,12.9,11.3z"/>
                </g>
            </g>
        </svg>
    )
}

/**
 * Svg Audio Icon
 * @param props
 * @returns {*}
 * @constructor
 */
export function AudioIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width='24px' height='24'
            viewBox="0 0 24 24" >

            <path fill='#777777' d="M21.1,7.4L14,0.3C13.8,0,13.7,0,13.4,0H5.3C3.5,0,2.7,1.2,2.7,2.6v18.8c0,1.4,1.2,2.6,2.6,2.6h13.5
	c1.4,0,2.6-1.2,2.6-2.6V8.1C21.3,7.7,21.3,7.6,21.1,7.4z M14.2,2.8l4.6,4.5c-1.7,0-3.2,0-4.6-0.1L14.2,2.8z M19.7,21.4
	c0,0.6-0.4,1-1,1H5.3c-0.6,0-1-0.4-1-1V2.6c0-0.6,0.4-1,1-1h7.4L12.5,8c0,0.4,0.3,0.8,0.7,0.8c2.2,0.1,4.3,0.1,6.5,0.1V21.4z"/>
            <path fill='#777777' d="M14.5,12.2c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.2l-0.1,0.1c-0.2,0.2-0.2,0.5,0,0.7
	c0.5,0.6,0.7,1.3,0.7,2c0,0.7-0.2,1.3-0.6,1.9c-0.2,0.2-0.1,0.5,0,0.7l0.1,0.1c0.1,0.1,0.2,0.2,0.4,0.2h0c0.2,0,0.3-0.1,0.4-0.2
	c0.6-0.8,0.9-1.7,0.9-2.6C15.4,14,15.1,13,14.5,12.2z M11.8,10.8c-0.2-0.1-0.4-0.1-0.6,0.1l-3,2.5H6.6c-0.3,0-0.5,0.2-0.5,0.5v2.2
	c0,0.3,0.2,0.5,0.5,0.5h1.6l3,2.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2-0.1c0.2-0.1,0.3-0.3,0.3-0.5v-7.4
	C12.1,11.1,12,10.9,11.8,10.8z"/>
            <path fill='#777777' d="M18.1,15c0,1.7-0.6,3.2-1.7,4.5c-0.1,0.1-0.2,0.2-0.4,0.2c0,0,0,0,0,0c-0.2,0-0.3-0.1-0.4-0.2l-0.1-0.1
	c-0.2-0.2-0.2-0.5,0-0.7c0.9-1,1.4-2.4,1.4-3.7c0-1.4-0.5-2.8-1.5-3.8c-0.2-0.2-0.2-0.5,0-0.7l0.1-0.1c0.1-0.1,0.2-0.2,0.4-0.2
	c0.2,0,0.3,0.1,0.4,0.2C17.5,11.6,18.1,13.3,18.1,15z"/>
        </svg>
    )
}

/**
 * Svg Submit Icon
 * @param props
 * @returns {*}
 * @constructor
 */
export function SubmitIcon(Props) {
    return (
        <svg width='14px' height='14px' viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path fill='#777777' d="m23.508.003c-4.685-.084-10.028 2.365-13.41 6.164-3.232.061-6.379 1.386-8.696 3.703-.135.133-.183.332-.124.512.06.181.216.312.404.339l3.854.552-.476.533c-.177.198-.168.499.02.687l6.427 6.427c.097.097.225.146.354.146.119 0 .238-.042.333-.127l.533-.476.552 3.854c.027.188.175.326.354.386.046.015.094.022.143.022.142 0 .287-.062.387-.161 2.285-2.2853.61-5.432 3.671-8.664 3.803-3.389 6.272-8.73 6.163-13.409-.007-.266-.222-.481-.489-.488zm-4.608 8.632c-.487.487-1.127.731-1.768.731s-1.281-.244-1.768-.731c-.974-.975-.974-2.561 0-3.536.975-.975 2.561-.975 3.536 0s.975 2.562 0 3.536z" />
            <path fill='#777777' d="m2.724 16.905c-1.07 1.07-2.539 5.904-2.703 6.451-.053.176-.004.367.125.497.096.096.223.147.354.147.048 0 .096-.007.144-.021.547-.164 5.381-1.633 6.451-2.703 1.205-1.205 1.205-3.166 0-4.371-1.206-1.205-3.166-1.204-4.371 0z" /></svg>
    )
}

/**
 * Svg Un Submit Icon
 * @param props
 * @returns {*}
 * @constructor
 */
export function UnSubmitIcon(Props) {
    return (
        <svg height="14px" viewBox="0 0 512.101 512.101"
            width="14px" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path fill='#777777' d="m234.783 173.725c3.885 3.883 10.182 3.883 14.067 0l82.858-82.822c24.735-24.724 64.827-24.724 89.562 0 24.729 24.718 24.729 64.805 0 89.523l-82.899 82.863c-3.861 3.86-3.861 10.119 0 13.979l34.026 34.011c3.885 3.883 10.182 3.883 14.067 0l87.188-87.149c51.264-51.241 51.264-134.344 0-185.585-51.277-51.255-134.39-51.255-185.667 0l-87.229 87.19c-3.861 3.86-3.861 10.119 0 13.979z" />
                <path fill='#777777' d="m274.06 341.633c-3.885-3.883-10.182-3.883-14.067 0l-79.6 79.565c-24.735 24.724-64.827 24.724-89.562 0-24.729-24.718-24.729-64.805 0-89.523l79.641-79.606c3.861-3.86 3.861-10.119 0-13.979l-34.026-34.011c-3.885-3.883-10.182-3.883-14.067 0l-83.93 83.893c-51.264 51.241-51.264 134.344 0 185.585 51.277 51.255 134.39 51.255 185.667 0l83.971-83.933c3.861-3.86 3.861-10.119 0-13.979z" />
                <path fill='#777777' d="m11.579 168.401 70.468 17.609c1.221.305 2.444.451 3.648.451 6.722 0 12.838-4.551 14.541-11.367 2.009-8.037-2.878-16.181-10.916-18.189l-70.468-17.609c-8.038-2.005-16.181 2.879-18.189 10.916-2.008 8.036 2.879 16.18 10.916 18.189z" />
                <path fill='#777777' d="m117.947 139.217c2.929 2.928 6.767 4.391 10.604 4.391 3.84 0 7.68-1.465 10.609-4.396 5.856-5.859 5.854-15.357-.004-21.213l-54.612-54.588c-5.86-5.857-15.357-5.855-21.213.004s-5.854 15.357.004 21.213z" />
                <path fill='#777777' d="m156.868 89.399c1.705 6.815 7.82 11.364 14.541 11.364 1.205 0 2.429-.146 3.651-.452 8.037-2.01 12.922-10.155 10.912-18.191l-17.617-70.437c-2.01-8.037-10.154-12.924-18.191-10.912-8.037 2.01-12.922 10.155-10.912 18.191z" />
                <path fill='#777777' d="m500.521 343.7-70.468-17.609c-8.036-2.005-16.18 2.878-18.189 10.917-2.008 8.037 2.879 16.18 10.917 18.189l70.468 17.609c1.221.305 2.443.451 3.647.451 6.722 0 12.838-4.551 14.542-11.367 2.008-8.038-2.879-16.182-10.917-18.19z" />
                <path fill='#777777' d="m394.154 372.884c-5.86-5.857-15.357-5.855-21.213.004s-5.854 15.357.004 21.213l54.613 54.589c2.929 2.928 6.767 4.391 10.604 4.391 3.84 0 7.68-1.465 10.609-4.396 5.856-5.859 5.854-15.357-.004-21.213z" />
                <path fill='#777777' d="m355.233 422.702c-2.01-8.037-10.152-12.923-18.191-10.912-8.037 2.01-12.922 10.155-10.912 18.191l17.617 70.437c1.705 6.815 7.819 11.364 14.541 11.364 1.205 0 2.429-.146 3.651-.452 8.037-2.01 12.922-10.155 10.912-18.191z" />
            </g></svg>
    )
}

/**
 * Save Icon
 */
export function SaveIcon(Props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 469.333 469.333" width="16" height="16">
            <g>
                <g>
                    <g>
                        <path d="M466.208,88.458L380.875,3.125c-2-2-4.708-3.125-7.542-3.125H42.667C19.146,0,0,19.135,0,42.667v384
				c0,23.531,19.146,42.667,42.667,42.667h384c23.521,0,42.667-19.135,42.667-42.667V96
				C469.333,93.167,468.208,90.458,466.208,88.458z M106.667,21.333h234.667v128c0,11.76-9.563,21.333-21.333,21.333H128
				c-11.771,0-21.333-9.573-21.333-21.333V21.333z M384,448H85.333V256H384V448z M448,426.667c0,11.76-9.563,21.333-21.333,21.333
				h-21.333V245.333c0-5.896-4.771-10.667-10.667-10.667h-320c-5.896,0-10.667,4.771-10.667,10.667V448H42.667
				c-11.771,0-21.333-9.573-21.333-21.333v-384c0-11.76,9.563-21.333,21.333-21.333h42.667v128C85.333,172.865,104.479,192,128,192
				h192c23.521,0,42.667-19.135,42.667-42.667v-128h6.25L448,100.417V426.667z"/>
                        <path d="M266.667,149.333h42.667c5.896,0,10.667-4.771,10.667-10.667V53.333c0-5.896-4.771-10.667-10.667-10.667h-42.667
				c-5.896,0-10.667,4.771-10.667,10.667v85.333C256,144.562,260.771,149.333,266.667,149.333z M277.333,64h21.333v64h-21.333V64z"
                        />
                    </g>
                </g>
            </g>
        </svg>

    );
}


/**
 * Xml Help Icon
 */
export function XmlHelpIcon(props) {
    return (
        <svg version="1.1" viewBox="0 0 32 32" height='24px' width='20px' font-size='24px' xmlns="http://www.w3.org/2000/svg" >
            <title />
            <desc />
            <defs />
            <g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1">
                <g fill="#777777" id="icon-102-document-file-xml">
                    <path d="M21,13 L21,10 L21,10 L15,3 L4.00276013,3 C2.89666625,3 2,3.89833832 2,5.00732994 L2,27.9926701 C2,29.1012878 2.89092539,30 3.99742191,30 L19.0025781,30 C20.1057238,30 21,29.1017876 21,28.0092049 L21,26 L28.9931517,26 C30.6537881,26 32,24.6577357 32,23.0012144 L32,15.9987856 C32,14.3426021 30.6640085,13 28.9931517,13 L21,13 L21,13 L21,13 Z M20,26 L20,28.0066023 C20,28.5550537 19.5523026,29 19.0000398,29 L3.9999602,29 C3.45470893,29 3,28.5543187 3,28.004543 L3,4.99545703 C3,4.45526288 3.44573523,4 3.9955775,4 L14,4 L14,8.99408095 C14,10.1134452 14.8944962,11 15.9979131,11 L20,11 L20,13 L12.0068483,13 C10.3462119,13 9,14.3422643 9,15.9987856 L9,23.0012144 C9,24.6573979 10.3359915,26 12.0068483,26 L20,26 L20,26 L20,26 Z M15,4.5 L15,8.99121523 C15,9.54835167 15.4506511,10 15.9967388,10 L19.6999512,10 L15,4.5 L15,4.5 Z M11.9945615,14 C10.8929956,14 10,14.9001762 10,15.992017 L10,23.007983 C10,24.1081436 10.9023438,25 11.9945615,25 L29.0054385,25 C30.1070044,25 31,24.0998238 31,23.007983 L31,15.992017 C31,14.8918564 30.0976562,14 29.0054385,14 L11.9945615,14 L11.9945615,14 Z M14,19.5 L12,16 L13,16 L14.5,18.625 L16,16 L17,16 L15,19.5 L17,23 L16,23 L14.5,20.375 L13,23 L12,23 L14,19.5 L14,19.5 Z M20.5,19 L19,16 L18.5,16 L18,16 L18,23 L19,23 L19,18 L20,20 L20.5,20 L21,20 L22,18 L22,23 L23,23 L23,16 L22.5,16 L22,16 L20.5,19 L20.5,19 Z M29,22 L29,23 L24,23 L24,16 L25,16 L25,22 L29,22 L29,22 Z" id="document-file-xml" />
                </g>
            </g>
        </svg>
    )
}

/**
 * Xsd Help Icon
 */
export function XsdHelpIcon(props) {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20px" height="24px" viewBox="0 0 32 32" font-size='24px'>
            <g id="Group_1" data-name="Group 1" transform="translate(-667.86 -370.89)">
                <g id="Page-1">
                    <g id="icon-102-document-file-xml">
                        <path id="document-file-xml" d="M694.86,380.89h-8v-3l-6-7h-11a2,2,0,0,0-2,2v23a1.991,1.991,0,0,0,1.99,2h15.01a1.991,1.991,0,0,0,2-1.99v-2.01h7.99a3,3,0,0,0,3.01-2.99v-7.01A3.009,3.009,0,0,0,694.86,380.89Zm-14-8.5,4.7,5.5h-3.7a1,1,0,0,1-1-1Zm5,23.51a.994.994,0,0,1-1,.99h-15a.99.99,0,0,1-1-1v-23a1,1,0,0,1,1-1h10v5a1.991,1.991,0,0,0,1.99,2h4.01v2h-8a3,3,0,0,0-3,3v7a3,3,0,0,0,3,3.01v-.01h8Zm11-5a2,2,0,0,1-2,1.99H677.85a1.989,1.989,0,0,1-1.99-1.99v-7.01a2,2,0,0,1,1.99-2h17.01a2.019,2.019,0,0,1,2,2Z" fill="#777777" fill-rule="evenodd" />
                        <path id="Path_2" data-name="Path 2" d="M696.86,383.89v7.01a2,2,0,0,1-2,1.99H677.85a1.989,1.989,0,0,1-1.99-1.99v-7.01a2,2,0,0,1,1.99-2h17.01A2.019,2.019,0,0,1,696.86,383.89Z" fill="#fff" fill-rule="evenodd" />
                    </g>
                </g>
                <path id="Path_1" data-name="Path 1" d="M683.165,390.68h-1L680.553,388a2.546,2.546,0,0,1-.161-.347h-.02c-.032.075-.088.191-.166.347l-1.66,2.681H677.54l2.344-3.521-2.158-3.479h1.005l1.431,2.459a4.782,4.782,0,0,1,.251.488h.02a5.529,5.529,0,0,1,.273-.508l1.487-2.439h.942l-2.2,3.47Zm.737-.283v-.967a1.861,1.861,0,0,0,.4.264,3.229,3.229,0,0,0,.488.2,3.827,3.827,0,0,0,.515.125,2.863,2.863,0,0,0,.479.044,1.87,1.87,0,0,0,1.129-.283,1.05,1.05,0,0,0,.249-1.3,1.393,1.393,0,0,0-.349-.385,3.444,3.444,0,0,0-.52-.332c-.2-.106-.415-.217-.647-.334s-.472-.249-.684-.376a2.985,2.985,0,0,1-.549-.421,1.77,1.77,0,0,1-.369-.52,1.791,1.791,0,0,1,.079-1.514,1.81,1.81,0,0,1,.552-.583,2.511,2.511,0,0,1,.779-.342,3.578,3.578,0,0,1,.891-.112,3.414,3.414,0,0,1,1.509.249v.923a2.729,2.729,0,0,0-1.592-.43,2.624,2.624,0,0,0-.537.056,1.543,1.543,0,0,0-.479.183,1.069,1.069,0,0,0-.342.327.876.876,0,0,0-.132.488,1,1,0,0,0,.1.464,1.157,1.157,0,0,0,.3.356,2.981,2.981,0,0,0,.476.313c.188.1.4.212.647.332s.482.251.713.391a3.258,3.258,0,0,1,.591.454,2.016,2.016,0,0,1,.4.552,1.541,1.541,0,0,1,.149.693,1.744,1.744,0,0,1-.2.876,1.661,1.661,0,0,1-.547.583,2.374,2.374,0,0,1-.793.325,4.3,4.3,0,0,1-.947.1,3.859,3.859,0,0,1-.416-.026c-.162-.018-.329-.044-.5-.078a4.054,4.054,0,0,1-.481-.127A1.49,1.49,0,0,1,683.9,390.4Zm5.64.283v-7h1.934q3.7,0,3.7,3.413a3.437,3.437,0,0,1-1.028,2.6,3.807,3.807,0,0,1-2.751.987Zm.82-6.26v5.518h1.045a2.963,2.963,0,0,0,2.144-.737,2.759,2.759,0,0,0,.767-2.09q0-2.689-2.861-2.69Z" fill="#777777" stroke="#777777" stroke-width="0.2" />
            </g>
        </svg>


    )
}

/**
 * report icon
 */

export function DashboardReport(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="40px" height="35px" className={props.className} viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#36aebe" stroke="none">
        <path d="M1405 5102 c-62 -21 -114 -60 -150 -111 -45 -67 -55 -118 -55 -301 l0 -167 -122 -6 c-68 -2 -159 -12 -203 -22 -375 -79 -689 -356 -811 -713 -174 -509 32 -1053 501 -1321 150 -86 363 -141 547 -141 l88 0 2 -1047 3 -1048 21 -46 c28 -60 93 -125 153 -153 l46 -21 1735 0 1735 0 46 21 c60 28 125 93 153 153 l21 46 3 1890 c1 1040 0 1906 -3 1925 -6 30 -81 110 -513 542 -278 279 -521 515 -538 523 -28 13 -191 15 -1321 15 -1195 -1 -1292 -2 -1338 -18z m2515 -647 l0 -465 29 -32 29 -33 471 -3 471 -3 -2 -1829 -3 -1830 -28 -27 -27 -28 -1700 0 -1700 0 -27 28 -28 27 0 1051 0 1051 75 28 c154 56 280 138 403 262 137 137 231 305 285 507 22 85 25 116 25 261 0 145 -3 176 -25 261 -54 202 -148 370 -285 507 -123 124 -249 206 -403 263 l-75 27 0 190 c0 176 1 192 20 212 11 12 29 26 40 31 11 4 568 8 1238 8 l1217 1 0 -465z m530 -10 l325 -325 -328 0 -327 0 0 325 c0 179 1 325 3 325 1 0 148 -146 327 -325z m-3448 -600 l3 -465 28 -27 27 -28 465 -3 465 -3 0 -22 c0 -13 -9 -58 -20 -101 -83 -316 -327 -561 -645 -647 -115 -32 -335 -32 -450 0 -312 85 -562 335 -646 646 -29 105 -32 325 -6 428 69 275 251 497 504 614 78 36 198 72 245 72 l27 1 3 -465z m324 445 c241 -61 467 -246 579 -475 43 -86 85 -222 85 -272 l0 -23 -395 0 -395 0 0 395 0 395 23 0 c13 0 59 -9 103 -20z"/>
        <path d="M2524 4510 c-32 -13 -64 -59 -64 -91 0 -18 11 -41 29 -61 l29 -33 566 -3 565 -2 33 29 c27 24 33 36 33 71 0 35 -6 47 -33 71 l-32 29 -553 -1 c-303 0 -562 -4 -573 -9z"/>
        <path d="M2524 4110 c-32 -13 -64 -59 -64 -91 0 -18 11 -41 29 -61 l29 -33 566 -3 565 -2 33 29 c27 24 33 36 33 71 0 35 -6 47 -33 71 l-32 29 -553 -1 c-303 0 -562 -4 -573 -9z"/>
        <path d="M2464 3510 c-32 -13 -64 -59 -64 -91 0 -40 46 -88 91 -95 31 -5 42 -1 70 24 28 25 34 37 34 72 0 35 -6 47 -33 71 -33 29 -60 35 -98 19z"/>
        <path d="M2864 3510 c-32 -13 -64 -59 -64 -91 0 -18 11 -41 29 -61 l29 -33 796 -3 795 -2 33 29 c27 24 33 36 33 71 0 35 -6 47 -33 71 l-32 29 -783 -1 c-430 0 -792 -4 -803 -9z"/>
        <path d="M2464 3110 c-32 -13 -64 -59 -64 -91 0 -40 46 -88 91 -95 31 -5 42 -1 70 24 28 25 34 37 34 72 0 35 -6 47 -33 71 -33 29 -60 35 -98 19z"/>
        <path d="M2864 3110 c-32 -13 -64 -59 -64 -91 0 -18 11 -41 29 -61 l29 -33 796 -3 795 -2 33 29 c27 24 33 36 33 71 0 35 -6 47 -33 71 l-32 29 -783 -1 c-430 0 -792 -4 -803 -9z"/>
        <path d="M4295 2443 c-169 -61 -248 -244 -176 -408 l20 -45 -343 -369 -344 -368 -73 1 c-41 1 -90 -3 -109 -7 l-35 -8 -152 190 -153 190 16 63 c28 110 2 210 -76 288 -206 206 -558 21 -501 -263 7 -34 13 -67 14 -73 1 -6 -78 -99 -177 -206 l-179 -195 -46 14 c-97 30 -215 -1 -291 -77 -61 -61 -85 -119 -85 -210 0 -91 24 -149 85 -210 206 -206 558 -21 501 263 -7 34 -13 67 -14 73 -1 6 78 99 177 206 l179 195 46 -14 c44 -14 116 -13 181 3 23 5 39 -12 177 -185 l153 -190 -16 -63 c-37 -145 31 -290 165 -351 64 -30 178 -30 242 0 60 27 125 92 152 152 30 65 30 178 0 244 l-22 47 343 368 342 367 85 0 c101 0 155 21 219 85 61 61 85 119 85 210 0 91 -24 149 -85 210 -80 80 -203 109 -305 73z m165 -218 c35 -36 34 -95 -3 -132 -60 -61 -162 -19 -162 66 0 89 101 130 165 66z m-1730 -400 c38 -39 35 -98 -9 -137 -28 -25 -39 -29 -70 -24 -23 3 -46 17 -65 37 -23 26 -27 38 -23 67 14 86 106 117 167 57z m-768 -794 c67 -60 28 -166 -62 -166 -105 0 -132 146 -35 186 37 15 64 9 97 -20z m1468 -6 c58 -59 14 -160 -70 -160 -78 0 -126 90 -80 148 42 53 104 58 150 12z"/>
        <path d="M465 3511 c-35 -15 -57 -49 -62 -94 -8 -71 23 -202 74 -307 39 -81 59 -108 133 -182 72 -72 103 -94 181 -131 97 -47 212 -77 295 -77 39 0 51 5 80 34 46 46 46 86 0 132 -28 28 -41 34 -78 34 -110 0 -252 62 -339 149 -87 87 -149 229 -149 339 0 37 -6 50 -34 78 -34 34 -61 41 -101 25z"/>
        </g>
        </svg>
    );
}




/**
 * Xml Copy Icon
 */
 export function XmlCopyIcon(props) {
    return (
        <svg id="folder_3_" data-name="folder (3)" xmlns="http://www.w3.org/2000/svg" width="15.033" height="11.657" viewBox="0 0 15.033 11.657">
        <path id="Path_119" data-name="Path 119" d="M15.7,161.53a1.257,1.257,0,0,0-1.08-.589H4.653a1.26,1.26,0,0,0-1.145.71L.87,167.18a.949.949,0,0,0,.928.707H12.406a1.2,1.2,0,0,0,1.071-.662l2.279-4.569a1.153,1.153,0,0,0-.053-1.127Zm0,0" transform="translate(-0.845 -156.23)" fill="#999"/>
        <path id="Path_120" data-name="Path 120" d="M2.126,5.07a1.26,1.26,0,0,1,1.145-.71H12.66v-1.2a.916.916,0,0,0-.931-.9H6.255a.029.029,0,0,1-.016,0L5.259.888A.941.941,0,0,0,4.493.5H.931A.916.916,0,0,0,0,1.4v8.13Zm0,0" transform="translate(0 -0.5)" fill="#999"/>
      </svg>
      
    )
}



/**
 * Xml Folder Icon
 */
 export function XmlFolderIcon(props) {
    return (
        <svg id="copy_1_" data-name="copy (1)" xmlns="http://www.w3.org/2000/svg" width="13.531" height="14.762" viewBox="0 0 13.531 14.762">
            <path id="Path_122" data-name="Path 122" d="M5.689,115.587A2.617,2.617,0,0,0,8.3,112.972v-6.3H9.38a1.693,1.693,0,0,1,1.691,1.691v8.3a1.693,1.693,0,0,1-1.691,1.691H1.691A1.693,1.693,0,0,1,0,116.663v-1.076Zm0,0" transform="translate(2.46 -103.593)" fill="#999"/>
            <path id="Path_123" data-name="Path 123" d="M128,1.691A1.691,1.691,0,0,1,129.691,0h6.458a1.691,1.691,0,0,1,1.691,1.691V9.38a1.691,1.691,0,0,1-1.691,1.691h-6.458A1.691,1.691,0,0,1,128,9.38Zm0,0" transform="translate(-128)" fill="#999"/>
        </svg>
      
    )
}


/**
 * Xml Settings Icon
 */
 export function XmlSettingsIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="183.341" height="182.593" viewBox="0 0 183.341 182.593">
        <g id="settings" transform="translate(0 -0.114)">
          <g id="Group_556" data-name="Group 556" transform="translate(0 51.86)">
            <g id="Group_555" data-name="Group 555">
              <path id="Path_129" data-name="Path 129" d="M120.559,86.428a54.878,54.878,0,0,0-.349-13.268l10.048-4.479a66.183,66.183,0,0,0-12.131-27.244l-10.055,4.475a55.059,55.059,0,0,0-9.626-9.132l3.941-10.269a66.481,66.481,0,0,0-27.84-10.7L70.6,26.095a54.878,54.878,0,0,0-13.268.349l-4.452-10a66.43,66.43,0,0,0-27.217,12.2l4.423,9.933A54.966,54.966,0,0,0,20.949,48.2L10.707,44.276a65.3,65.3,0,0,0-6.963,13.3A67.217,67.217,0,0,0,0,72.1l10.269,3.941a54.878,54.878,0,0,0,.349,13.268L.57,93.787A66.132,66.132,0,0,0,12.7,121.031l10.048-4.475a54.86,54.86,0,0,0,9.629,9.135L28.44,135.966a66.481,66.481,0,0,0,27.84,10.7l3.945-10.279a55.045,55.045,0,0,0,13.268-.353l4.452,10a66.424,66.424,0,0,0,27.221-12.2l-4.426-9.933a54.972,54.972,0,0,0,9.138-9.626l10.236,3.928a65.356,65.356,0,0,0,6.963-13.3,66.5,66.5,0,0,0,3.754-14.53Zm-27.3,5.494A29.828,29.828,0,1,1,76.1,53.391,29.829,29.829,0,0,1,93.262,91.922Z" transform="translate(0 -15.816)" fill="#36aebe"/>
            </g>
          </g>
          <g id="Group_558" data-name="Group 558" transform="translate(105.136 0.114)">
            <g id="Group_557" data-name="Group 557">
              <path id="Path_130" data-name="Path 130" d="M102.96,40.586a32.074,32.074,0,0,0-.554-5.83l7.7-4.449A40.423,40.423,0,0,0,99.474,11.849l-7.721,4.459a31.732,31.732,0,0,0-10.094-5.82V1.58a39.412,39.412,0,0,0-21.3,0v8.911a31.83,31.83,0,0,0-10.094,5.82l-7.725-4.459A40.511,40.511,0,0,0,31.9,30.311l7.7,4.449a30.79,30.79,0,0,0,0,11.659l-7.7,4.449A40.494,40.494,0,0,0,42.538,69.326l7.721-4.459a31.767,31.767,0,0,0,10.094,5.82V79.6a39.412,39.412,0,0,0,21.3,0V70.687a31.793,31.793,0,0,0,10.094-5.82l7.721,4.459A40.424,40.424,0,0,0,110.1,50.868l-7.7-4.449A31.847,31.847,0,0,0,102.96,40.586ZM71.011,55.5A14.914,14.914,0,1,1,85.919,40.586,14.916,14.916,0,0,1,71.011,55.5Z" transform="translate(-31.903 -0.114)" fill="#36aebe"/>
            </g>
          </g>
        </g>
      </svg>
      
      
    )
}


/**
 * Xml Warning Icon
 */
 export function XmlWarningIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19.475" height="17.442" viewBox="0 0 19.475 17.442">
        <g id="warning" transform="translate(0 -26.72)">
          <g id="Group_531" data-name="Group 531" transform="translate(0 26.72)">
            <path id="Path_102" data-name="Path 102" d="M19.18,40.9,11.62,27.807a2.174,2.174,0,0,0-3.765,0L.294,40.9a2.174,2.174,0,0,0,1.883,3.261H17.3A2.174,2.174,0,0,0,19.18,40.9ZM9.166,32.552h1.142v5.9H9.166Zm.571,8.564a.761.761,0,1,1,.761-.761A.762.762,0,0,1,9.737,41.117Z" transform="translate(0 -26.72)" fill="#ee3322"/>
          </g>
        </g>
      </svg>
      
      
    )
}


/**
 * Xml ZoomIn Icon
 */
 export function XmlZoomInIcon(props) {
    return (
        <svg id="zoom-in" xmlns="http://www.w3.org/2000/svg" width="12.484" height="12.484" viewBox="0 0 12.484 12.484">
        <path id="Path_128" data-name="Path 128" d="M.805,0h4.43a.805.805,0,0,1,.805.805v4.43a.805.805,0,0,1-.805.805H.805A.805.805,0,0,1,0,5.235V.805A.805.805,0,0,1,.805,0Z" transform="translate(3.222 3.222)" fill="#999"/>
        <path id="Path_124" data-name="Path 124" d="M1.4,4.423a.4.4,0,0,0,.4-.4V2.375L3.333,3.9A.4.4,0,1,0,3.9,3.333L2.375,1.805H4.02A.4.4,0,1,0,4.02,1H1.4a.4.4,0,0,0-.4.4V4.02A.4.4,0,0,0,1.4,4.423Z" transform="translate(-1 -1)" fill="#999"/>
        <path id="Path_125" data-name="Path 125" d="M49.02,1H46.4a.4.4,0,0,0,0,.805h1.645L46.521,3.333a.4.4,0,1,0,.569.569l1.527-1.528V4.02a.4.4,0,0,0,.805,0V1.4A.4.4,0,0,0,49.02,1Z" transform="translate(-36.939 -1)" fill="#999"/>
        <path id="Path_126" data-name="Path 126" d="M4.02,48.618H2.375L3.9,47.09a.4.4,0,1,0-.569-.569L1.805,48.048V46.4A.4.4,0,1,0,1,46.4V49.02a.4.4,0,0,0,.4.4H4.02a.4.4,0,0,0,0-.805Z" transform="translate(-1 -36.939)" fill="#999"/>
        <path id="Path_127" data-name="Path 127" d="M49.02,46a.4.4,0,0,0-.4.4v1.645L47.09,46.521a.4.4,0,1,0-.569.569l1.528,1.527H46.4a.4.4,0,1,0,0,.805H49.02a.4.4,0,0,0,.4-.4V46.4A.4.4,0,0,0,49.02,46Z" transform="translate(-36.939 -36.939)" fill="#999"/>
      </svg>
      
      
    )
}


/**
 * Xml ZoomOut Icon
 */
 export function XmlZoomOutIcon(props) {
    return (
        <svg id="Layer_24" xmlns="http://www.w3.org/2000/svg" width="15.586" height="15.585" viewBox="0 0 15.586 15.585">
        <path id="Path_131" data-name="Path 131" d="M26.022,27.027H23.005A1.006,1.006,0,0,1,22,26.022V23.005A1.006,1.006,0,0,1,23.005,22h3.016a1.006,1.006,0,0,1,1.005,1.005v3.016A1.006,1.006,0,0,1,26.022,27.027Z" transform="translate(-16.721 -16.721)" fill="#999"/>
        <g id="Group_560" data-name="Group 560" transform="translate(10.558 10.558)">
          <path id="Path_132" data-name="Path 132" d="M47.879,47.879a.5.5,0,0,1-.709,0l-3.165-3.165v2.308a.5.5,0,1,1-1.005,0V43.5a.5.5,0,0,1,.5-.5h3.519a.5.5,0,1,1,0,1.005H44.714l3.165,3.165A.5.5,0,0,1,47.879,47.879Z" transform="translate(-43 -43)" fill="#999"/>
        </g>
        <g id="Group_561" data-name="Group 561" transform="translate(0 10.558)">
          <path id="Path_133" data-name="Path 133" d="M1.146,47.879a.5.5,0,0,0,.709,0L5.02,44.714v2.308a.5.5,0,1,0,1.005,0V43.5a.5.5,0,0,0-.5-.5H2a.5.5,0,1,0,0,1.005H4.311L1.146,47.17A.5.5,0,0,0,1.146,47.879Z" transform="translate(-0.998 -43)" fill="#999"/>
        </g>
        <g id="Group_562" data-name="Group 562" transform="translate(10.558)">
          <path id="Path_134" data-name="Path 134" d="M47.879,1.148a.5.5,0,0,0-.709,0L44.006,4.313V2.005a.5.5,0,1,0-1.005,0V5.525a.5.5,0,0,0,.5.5h3.519a.5.5,0,1,0,0-1.005H44.714l3.165-3.165A.5.5,0,0,0,47.879,1.148Z" transform="translate(-43 -1)" fill="#999"/>
        </g>
        <g id="Group_563" data-name="Group 563">
          <path id="Path_135" data-name="Path 135" d="M1.146,1.148a.5.5,0,0,1,.709,0L5.02,4.313V2.005a.5.5,0,1,1,1.005,0V5.525a.5.5,0,0,1-.5.5H2A.5.5,0,1,1,2,5.022H4.311L1.146,1.857A.5.5,0,0,1,1.146,1.148Z" transform="translate(-0.998 -1)" fill="#999"/>
        </g>
      </svg>
      
      
    )
}

export const arrowPopOver = `<svg fill="#36aebe" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" enable-background="new 0 0 120 120" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <polygon points="0.233,106.52 60,3 119.768,106.52 "></polygon> </g></svg>`
/**
 * Xml download Icon in notification
 */

export function DownloadIconInXMLNotification(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16.464" viewBox="0 0 15 16.464">
  <g id="surface1" transform="translate(2 2)">
    <path id="Path_1395" data-name="Path 1395" d="M11.057,7.023V3.953a.355.355,0,0,0-.1-.233L7.517.1A.343.343,0,0,0,7.27,0H1.812A1.818,1.818,0,0,0,0,1.829v10.32a1.807,1.807,0,0,0,1.812,1.816H6.12a4.65,4.65,0,1,0,4.937-6.942ZM7.608,1.187,9.917,3.618h-1.5A.817.817,0,0,1,7.608,2.8Zm-5.8,12.1A1.133,1.133,0,0,1,.676,12.149V1.829A1.142,1.142,0,0,1,1.812.676H6.932V2.8A1.491,1.491,0,0,0,8.419,4.294h1.961V6.935c-.1,0-.183-.013-.27-.013A4.718,4.718,0,0,0,7.026,8.085H2.732a.338.338,0,1,0,0,.676H6.4a5.38,5.38,0,0,0-.6,1.048H2.732a.338.338,0,0,0,0,.676h2.85a4.64,4.64,0,0,0,.2,2.806H1.812Zm8.294,2.262a3.973,3.973,0,1,1,3.973-3.973A3.977,3.977,0,0,1,10.107,15.551Zm0,0" transform="translate(-1.88 -1.88)" fill="#999"/>
    <path id="Path_1395_-_Outline" data-name="Path 1395 - Outline" d="M8.223,14.464a4.733,4.733,0,0,1-2.379-.638,4.817,4.817,0,0,1-1.671-1.62H-.068a1.933,1.933,0,0,1-.755-.151,1.912,1.912,0,0,1-.614-.414,1.917,1.917,0,0,1-.413-.615A1.942,1.942,0,0,1-2,10.269V-.051a1.957,1.957,0,0,1,.151-.757,1.942,1.942,0,0,1,.412-.62,1.918,1.918,0,0,1,.614-.419A1.912,1.912,0,0,1-.068-2H5.39a.462.462,0,0,1,.334.142L9.168,1.76a.476.476,0,0,1,.129.313V5.046A4.724,4.724,0,0,1,10.7,5.62a4.816,4.816,0,0,1,1.205,1.045,4.76,4.76,0,0,1,.8,1.392A4.7,4.7,0,0,1,13,9.694a4.773,4.773,0,0,1-4.776,4.769ZM-.068-1.76A1.7,1.7,0,0,0-1.76-.051v10.32a1.7,1.7,0,0,0,1.692,1.7H4.308l.035.058A4.53,4.53,0,1,0,9.152,5.261l-.1-.02V2.073a.237.237,0,0,0-.066-.152L5.55-1.692a.224.224,0,0,0-.16-.068ZM8.227,13.791A4.094,4.094,0,1,1,12.32,9.7,4.1,4.1,0,0,1,8.227,13.791Zm0-7.946A3.853,3.853,0,1,0,12.08,9.7,3.857,3.857,0,0,0,8.227,5.845ZM4.086,11.532H-.188v-.009a1.244,1.244,0,0,1-.77-.362,1.247,1.247,0,0,1-.268-.4,1.265,1.265,0,0,1-.1-.491V-.051a1.285,1.285,0,0,1,.1-.494,1.267,1.267,0,0,1,.268-.4,1.245,1.245,0,0,1,.4-.274,1.239,1.239,0,0,1,.492-.1H5.172V.923A1.371,1.371,0,0,0,6.54,2.294H8.621V5.179l-.124,0c-.046,0-.088,0-.128-.007s-.094-.006-.139-.006a4.591,4.591,0,0,0-3,1.134l-.034.029H.852a.218.218,0,1,0,0,.436h3.9l-.135.19a5.225,5.225,0,0,0-.582,1.025L4,8.049H.852a.218.218,0,0,0,0,.436h3l-.037.149a4.521,4.521,0,0,0,.2,2.731Zm-4.034-.24H3.729a4.4,4.4,0,0,1-.187-.678,4.845,4.845,0,0,1-.088-.92,4.771,4.771,0,0,1,.1-.969H.852a.458.458,0,0,1,0-.917H3.843A5.266,5.266,0,0,1,4.289,7H.852a.458.458,0,1,1,0-.916H5.1a4.914,4.914,0,0,1,1.407-.84A4.743,4.743,0,0,1,8.23,4.922c.052,0,.1,0,.15.007V2.534H6.54A1.611,1.611,0,0,1,4.932.923V-1.083h-5A1.026,1.026,0,0,0-1.083-.051v10.32A1.007,1.007,0,0,0-.068,11.288h.12ZM8.317,1.858H6.54a.916.916,0,0,1-.362-.074.944.944,0,0,1-.5-.5A.925.925,0,0,1,5.608.923V-.994ZM5.848-.392V.923a.694.694,0,0,0,.691.695H7.758Z" transform="translate(0 0)" fill="#999"/>
    <path id="Path_1396" data-name="Path 1396" d="M75.889,202.36h3.425a.338.338,0,1,0,0-.676H75.889a.338.338,0,1,0,0,.676Zm0,0" transform="translate(-75.037 -197.173)" fill="#999"/>
    <path id="Path_1396_-_Outline" data-name="Path 1396 - Outline" d="M77.434,200.6H74.009a.458.458,0,1,1,0-.917h3.425a.458.458,0,1,1,0,.917Zm-3.425-.676a.218.218,0,1,0,0,.436h3.425a.218.218,0,1,0,0-.436Z" transform="translate(-73.157 -195.293)" fill="#999"/>
    <path id="Path_1397" data-name="Path 1397" d="M249.719,285.744l-1.39,1.5v-3.692a.338.338,0,1,0-.676,0v3.692l-1.4-1.5a.341.341,0,0,0-.48-.017.337.337,0,0,0-.017.477l1.978,2.127a.336.336,0,0,0,.494,0l1.982-2.127a.336.336,0,1,0-.49-.46Zm0,0" transform="translate(-239.761 -276.117)" fill="#999"/>
    <path id="Path_1397_-_Outline" data-name="Path 1397 - Outline" d="M246.1,286.679a.458.458,0,0,1-.334-.146h0l-1.978-2.126a.447.447,0,0,1-.123-.328.462.462,0,0,1,.145-.318.451.451,0,0,1,.312-.124.468.468,0,0,1,.338.145h0l1.192,1.275v-3.388a.458.458,0,1,1,.917,0v3.386l1.182-1.275a.465.465,0,0,1,.335-.145.456.456,0,0,1,.311.123.449.449,0,0,1,.144.318.454.454,0,0,1-.125.329l-1.982,2.127A.458.458,0,0,1,246.1,286.679Zm-.159-.311a.216.216,0,0,0,.319,0l1.982-2.127a.215.215,0,0,0,.059-.157.211.211,0,0,0-.067-.149.217.217,0,0,0-.147-.059.22.22,0,0,0-.16.07l-1.6,1.722v-4a.218.218,0,1,0-.436,0v4l-1.607-1.72a.227.227,0,0,0-.164-.07.212.212,0,0,0-.147.058.223.223,0,0,0-.07.153.209.209,0,0,0,.058.154h0Z" transform="translate(-237.881 -274.237)" fill="#999"/>
  </g>
</svg>
      
      
    )
}

export function DownloadIconInXMLNotificationTablelist(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16.464" viewBox="0 0 15 16.464">
  <g id="surface1" transform="translate(2 2)">
    <path fill='#36aebe' id="Path_1395" data-name="Path 1395" d="M11.057,7.023V3.953a.355.355,0,0,0-.1-.233L7.517.1A.343.343,0,0,0,7.27,0H1.812A1.818,1.818,0,0,0,0,1.829v10.32a1.807,1.807,0,0,0,1.812,1.816H6.12a4.65,4.65,0,1,0,4.937-6.942ZM7.608,1.187,9.917,3.618h-1.5A.817.817,0,0,1,7.608,2.8Zm-5.8,12.1A1.133,1.133,0,0,1,.676,12.149V1.829A1.142,1.142,0,0,1,1.812.676H6.932V2.8A1.491,1.491,0,0,0,8.419,4.294h1.961V6.935c-.1,0-.183-.013-.27-.013A4.718,4.718,0,0,0,7.026,8.085H2.732a.338.338,0,1,0,0,.676H6.4a5.38,5.38,0,0,0-.6,1.048H2.732a.338.338,0,0,0,0,.676h2.85a4.64,4.64,0,0,0,.2,2.806H1.812Zm8.294,2.262a3.973,3.973,0,1,1,3.973-3.973A3.977,3.977,0,0,1,10.107,15.551Zm0,0" transform="translate(-1.88 -1.88)"/>
    <path fill='#36aebe' id="Path_1395_-_Outline" data-name="Path 1395 - Outline" d="M8.223,14.464a4.733,4.733,0,0,1-2.379-.638,4.817,4.817,0,0,1-1.671-1.62H-.068a1.933,1.933,0,0,1-.755-.151,1.912,1.912,0,0,1-.614-.414,1.917,1.917,0,0,1-.413-.615A1.942,1.942,0,0,1-2,10.269V-.051a1.957,1.957,0,0,1,.151-.757,1.942,1.942,0,0,1,.412-.62,1.918,1.918,0,0,1,.614-.419A1.912,1.912,0,0,1-.068-2H5.39a.462.462,0,0,1,.334.142L9.168,1.76a.476.476,0,0,1,.129.313V5.046A4.724,4.724,0,0,1,10.7,5.62a4.816,4.816,0,0,1,1.205,1.045,4.76,4.76,0,0,1,.8,1.392A4.7,4.7,0,0,1,13,9.694a4.773,4.773,0,0,1-4.776,4.769ZM-.068-1.76A1.7,1.7,0,0,0-1.76-.051v10.32a1.7,1.7,0,0,0,1.692,1.7H4.308l.035.058A4.53,4.53,0,1,0,9.152,5.261l-.1-.02V2.073a.237.237,0,0,0-.066-.152L5.55-1.692a.224.224,0,0,0-.16-.068ZM8.227,13.791A4.094,4.094,0,1,1,12.32,9.7,4.1,4.1,0,0,1,8.227,13.791Zm0-7.946A3.853,3.853,0,1,0,12.08,9.7,3.857,3.857,0,0,0,8.227,5.845ZM4.086,11.532H-.188v-.009a1.244,1.244,0,0,1-.77-.362,1.247,1.247,0,0,1-.268-.4,1.265,1.265,0,0,1-.1-.491V-.051a1.285,1.285,0,0,1,.1-.494,1.267,1.267,0,0,1,.268-.4,1.245,1.245,0,0,1,.4-.274,1.239,1.239,0,0,1,.492-.1H5.172V.923A1.371,1.371,0,0,0,6.54,2.294H8.621V5.179l-.124,0c-.046,0-.088,0-.128-.007s-.094-.006-.139-.006a4.591,4.591,0,0,0-3,1.134l-.034.029H.852a.218.218,0,1,0,0,.436h3.9l-.135.19a5.225,5.225,0,0,0-.582,1.025L4,8.049H.852a.218.218,0,0,0,0,.436h3l-.037.149a4.521,4.521,0,0,0,.2,2.731Zm-4.034-.24H3.729a4.4,4.4,0,0,1-.187-.678,4.845,4.845,0,0,1-.088-.92,4.771,4.771,0,0,1,.1-.969H.852a.458.458,0,0,1,0-.917H3.843A5.266,5.266,0,0,1,4.289,7H.852a.458.458,0,1,1,0-.916H5.1a4.914,4.914,0,0,1,1.407-.84A4.743,4.743,0,0,1,8.23,4.922c.052,0,.1,0,.15.007V2.534H6.54A1.611,1.611,0,0,1,4.932.923V-1.083h-5A1.026,1.026,0,0,0-1.083-.051v10.32A1.007,1.007,0,0,0-.068,11.288h.12ZM8.317,1.858H6.54a.916.916,0,0,1-.362-.074.944.944,0,0,1-.5-.5A.925.925,0,0,1,5.608.923V-.994ZM5.848-.392V.923a.694.694,0,0,0,.691.695H7.758Z" transform="translate(0 0)" />
    <path fill='#36aebe' id="Path_1396" data-name="Path 1396" d="M75.889,202.36h3.425a.338.338,0,1,0,0-.676H75.889a.338.338,0,1,0,0,.676Zm0,0" transform="translate(-75.037 -197.173)" />
    <path fill='#36aebe' id="Path_1396_-_Outline" data-name="Path 1396 - Outline" d="M77.434,200.6H74.009a.458.458,0,1,1,0-.917h3.425a.458.458,0,1,1,0,.917Zm-3.425-.676a.218.218,0,1,0,0,.436h3.425a.218.218,0,1,0,0-.436Z" transform="translate(-73.157 -195.293)"/>
    <path fill='#36aebe' id="Path_1397" data-name="Path 1397" d="M249.719,285.744l-1.39,1.5v-3.692a.338.338,0,1,0-.676,0v3.692l-1.4-1.5a.341.341,0,0,0-.48-.017.337.337,0,0,0-.017.477l1.978,2.127a.336.336,0,0,0,.494,0l1.982-2.127a.336.336,0,1,0-.49-.46Zm0,0" transform="translate(-239.761 -276.117)"/>
    <path fill='#36aebe' id="Path_1397_-_Outline" data-name="Path 1397 - Outline" d="M246.1,286.679a.458.458,0,0,1-.334-.146h0l-1.978-2.126a.447.447,0,0,1-.123-.328.462.462,0,0,1,.145-.318.451.451,0,0,1,.312-.124.468.468,0,0,1,.338.145h0l1.192,1.275v-3.388a.458.458,0,1,1,.917,0v3.386l1.182-1.275a.465.465,0,0,1,.335-.145.456.456,0,0,1,.311.123.449.449,0,0,1,.144.318.454.454,0,0,1-.125.329l-1.982,2.127A.458.458,0,0,1,246.1,286.679Zm-.159-.311a.216.216,0,0,0,.319,0l1.982-2.127a.215.215,0,0,0,.059-.157.211.211,0,0,0-.067-.149.217.217,0,0,0-.147-.059.22.22,0,0,0-.16.07l-1.6,1.722v-4a.218.218,0,1,0-.436,0v4l-1.607-1.72a.227.227,0,0,0-.164-.07.212.212,0,0,0-.147.058.223.223,0,0,0-.07.153.209.209,0,0,0,.058.154h0Z" transform="translate(-237.881 -274.237)"/>
  </g>
</svg>
      
      
    )
}


export const exclamation = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20">
        <path id="Path_1401" data-name="Path 1401" d="M11,15h2v2H11Zm0-8h2v6H11Zm.99-5A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" transform="translate(-2 -2)" fill="#e6492d"/>
      </svg>`;
 export const questionMark = `<svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path opacity="0.5" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="#bab8b8"/>
 <path d="M12 7.75C11.3787 7.75 10.875 8.25368 10.875 8.875C10.875 9.28921 10.5392 9.625 10.125 9.625C9.71079 9.625 9.375 9.28921 9.375 8.875C9.375 7.42525 10.5503 6.25 12 6.25C13.4497 6.25 14.625 7.42525 14.625 8.875C14.625 9.58584 14.3415 10.232 13.883 10.704C13.7907 10.7989 13.7027 10.8869 13.6187 10.9708C13.4029 11.1864 13.2138 11.3753 13.0479 11.5885C12.8289 11.8699 12.75 12.0768 12.75 12.25V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V12.25C11.25 11.5948 11.555 11.0644 11.8642 10.6672C12.0929 10.3733 12.3804 10.0863 12.6138 9.85346C12.6842 9.78321 12.7496 9.71789 12.807 9.65877C13.0046 9.45543 13.125 9.18004 13.125 8.875C13.125 8.25368 12.6213 7.75 12 7.75Z" fill="#757373"/>
 <path d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z" fill="#757373"/>
 </svg>`

 export const downArrow = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
 <g id="Group_118" data-name="Group 118" transform="translate(0 0.243)">
   <rect id="Rectangle_39" data-name="Rectangle 39" width="30" height="30" rx="15" transform="translate(0 -0.243)" fill="#d6d6d6"/>
   <g id="expand_more_black_24dp_5_" data-name="expand_more_black_24dp (5)" transform="translate(5 5)">
     <path id="Path_1395" data-name="Path 1395" d="M0,0H20V20H0Z" fill="none"/>
     <path id="Path_1396" data-name="Path 1396" d="M8.825,0,5,3.817,1.175,0,0,1.175l5,5,5-5Z" transform="translate(5 7.158)" fill="#777"/>
   </g>
 </g>
</svg>`

export const upArrow = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
<g id="Group_118" data-name="Group 118" transform="translate(30.289 30) rotate(180)">
  <rect id="Rectangle_39" data-name="Rectangle 39" width="30" height="30" rx="15" transform="translate(0.289)" fill="#36aebe"/>
  <g id="expand_more_black_24dp_5_" data-name="expand_more_black_24dp (5)" transform="translate(5 5)">
    <path id="Path_1395" data-name="Path 1395" d="M0,0H20V20H0Z" fill="none"/>
    <path id="Path_1396" data-name="Path 1396" d="M8.825,0,5,3.817,1.175,0,0,1.175l5,5,5-5Z" transform="translate(5 7.158)" fill="#f7f5f5"/>
  </g>
</g>
</svg>`

export const errorSnackbar = `<svg xmlns="http://www.w3.org/2000/svg" width="27.77" height="15.684" viewBox="0 0 27.77 24.684">
        <g id="warning" transform="translate(-19.072 -22.708)">
          <path id="Path_5" data-name="Path 5" d="M30.906,23.892,19.39,43.838a2.369,2.369,0,0,0,2.052,3.554H44.473a2.369,2.369,0,0,0,2.052-3.554L35.009,23.892a2.369,2.369,0,0,0-4.1,0Z" transform="translate(0)" fill="#E6492D"/>
          <g id="Group_14" data-name="Group 14" transform="translate(31.468 30.044)">
            <rect id="Rectangle_12" data-name="Rectangle 12" width="2.979" height="9.079" rx="1.489" transform="translate(0)" fill="#FFF"/>
            <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.489" cy="1.489" r="1.489" transform="translate(0 11.177)" fill="#FFF"/>
          </g>
        </g>
      </svg>`

export const downArrowSnackbar = `<svg enable-background="new 0 0 26 26" height="10px" id="Layer_1" version="1.1" viewBox="0 0 26 26" width="26px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><polygon fill="#FFF" points="0.046,2.582 2.13,0.498 12.967,11.334 23.803,0.498 25.887,2.582 12.967,15.502  "/><polygon fill="#FFF" points="0.046,13.582 2.13,11.498 12.967,22.334 23.803,11.498 25.887,13.582 12.967,26.502  "/></g></svg>`
export  const upArrowSnackbar = `<svg enable-background="new 0 0 26 26" height="10px" id="Layer_1" version="1.1" viewBox="0 0 26 26" width="26px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><polygon fill="grey" points="0.046,24.418 2.13,26.502 12.967,15.666 23.803,26.502 25.887,24.418 12.967,11.498  "/><polygon fill="grey" points="0.046,13.418 2.13,15.502 12.967,4.666 23.803,15.502 25.887,13.418 12.967,0.498  "/></g></svg>`
