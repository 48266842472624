//import helper
import { authHeader,globalVariable,handleResponse } from "../helpers";
import momentTZ from 'moment-timezone';

const timeZone = momentTZ.tz.guess(true)

export const complianceService = {
    getAll,
    updateData,
    getFields,
    getFieldData,
    updateActiveStatus
}

/**
 *Get data for compliance list
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sField
 * @param {*} sValue
 * @returns
 */
function getAll(skip, limit, orderBy, order, sField, sValue){
    
    let data = {
        fields : sField,
        values : sValue,
    };

    const requestOptions = {
        method : 'POST',
        headers: authHeader(),
        body : JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/compliance/search?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}&timeZone=${timeZone}`, requestOptions).then(handleResponse);
}

/**
 *Update compliance data
 *
 * @param {*} formData - update data
 * @returns
 */
function updateData(formData) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };
    return fetch(`${globalVariable.apiURL}/compliance/settings`, requestOptions).then(handleResponse);
}


/**
 *Update compliance data active status
 *
 * @param {*} formData - update data
 * @returns
 */
 function updateActiveStatus(formData) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };
    return fetch(`${globalVariable.apiURL}/compliance/active-status`, requestOptions).then(handleResponse);
}

/**
 *Get Default Fields for compliance
 *
 * @returns
 */
function getFields() {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() }
    };

    return fetch(`${globalVariable.apiURL}/compliance/get-fields`, requestOptions).then(handleResponse);
}

/**
 *Get Field data for compliance
 *
 * @returns
 */
function getFieldData(){
    const requestOptions = {
        method : 'POST',
        headers: authHeader(),
    };

    return fetch(`${globalVariable.apiURL}/compliance/get-data`, requestOptions).then(handleResponse);
}
