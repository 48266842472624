//import services
import { complianceService } from '../services/compliance';

//import constants
import { complianceConstants } from '../constants/compliance';

//import actions
import { alertActions, validationActions } from './';

export const complianceActions = {
    getAll,
    updateData,
    getFields,
    getFieldData,
    updateActiveStatus
}

/**
 *Get data for compliance list
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sField
 * @param {*} sValue
 * @returns
 */
function getAll(skip, limit, orderBy, order, sField, sValue) {
    return dispatch => {
        dispatch(request());
        complianceService.getAll(skip, limit, orderBy, order, sField, sValue)
            .then(
                compliance => dispatch(success(compliance)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: complianceConstants.GETALL_REQUEST } }
    function success(compliance) { return { type: complianceConstants.GETALL_SUCCESS, compliance } }
    function failure(error) { return { type: complianceConstants.GETALL_FAILURE, error } }
}

/**
 *Update compliance data
 *
 * @param {*} formData - update data
 * @returns
 */
function updateData(formData) {
    return dispatch => {
        dispatch(request());
        complianceService.updateData(formData)
            .then(
                compliance => {
                    dispatch(success(compliance));
                    dispatch(alertActions.success("Updated Successfully"));

                    // update all sites validation
                    dispatch(validationActions.updateValidationReports());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: complianceConstants.UPDATEDATA_REQUEST } }
    function success(compliance) { return { type: complianceConstants.UPDATEDATA_SUCCESS, compliance } }
    function failure(error) { return { type: complianceConstants.UPDATEDATA_FAILURE, error } }
}

/**
 *Update compliance data
 *
 * @param {*} formData - update data
 * @returns
 */
 function updateActiveStatus(formData) {
    return dispatch => {
        dispatch(request());
        complianceService.updateActiveStatus(formData)
            .then(
                compliance => {
                    dispatch(success(compliance));
                    dispatch(alertActions.success("Updated Successfully"));

                    // update all sites validation
                    dispatch(validationActions.updateValidationReports());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: complianceConstants.UPDATEDATA_REQUEST } }
    function success(compliance) { return { type: complianceConstants.UPDATEDATA_SUCCESS, compliance } }
    function failure(error) { return { type: complianceConstants.UPDATEDATA_FAILURE, error } }
}

/**
 *Get Default Fields for compliance
 *
 * @returns
 */
function getFields() {
    return dispatch => {
        dispatch(request());

        complianceService.getFields()
            .then(
                compliance => {
                    dispatch(success(compliance.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: complianceConstants.GETFIELD_REQUEST } }
    function success(compliance) { return { type: complianceConstants.GETFIELD_SUCCESS, compliance } }
    function failure(error) { return { type: complianceConstants.GETFIELD_FAILURE, error } }
}

/**
 *Get Field data for compliance
 *
 * @returns
 */
function getFieldData() {
    return dispatch => {
        dispatch(request());
        complianceService.getFieldData()
            .then(
                compliance => dispatch(success(compliance)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: complianceConstants.GETFIELDDATA_REQUEST } }
    function success(compliance) { return { type: complianceConstants.GETFIELDDATA_SUCCESS, compliance } }
    function failure(error) { return { type: complianceConstants.GETFIELDDATA_FAILURE, error } }
}