import { uploadConstants } from '../constants';
import { history } from '../helpers';
import { Table4Service, UAService, uploadService } from '../services';
import { alertActions, validationActions } from './';
import { occupantsActions } from './occupants';
import { saveAs } from 'file-saver';


export const uploadActions = {
  bulkImport,
  importIncomeRentLmt,
  table1Import,
  triggerUpdate,
  importStatus,
  reportActivityImport,
  downloadErrorLogs,
  downloadUpdateTemplate,
  bulkImportSubmit
};

/**
 * Bulk import
 * 
 * @param {*} uploadData 
 * @param {*} action 
 */
function bulkImport(uploadData, action) {
  return dispatch => {
    dispatch(request());
    uploadService.funderBulkUpload(uploadData, action)
      .then(
        res => {
          let message = action === 'new' ? 'Inserted successfully' : 'Updated successfully';
          dispatch(success(res));
          dispatch(alertActions.success(message));
          history.push("/configurations/upload/3");
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message.toString()));
          history.push("/configurations/upload/2");
        }
      );
  };

  function request() { return { type: uploadConstants.BULK_IMPORT_REQUEST } }
  function success(data) { return { type: uploadConstants.BULK_IMPORT_SUCCESS, data } }
  function failure(error) { return { type: uploadConstants.BULK_IMPORT_FAILURE, error } }
}

/**
 * Bulk import
 * 
 * @param {*} uploadData 
 * @param {*} action 
 */
function bulkImportSubmit(uploadData, action) {
  return dispatch => {
    dispatch(request());
    uploadService.funderBulkUploadSubmit(uploadData, action)
      .then(
        res => {
          let message = action === 'new' ? 'Inserted successfully' : 'Updated successfully';
          // dispatch(success(res.data));
          dispatch(success(res));
          dispatch(alertActions.success(message));
          history.push("/configurations/upload/4");
          if (res.data && res.data.unitIds && res.data.unitIds.length > 0) {
            dispatch(occupantsActions.updateIncomeRent(res.data));
            UAService.updateUABulkInTable1(res.data).then(res=>{},error => {})
          }
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message.toString()));
          // history.push("/configurations/upload/2");
        }
      );
  };

  function request() { return { type: uploadConstants.BULK_IMPORT_REQUEST } }
  function success(data) { return { type: uploadConstants.BULK_IMPORT_SUCCESS, data } }
  function failure(error) { return { type: uploadConstants.BULK_IMPORT_FAILURE, error } }
}

/**
 * Download explore reports
 * 
 * @param {*} reportName - default name
 */
function downloadErrorLogs(reportName) {
  // reportName='TSET_Error log_26-09-2023.xlsx';
  console.log(reportName)
  return dispatch => {
      dispatch(request());
      dispatch(alertActions.success('Please wait... Download is Inprogress'));
      setTimeout(() => {
          dispatch(alertActions.clear());
      }, 3000)

      uploadService.funderErrorLogDownload(reportName)
          .then(res => res.blob())
          .then(
              blob => {
                  reportName = (/.xlsx/g).test(reportName) ? reportName.replace('.xlsx', '') : reportName;
                  saveAs(blob, `${reportName}.xlsx`);
                  dispatch(success());
              },
              error => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error(error.toString()));
              }
          );
  };
  function request() { return { type: uploadConstants.ERROR_LOG_REQUEST } }
  function success(data) { return { type: uploadConstants.ERROR_LOG_SUCCESS, data } }
  function failure(error) { return { type: uploadConstants.ERROR_LOG_FAILURE, error } }
}

/**
 * Download update template
 * 
 * @param {*} payLoad - default name
 */
function downloadUpdateTemplate(payLoad,reportName) {
  console.log(reportName)
  return dispatch => {
      dispatch(request());
      dispatch(alertActions.success('Please wait... Download is Inprogress'));
      setTimeout(() => {
          dispatch(alertActions.clear());
      }, 3000)
      uploadService.updateTemplateDownload(payLoad)
          .then(res => res.blob())
          .then(
              blob => {
                  reportName = (/.xlsx/g).test(reportName) ? reportName.replace('.xlsx', '') : reportName;
                  saveAs(blob, `${reportName}.xlsx`);
                  dispatch(success());
              },
              error => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error(error.toString()));
              }
          );
  };
  function request() { return { type: uploadConstants.UPDATE_TEMPLATE_REQUEST } }
  function success(data) { return { type: uploadConstants.UPDATE_TEMPLATE_SUCCESS, data } }
  function failure(error) { return { type: uploadConstants.UPDATE_TEMPLATE_FAILURE, error } }
}

/**
 * A method to import table 1 data
 *
 * @param {*} formData
 * @returns
 */
function table1Import(formData) {
  return dispatch => {
    dispatch(request());
    uploadService.table1Import(formData)
      .then(
        res => {
          dispatch(success({
            data: res.data,
            data1: res.data1,
            validationError: res.validationError,
            updateError: res.updateError,
            dummyResponse: res.dummyResponse,
            fileName : res.newfileName,
            errors: res.errors
          }));
          //res.details && dispatch(validationActions.updateValidations(res.details.project_id, res.details.site_id, 0, 0));
          // dispatch(alertActions.success("Table 1 Imported Successfully"));
          res.details != undefined && Table4Service.getConvertedTable4Data(res.details.report_yearid, 'projects')
        },
        error => {
          dispatch(failure(error.data));
          dispatch(alertActions.error(error.message.toString()));
        }
      );
  };
  function request() { return { type: uploadConstants.TABLE1_IMPORT_REQUEST } }
  function success(data) { return { type: uploadConstants.TABLE1_IMPORT_SUCCESS, data } }
  function failure(error) { return { type: uploadConstants.TABLE1_IMPORT_FAILURE, error } }
}

/**
 * import Income and Rent limit
 * 
 * @param {*} generalData 
 * @param {*} uploadData 
 */
function importIncomeRentLmt(generalData, uploadData) {
  return dispatch => {
    dispatch(request());
    uploadService.importIncRentLmt(generalData, uploadData)
      .then(
        res => {
          dispatch(success(res.data));
          dispatch(alertActions.success(res.message.toString()));
        },
        error => {
          dispatch(failure(error.data));
          dispatch(alertActions.error(error.message.toString()));
        }
      );
  }

  function request() { return { type: uploadConstants.IMPORT_INCOMERENTLMT_REQUEST } }
  function success(data) { return { type: uploadConstants.IMPORT_INCOMERENTLMT_SUCCESS, data } }
  function failure(error) { return { type: uploadConstants.IMPORT_INCOMERENTLMT_FAILURE, error } }
}

/**
 * Import status 
 */
function importStatus() {
  return dispatch => {
    dispatch(request());
    uploadService.importStatus()
      .then(
        res => {
          dispatch(success(res.data));
        },
        error => {
          dispatch(failure(error.data))
        }
      );
  }

  function request() { return { type: uploadConstants.IMPORT_INCOMERENTLMT_STATUS_REQUEST } }
  function success(data) { return { type: uploadConstants.IMPORT_INCOMERENTLMT_STATUS_SUCCESS, data } }
  function failure(error) { return { type: uploadConstants.IMPORT_INCOMERENTLMT_STATUS_FAILURE, error } }
}

/**
 * Trigger Update
 */
function triggerUpdate() {
  return dispatch => {
    dispatch(request());
    uploadService.triggerUpdate()
      .then(
        res => {
          dispatch(success(res.data));
          dispatch(alertActions.success(res.message.toString()));
        },
        error => {
          dispatch(failure(error.data))
        }
      );
  }

  function request() { return { type: uploadConstants.ACTION_IMPORT_INCOMERENTLMT_REQUEST } }
  function success(data) { return { type: uploadConstants.ACTION_IMPORT_INCOMERENTLMT_SUCCESS, data } }
  function failure(error) { return { type: uploadConstants.ACTION_IMPORT_INCOMERENTLMT_FAILURE, error } }
}

/**
 * A method to import report activity
 *
 * @param {*} formData
 * @returns
 */
 function reportActivityImport(formData) {
  return dispatch => {
    dispatch(request());
    uploadService.reportActivityImport(formData)
      .then(
        res => {
          dispatch(success({
            data: res.data,
            data1: res.data1,
            validationError: res.validationError,
            updateError: res.updateError,
            dummyResponse: res.dummyResponse,
            errors: res.errors
          }));
        },
        error => {
          dispatch(failure(error.data));
          dispatch(alertActions.error(error.message.toString()));
        }
      );
  };

  function request() { return { type: uploadConstants.REPORT_ACTIVITY_IMPORT_REQUEST } }
  function success(data) { return { type: uploadConstants.REPORT_ACTIVITY_IMPORT_SUCCESS, data } }
  function failure(error) { return { type: uploadConstants.REPORT_ACTIVITY_IMPORT_FAILURE, error } }
}
