import { authHeader, globalVariable, handleResponse } from "../helpers";

export const mergeService = {
    getProjectsByName,
    getSitesByProjectId,
    getBuildings,
    getUnits,
    projectMerge,
    siteMerge,
    buildingMerge,
    unitMerge,
    getOrganizationName,
    organizationMerge,
}
/**
 * get list of projects
 * @param  text 
 */
function getProjectsByName(text) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() }
    };

    return fetch(`${globalVariable.apiURL}/merge/projectName`, requestOptions).then(handleResponse);
}

/**
 * get list of organizations 
 */
function getOrganizationName(){
    const requestOptions = {
        method:'GET',
        headers:{...authHeader()}
    };

    return fetch(`${globalVariable.apiURL}/merge/organizationName`, requestOptions).then(handleResponse);
}

/**
 *get list of sites based on project id
 *
 * @param {*} name - project name
 * @param {*} id - project_id
 * @returns
 */
function getSitesByProjectId(name, id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${globalVariable.apiURL}/merge/sitelist/${id}`, requestOptions).then(handleResponse);
}

/**
 *get list of buildings based on site id
 *
 * @param {*} name - site name
 * @param {*} id - site_id
 * @returns
 */
function getBuildings(name, id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${globalVariable.apiURL}/merge/buildinglist/${id}`, requestOptions).then(handleResponse);
}

/**
 *get list of units based on building id
 *
 * @param {*} name - building name
 * @param {*} id - building_id
 * @returns
 */
function getUnits(name, id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${globalVariable.apiURL}/merge/unitlist/${id}`, requestOptions).then(handleResponse);
}

/**
 * A method call API to merge the project details
 *
 * @param {*} formData contains the project details data to merge
 * @returns
 */
function projectMerge(formdata) {

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formdata)
    };

    return fetch(`${globalVariable.apiURL}/merge/projects`, requestOptions).then(handleResponse);
}

/**
 * A method call API to merge the organization details
 *
 * @param {*} formData contains the organization details data to merge
 * @returns
 */
 function organizationMerge(formdata) {

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formdata)
    };

    return fetch(`${globalVariable.apiURL}/merge/organizations`, requestOptions).then(handleResponse);
}

/**
 * A method call API to merge the site details
 *
 * @param {*} formdata contains the site details data to merge
 * @returns
 */
function siteMerge(formdata) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formdata)
    };

    return fetch(`${globalVariable.apiURL}/merge/sites`, requestOptions).then(handleResponse);
}

/**
 * A method call API to merge the building details
 *
 * @param {*} formdata contains the building details data to merge
 * @returns
 */
function buildingMerge(formdata) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formdata)
    };

    return fetch(`${globalVariable.apiURL}/merge/buildings`, requestOptions).then(handleResponse);
}


/**
 * A method call API to merge the unit details
 *
 * @param {*} formdata contains the unit details data to merge
 * @returns
 */
function unitMerge(formdata) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formdata)
    };

    return fetch(`${globalVariable.apiURL}/merge/units`, requestOptions).then(handleResponse);
}
