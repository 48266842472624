import { mergeConstants } from '../constants';

const initialState = {
    isLoading: false,
    organizations:[],
    projects: [],
    data: [],
    projectsTab: {
        fromProject: {
            project: {}
        },
        toProject: {
            project: {}
        },
    },
    sitesTab: {
        fromSites: {
            sites: []
        },
        toSites: {
            sites: []
        },
    },
    buildingsTab: {
        fromBuildings: {
            buildings: []
        },
        toBuildings: {
            buildings: []
        },
    },
    unitsTab: {
        fromUnits: {
            units: []
        },
        toUnits: {
            units: []
        },
    },

}

export function merge(state = initialState, action) {
    switch (action.type) {
        case mergeConstants.GET_ALL_PROJECTS_NAME_REQUEST:
        case mergeConstants.GET_ALL_ORGANIZATION_NAME_REQUEST:
        //case mergeConstants.MERGE_ORGANIZATION_NOTIFICATION_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case mergeConstants.GET_ALL_PROJECTS_NAME_SUCCESS:
            return {
                ...state,
                projects: action.suggestions,
                isLoading: false,
            }
        case mergeConstants.GET_ALL_PROJECTS_NAME_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
        case mergeConstants.GET_ALL_ORGANIZATION_NAME_SUCCESS:
            return {
                ...state,
                organizations: action.orglist,
                isLoading: false,
            }
        case mergeConstants.GET_ALL_ORGANIZATION_NAME_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
        case mergeConstants.GET_SITE_NAME_REQUEST:
            return {
                ...state,
                sitesTab: {
                    ...state.sitesTab,
                    [action.name]: {
                        sites: []
                    }
                },
                isLoading: true,
            };

        case mergeConstants.GET_SITE_NAME_SUCCESS:
            return {
                ...state,
                sitesTab: {
                    ...state.sitesTab,
                    [action.name]: {
                        sites: action.sites,
                    },
                },
                isLoading: true,
            };

        case mergeConstants.GET_SITE_NAME_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        case mergeConstants.GET_BUILDING_NAME_REQUEST:
            return {
                ...state,
                buildingsTab: {
                    ...state.buildingsTab,
                    [action.name]: {
                        buildings: []
                    }
                },
                isLoading: true,
            };

        case mergeConstants.GET_BUILDING_NAME_SUCCESS:
            return {
                ...state,
                buildingsTab: {
                    ...state.buildingsTab,
                    [action.name]: {
                        buildings: action.buildings,
                    },
                },
                isLoading: true,
            };

        case mergeConstants.GET_BUILDING_NAME_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        case mergeConstants.GET_UNIT_NAME_REQUEST:
            return {
                ...state,
                unitsTab: {
                    ...state.unitsTab,
                    [action.name]: {
                        units: []
                    }
                },
                isLoading: true,
            };

        case mergeConstants.GET_UNIT_NAME_SUCCESS:
            return {
                ...state,
                unitsTab: {
                    ...state.unitsTab,
                    [action.name]: {
                        units: action.units,
                    },
                },
                isLoading: true,
            };

        case mergeConstants.GET_UNIT_NAME_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        case mergeConstants.CLEAR_MERGE_SELECTION_DATA:
            return {
                ...state,
                [action.tabName]: {
                    ...initialState[action.tabName]
                }
            }
        // case mergeConstants.MERGE_ORGANIZATION_NOTIFICATION_SUCCESS:
        //     return {
        //         ...state,
        //         isLoading: false,
        //     }
        default: {
            return state;
        }
    }
}

