import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import _ from 'underscore';

// const fs = require('fs');
//import fs from 'fs';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Redux Actions
import { Table1LogActions } from '../../actions/Table1Log';
import { userActions } from '../../actions/user';
import { confActions } from '../../actions/configuration';
import { searchActions } from '../../actions/search';
import { helpActions } from '../../actions';
// Import Actions
import { uploadActions } from '../../actions/uploads';

// Helpers
import { getUserOrgId,xmlFormatter, getUserRole, globalVariable, history,authHeader,formatDate, formatDateWithPrefixZero, filterSearchFields, unixTimeToHourMinute ,handleResponse } from '../../helpers';

// Import Components
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import TableComponent from '../../components/Table/TableComponent';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import HelpFloater from "../../components/HelpFloater/HelpFloater";


/**
 * ProjectsList Container
 * @class ProjectsList
 * @extends {Component}
 */
class Table1Log extends Component {
    /**
     * Cinstructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            fieldFilter: false,
            reset: false,
            anchorEl: null,
            deleteProj: false,
            deleteItemId: null,
            openHelp: false,
            sFields:[],
            sValues:[]
        }

        this.userOrgId = getUserOrgId();
        this.userRole = getUserRole();
    }

    /**
     * Component Will Mount Event
     */
    UNSAFE_componentWillMount() {
        const { xmlimportlogs, userId,searchData:{sFields,sValues} } = this.props;
        const { page } = xmlimportlogs;
        this.props.getHelp();
        if (page.skip) {
            this.props.getTable1logList(page.skip, page.limit, page.orderBy, page.order, sFields, sValues, false, userId);
        } else {
         //   this.props.page === 'projects' ?
                this.props.getTable1logList(1, globalVariable.tableRowSize, 'filename', 'asc', '', '', false, userId) 
               // this.props.getTable1logList(1, globalVariable.subPageTabtableRowSize, 'name', 'asc', '', '', false, userId)
        }
        // setTimeout(() => {
        //     this.props.getFieldFilter('xmlimportlogs');
        // }, 100);
    }

    /**
     * Component Did Update
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }
    /**
     * Component Will Unmount
     * 
     * [1]  -   clear search of assoc tab
     */
    componentWillUnmount() {
        // [1]
        if (this.props.userId) {
            this.props.searchActions({ sFields: [], sValues: [] });
        }
    }
    /**
     * Handle Table Pagination On Change Event
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const { xmlimportlogs, userId, searchData: { sFields, sValues } } = this.props;
        const { page } = xmlimportlogs;
        this.props.getTable1logList(skip, limit, page.orderBy, page.order, sFields, sValues, false, userId);
    };
    /**
     * Handle Table Sort Event
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { xmlimportlogs, userId, searchData: { sFields, sValues } } = this.props;
        const { page } = xmlimportlogs;
        this.props.getTable1logList(page.skip, page.limit, orderBy, order, sFields, sValues, false, userId);
    };
    /**
     * Handle Table Search Input Box Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
       
        const { xmlimportlogs, userId, searchData } = this.props;
        const { page } = xmlimportlogs;
        let { sFields, sValues } = searchData;
        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        // this.setState({sFields,sValues});
        this.props.searchActions({ sFields, sValues });
        this.props.getTable1logList(1, globalVariable.tableRowSize, page.orderBy, page.order, sFields, sValues, false, userId);
    };
    /**
     * Handle Table Navigation Event
     * @param {*} id 
     * @param {*} field 
     * @param {*} item 
     */
    handleNavigateAction = (id, field, item) => {
        // const { match } = this.props;
        const { owners } = item;
        switch (field) {
            case "name":
                history.push({
                    pathname: `/table1-import-log/${id}`,
                    state: {
                        page: 'projectEdit',
                        pageData: {
                            project_id: id
                        }
                    }
                });
                break;
            default: break;
        }
    }
    /**
     * Handle Add Action
     */

    handleAddActionClick = () => {
         history.push({
            pathname: `/table1-import`,
            state: {
                page: 'table1Import',
                pageData: {}
            }
        });
    }
    /**
     * Handle Clear Fileter Action
     */
    handleClearFilterActionClick = () => {
        const { xmlimportlogs, userId,searchData } = this.props;
        const { page } = xmlimportlogs;
        this.props.searchActions({ sFields: [], sValues: [] });
        this.props.getTable1logList(page.skip, page.limit, page.orderBy, page.order, [], [], false, userId);
    };

    /**
     * Handle Field Filter Action Click
     * 
     * [1]  -   update visible columns
     */
    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // [1]
        const { xmlimportlogs, userId } = this.props;
        const { page } = xmlimportlogs;
        this.props.changeFieldFilter(xmlimportlogs, page.fieldFilters.visibleColumns);
    };
    /**
     * Handle Field Filter Close Action Click
     * 
     * [1]  -   reset to current table column values if user not save the changes
     * [2]  -   close the popup
     */
    handleFieldFilterOnClose = () => {
        // [1]
        const { xmlimportlogs, userId } = this.props;
        const { page } = xmlimportlogs;
        this.props.changeFieldFilter(xmlimportlogs, page.fieldFilters.visibleColumns);
        // [2]
        this.setState({ fieldFilter: false });
    }
    /**
     * A method to update visible columns
     * @param {*} key 
     * @param {*} value 
     */
    handleFieldFilterOnChange = (key, value) => {
        const { userId,xmlimportlogs } = this.props;
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter(xmlimportlogs, updatedColumns);
        } else {
            this.props.changeFieldFilter(xmlimportlogs, [...this.props.visibleColumns, key]);
        }
    }
    /**
     * Handle Fieldfilter om Save
     * 
     * [2]  -   on api call back close the popup and call the table view api again
     */
    handleFieldFilterOnSave = () => {
        const { userId,xmlimportlogs } = this.props;
        this.props.updateFieldFilter(xmlimportlogs, this.props.visibleColumns, () => {
            // [1]
            this.setState({ fieldFilter: false });
            const { xmlimportlogs, userId, searchData: { sFields, sValues } } = this.props;
            const { page } = xmlimportlogs;
            this.props.getTable1logList(page.skip, page.limit, page.orderBy, page.order, sFields, sValues, false, userId);
        });
    }
    /**
     *  Handle Table Delete Action
     * @param {*} id 
     * 
     * [1]  -   show pop up
     * [2]  -   for projects with zero sites error msg
     */
     handleTableDeleteAction = (id) => {
      
        // [1]
        const { xmlimportlogs, userId, searchData } = this.props;
        const { page } = xmlimportlogs;
        let { sFields, sValues } = searchData;
        // this.setState({ deleteProj: true, deleteItemId: id });
        this.props.deleteProject(id,'xmlimportlogs',()=>{
            if (page.skip) {
                this.props.getTable1logList(page.skip, page.limit, page.orderBy, page.order, sFields, sValues, false, userId);
            } else {
             //   this.props.page === 'projects' ?
                    this.props.getTable1logList(1, globalVariable.tableRowSize, 'name', 'asc', '', '', false, userId) 
                   // this.props.getTable1logList(1, globalVariable.subPageTabtableRowSize, 'name', 'asc', '', '', false, userId)
            }
        })
        
        // [2]
        // xmlimportlogs.data.forEach((item, index) => {
        //     if (id === item._id) {
        //         this.noOfSites = item.sites;
        //     }
        // })
        // if (this.noOfSites === '0') {
        //     this.props.deleteProject(id)
        //     this.setState({ deleteProj: false })
        //     this.props.getTable1logList(page.skip, globalVariable.tableRowSize, page.orderBy, page.order, sFields, sValues, false, userId);

        // }
    };
    handleXmlEdit = (item) => {
    
        history.push({
            pathname: `/table1-import`,
            state: {
                page: 'table1Import',
                pageData: {
                    item,
                    screen:'edit'
                }
            }
        });
    }

    handleXmlEmailSend = (item) => {

        history.push({
            pathname: `/table1-import-log/email`,
            state: {
                page: 'table1ImportEmail',
                pageData: {
                    item
                }
            }
        });

    }  
   //file to download error file
       handleDownloadHtmActionClick = (item) => {
        const {tablelogLoading} = this.props;
        if (!tablelogLoading) {
            this.props.Table1ErrorLog(item)
        } else {
            this.props.successAlert('Please wait...Download already in progress');
            setTimeout(() => {
                this.props.clearAlert();
            }, 3000)
        }
       
    }
     

    handleDownloadActionClick = (item) => {
        const requestFileOptions = {
            method: 'POST',
            headers: {
                ...authHeader()
            },

        body: JSON.stringify({item })
        };

      
        fetch(`${globalVariable.ms_apiURL}/table1-read-xml-file`, requestFileOptions) .then(handleResponse).then(response => {
         
               const xmlFormattedString = response.data;
               const errors = JSON.parse(item.xmlErrors);
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        ...authHeader()
                    },
                    body: JSON.stringify({
                        xmlFormattedString,
                        errors:errors
                    })
                };
             
                 fetch(`${globalVariable.ms_apiURL}/table1-error`, requestOptions) .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download =item.filename.split('.')[0]+'.html';
                        a.click();
                    });
              
                 });
       });
     
    };
    /**
     * A method to call when copy icon clicked, copy the project details to new project
     * @param {*} id 
     */
    handleTableCopyAction = (id) => {
        history.push({
            pathname: `/projects/new/${id}`,
            state: {
                page: 'projectNew',
                pageData: {}
            }
        });
    }
    /**
     * Handle Field Component On Change Event
     * @param {*} name 
     * @param {*} data 
     */
    fieldComponentOnChange = (name, data) => {
     
        switch (name) {
            case "funders":
                const { _id, name: funderName, org_id, modifiedBy, updatedAt } = data.funderKey;
                if (_id) {
                    history.push({
                        pathname: `/projects/${data._id}/funders/${_id}`,
                        state: {
                            page: 'funderEdit',
                            pageData: {
                                funder_id: _id,
                                orgId: org_id
                            },
                            pageTitle: {
                                // title: '',
                                title: `${funderName} | ${data.name}`,
                                subTitle: modifiedBy ? `Last modified by: <b>${modifiedBy}</b> on <b>${formatDate(updatedAt)}</b>` : ''
                                // subTitle: ''
                            }
                        }
                    });
                }
                break;
            default:
        }
    }
    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
    /**
     * Handle Upload Click Action
     */
    // handleTable1ImportActionClick = () => {
    //     history.push({
    //         pathname: `/table1-list`,
    //         state: {
    //             page: 'table1Import',
    //             pageData: {}
    //         }
    //     });
    // }
    handleTable1ImportActionClick = () => {
        history.push({
            pathname: `/table1-list`,
            state: {
                page: 'table1LogList',
                pageData: {}
            }
        });
    }
    /**
    * For merging projects
    */
    handleMergeActionClick = () => {

        history.push({
            pathname: '/merge',
            state: {
                page: 'mergeProjects',
                pageData: {}
            }
        })
    }
    /**
     * To handle bulk upload navigation
     * @param {*} page 
     */
    handleBulkUploadNavigation = (page) => {
        history.push({
            pathname: `/bulk-upload/${page}`,
            state: {
                page: 'bulkUpload',
                pageData: {}
            }
        })
    }
    deleteProject=()=>{
        const { xmlimportlogs, userId, searchData: { sFields, sValues } } = this.props;
        const { page } = xmlimportlogs;
        
        this.setState({ deleteProj: false });
        this.props.deleteProject(this.state.deleteItemId,'xmlimportlogs',()=>{
            this.props.getTable1logList(page.skip, page.limit, page.orderBy, page.order, sFields, sValues, false, userId);
        })
    }
    /**
     * Bind Html to DOM
     * 
     * [1]  -   remove download icon
     */
    render() {
        let {
            xmlimportlogs, columnsMap, visibleColumns, tableActionRequired,
            removeActions, location: { pathname }, title, pageAccess,
            isSubPage, classes, needPadding, helpData,
            searchData: { sFields, sValues }
        } = this.props;
       
        const { userRole, userOrgId } = this;
        const { fieldFilter, openHelp, anchorEl } = this.state;
        let search = xmlimportlogs;
        // search.data.map(item=>{
        //     item['createdDate'] = formatDateWithPrefixZero(item['createdDate'])
        //     item['importedTime'] = unixTimeToHourMinute(item['createdDate'])
        // })
        const fieldFilters = search.page.fieldFilters;
        pageAccess = _.difference(pageAccess, removeActions);
        // [1]
        const downloadIndex = pageAccess.indexOf('DOWNLOAD');
        if (this.props.userId && downloadIndex > -1) {
            pageAccess.splice(downloadIndex, 1);
        }
        pageAccess = [...pageAccess, 'HELP']
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title}
                isSubPage={isSubPage}
                handleAddActionClick={this.handleAddActionClick}
                handleHelpActionClick={this.handleHelpActionClick}
                needPadding={needPadding}
              
                handleTable1ImportActionClick={this.handleTable1ImportActionClick}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleBackActionClick={()=>{history.goBack()}}
               
            >
                <TableComponent
                    actionClassName={'XmlFlexEnd'}
                    page={search.page.skip}
                    rowsPerPage={search.page.limit}
                    count={search.page.count}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    fieldComponentOnChange={this.fieldComponentOnChange}
                    actions={tableActionRequired === true ? pageAccess : []}
                    userOrgId={userOrgId}
                    userRole={userRole}
                    currentPage={'Table1List'}
                    // links={['filename']}
                    order={search.page.order}
                    orderBy={search.page.orderBy}
                    data={search.data}
                    // highlightField={['filename']}
                    isLoading={false}
                    onChangePageTable={this.onChangePageTable}
                    onSortRequest={this.onSortRequest}
                    onInputChangeRequest={this.onInputChangeRequest}
                     handleTableDeleteAction={this.handleTableDeleteAction}
                    handleXmlEdit={(item) =>{this.handleXmlEdit(item)}}
                    handleXmlEmailSend={(item) =>{this.handleXmlEmailSend(item)}}
                    handleDownloadAction={(item) => {
                        this.handleDownloadActionClick(item);
                    }}
                    handleDownloadhtmAction={(item) => {
                        this.handleDownloadHtmActionClick(item);
                    }}
                    handleTableCopyAction={this.handleTableCopyAction}
                    resetSearchContent={this.props.location.hash === "#clear"}
                    sFields={filterSearchFields(sFields)}
                    sValues={sValues}
                    handleNavigateAction={this.handleNavigateAction}
                    noDataErrorMessage={"No Records Found"}
                    prevNext={false}
                    showLoading={isSubPage}
                    paginationVisible={true}
                    disablePagination={false}
                />
                <FieldFilter
                    open={fieldFilter}
                    handleOnClose={this.handleFieldFilterOnClose}
                    handleOnChange={this.handleFieldFilterOnChange}
                    handleOnSave={this.handleFieldFilterOnSave}
                    columnsMap={columnsMap}
                    visibleColumns={visibleColumns}
                />
                {/* <DeleteDialog
                    open={this.state.deleteProj !== false}
                    content={"Are you sure to delete the log?"}
                    handleDeleteAction={() => this.deleteProject()}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ deleteProj: false })
                    }}
                /> */}
                {
                    openHelp && <HelpFloater
                        handleCloseFloater={() => this.setState({ openHelp: false })}
                        {...helpData}
                        title="XML Import"
                    />
                }
               
            </ContentWrapper>

        );
    }
}
/**
 * Bind Component Property Types
 */
 Table1Log.propTypes = {
    classes: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    title: PropTypes.string,
    removeActions: PropTypes.array,
    isSubPage: PropTypes.bool
};
/**
 * Default Props Types
 */
 Table1Log.defaultProps = {
    needPadding: false,
    tableActionRequired: true,
    title: "Table1 Import Logs",
    removeActions: [],
    isSubPage: false
};
/**
 * A method to map the actions from different pages for same containers
 * @param {*} page 
 * @param {*} state 
 * @returns 
 */
const getPageState = function (page, state) {
    switch (page) {
        case 'projects': return state.projects;
        case 'users': return state.users.projects;
        default: break;
    }
}
/**
 * Map Page Action
 * @param {*} page 
 * @returns 
 */
const getPageAction = function (page) {
    return Table1LogActions.getTable1log;
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = ownProps.userId ? state.configuration.fieldFilter['xmlimportlogs'] || {} : state.configuration.fieldFilter['xmlimportlogs'] || {};
    const pageAccess = state.pageAccess['table1LogList'] || [];
    const xmlimportlogs = state.table1Log;
    const searchIndexFor = ownProps.userId ? 'xmlimportlogs' : 'xmlimportlogs';
    const searchData = state.search['table1XMLLog'] || { sFields: [], sValues: [] };
    const helpData = state.help.data[0]
    const tablelogLoading = state.table1Log.doubleClick
    return {
        xmlimportlogs,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        pageAccess,
        searchData,
        helpData,
        tablelogLoading
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @param {*} ownProps 
 * @returns 
 */
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        resetFilter: () => dispatch(Table1LogActions.resetFilter()),
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        getTable1logList: (skip, limit, orderBy, order, sField, sValue, exactMatch, userId) => {
           const actionToCall = getPageAction(ownProps.page);
            dispatch(actionToCall(skip, limit, orderBy, order, sField, sValue, exactMatch, userId))
        },
        deleteProject: (id,page,successCallback) => dispatch(Table1LogActions.delete(id,page,successCallback)),
        searchActions: (data) => {
                return dispatch(searchActions.searchTable1ImportLogs(data));
          
        },
        getHelp: () => dispatch(helpActions.getAll({ _id: 25 })),
        Table1ErrorLog: (fileName) => dispatch(Table1LogActions.getErrorLogFile(fileName))
    }
   
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(Table1Log));
