export const mergeConstants = {
    // site dropdown
    GET_SITE_NAME_REQUEST: 'SITES_GETBY_PROJECTID_REQUEST',
    GET_SITE_NAME_SUCCESS: 'SITES_GETBY_PROJECTID_SUCCESS',
    GET_SITE_NAME_FAILURE: 'SITES_GETBY_PROJECTID_FAILURE',

    // project dropdown
    GET_ALL_PROJECTS_NAME_REQUEST: 'PROJECTS_LIST_REQUEST',
    GET_ALL_PROJECTS_NAME_SUCCESS: 'PROJECTS_LIST_SUCCESS',
    GET_ALL_PROJECTS_NAME_FAILURE: 'PROJECTS_LIST_FAILURE',

    // organization dropdown
    GET_ALL_ORGANIZATION_NAME_REQUEST: 'GET_ALL_ORGANIZATION_NAME_REQUEST',
    GET_ALL_ORGANIZATION_NAME_SUCCESS: 'GET_ALL_ORGANIZATION_NAME_SUCCESS',
    GET_ALL_ORGANIZATION_NAME_FAILURE: 'GET_ALL_ORGANIZATION_NAME_FAILURE',


    // building dropdown
    GET_BUILDING_NAME_REQUEST: 'BUILDINGS_GETBY_SITEID_REQUEST',
    GET_BUILDING_NAME_SUCCESS: 'BUILDINGS_GETBY_SITEID_SUCCESS',
    GET_BUILDING_NAME_FAILURE: 'BUILDINGS_GETBY_SITEID_FAILURE',

    // unit dropdown
    GET_UNIT_NAME_REQUEST: 'UNITNAME_GET_REQUEST',
    GET_UNIT_NAME_SUCCESS: 'UNITNAME_GET_SUCCESS',
    GET_UNIT_NAME_FAILURE: 'UNITNAME_GET_FAILURE',

    // project merge
    MERGE_PROJECT_REQUEST: 'PROJECT_MERGE_REQUEST',
    MERGE_PROJECT_SUCCESS: 'PROJECT_MERGE_SUCCESS',
    MERGE_PROJECT_FAILURE: 'PROJECT_MERGE_FAILURE',

    // site merge
    MERGE_SITE_REQUEST: 'SITE_MERGE_REQUEST',
    MERGE_SITE_SUCCESS: 'SITE_MERGE_SUCCESS',
    MERGE_SITE_FAILURE: 'SITE_MERGE_FAILURE',

    // building merge
    MERGE_BUILDING_REQUEST: 'BUILDING_MERGE_REQUEST',
    MERGE_BUILDING_SUCCESS: 'BUILDING_MERGE_SUCCESS',
    MERGE_BUILDING_FAILURE: 'BUILDING_MERGE_FAILURE',

    // unit merge
    MERGE_UNIT_REQUEST: 'UNIT_MERGE_REQUEST',
    MERGE_UNIT_SUCCESS: 'UNIT_MERGE_SUCCESS',
    MERGE_UNIT_FAILURE: 'UNIT_MERGE_FAILURE',

    // organization merge
    MERGE_ORGANIZATION_REQUEST: 'ORGANIZATION_MERGE_REQUEST',
    MERGE_ORGANIZATION_SUCCESS: 'ORGANIZATION_MERGE_SUCCESS',
    MERGE_ORGANIZATION_FAILURE: 'ORGANIZATION_MERGE_FAILURE',

    // organization merge email notification
    MERGE_ORGANIZATION_NOTIFICATION_REQUEST:'ORGANIZATION_MERGE_NOTIFICATION_REQUEST',
    MERGE_ORGANIZATION_NOTIFICATION_SUCCESS:'ORGANIZATION_MERGE_NOTIFICATION_SUCCESS',
    MERGE_ORGANIZATION_NOTIFICATION_FAILURE:'ORGANIZATION_MERGE_NOTIFICATION_FAILURE',


    // clear merge data
    CLEAR_MERGE_SELECTION_DATA: 'MERGE_CLEAR_SELECTION_DATA'

}