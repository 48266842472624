import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom'

// Material UI
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';

// Import Theme Override JSS File
import theme from '../assets/jss/theme_overrides';
import homeTheme from '../assets/jss/homeownershipTheme'

//Imports Routes
import { PrivateRoute, PublicRoute } from '../components/Routes/index'

//Imports Components
import SnackBarComponent from "../components/SnackBar/SnackBar"
import Upload from "../components/FileUpload/FileUpload";
import PageLoader from '../components/PageLoader/PageLoader';

//Imports Container
import ProjectsRouter from "./Projects/Projects.Router";
import Dashboard from "./Dashboard/Dashboard";
import DashboardHO from "./DashboardHO/DashboardHO"
import Login from "./Login/Login";
import UsersRouter from './Users/Users.Router';
import SitesRouter from './Sites/Sites.Router';
import OccupantsRouter from './Occupants/Occupants.Router';
import OrganizationsRouter from './Organizations/Organizations.Router';
import UARouter from "./UtilityAllowance/UA.Router";
import Compliance from './Compliance/Compliance';
import Configurations from './Configurations/Configurations';
import HelpRouter from './Help/HelpRouter';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ChangePassword from './ChangePassword/ChangePassword';
import BuildingRouter from './Buildings/Building.Router';
import UnitsRouter from './Units/Units.Router';
import ComplianceSetting from './Compliance/ComplianceSetting';
import ExploreRouter from './Explore/Explore.Router';
import Table1Import from "./Imports/Table1";
import Table1ImportSuccessPage from "./Imports/Table1SuccessPage";
import ReportActivityImport from "./Imports/ReportActivity/ReportActivityImport";
import MergeRouter from './Merge/Merge.Router';
import Print from './Print/Print';
import Archives from './Archives/Archive';
import MailRouter from './Mail/Mail.Router'
import IncomeRentRouter from './IncomeRent/IncomeRent.Router'
import LoanProjectsRouter from './LoanProjects/LoanProjects.Router';
import Logs from './IncomeRent/Logs/Logs';
import BulkUploadRouter from './BulkUpload/BulkUpload.Router';
import Table1LogRouter from './XmlLogs/XmlLogs.Router';

//Helpers
import { history } from '../helpers';

// Actions
import { maintenanceActions } from '../actions/maintenance';
import { alertActions } from '../actions';

/**
 * App Container
 * @class App
 * @extends {Component}
 */
class App extends Component {
    /**
     * Constructor
     * @param {*} props 
     * 
     * [1]  -   clear alert on location change
     */
    constructor(props) {
        super(props);
        history.listen((location, action) => {
            // [1]
            this.props.clearAlerts();
        });
    }
    /**
     * Component Will Mount
     */
    UNSAFE_componentWillMount() {
        this.props.maintanance();
    }
    /**
     * Handle close
     */
    handleClose = () => {
        const { alert } = this.props;
        if (alert && alert.redirect === true) {
            history.push("/login")
        }
        this.props.clearAlerts()
    }
    /**
     * Bind Html to DOM 
     * @returns
     * 
     * Routing to components using path 
     */
    render() {
        var uitheme = theme;
        if (global.localStorage.user) {
            if (history.location.pathname.includes('loanProjects') || history.location.pathname.includes('dashboardho')) {
                uitheme = homeTheme
            } else {
                uitheme = theme
            }
        }
        const { alert, isLoading, maintenance } = this.props;
        if (maintenance && maintenance.maintenance) {
            return (
                <React.Fragment>
                    <CssBaseline />
                    <MuiThemeProvider theme={uitheme}>
                        <Switch>
                            <PublicRoute exact path="/" component={Login} />
                            <PublicRoute exact path="/login" component={Login} />
                            <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
                            <PublicRoute exact path="/change-password/:hash" component={ChangePassword} />
                            <PrivateRoute path="/projects" component={ProjectsRouter} />
                            <PrivateRoute path="/occupants" component={OccupantsRouter} />
                            <PrivateRoute path="/users" component={UsersRouter} />
                            <PrivateRoute path="/organizations" component={OrganizationsRouter} />
                            {/*income rent limit*/}
                            <PrivateRoute path="/incomerent" component={IncomeRentRouter} />
                            <PrivateRoute path="/logs" component={Logs} />
                        </Switch>
                    </MuiThemeProvider>
                    <React.Fragment>
                        {alert && alert.type &&
                            <SnackBarComponent type={alert.type} message={alert.message} open={true} onClose={this.handleClose} redirect={alert.redirect} />
                        }
                    </React.Fragment>
                    <PageLoader show={isLoading} />
                    <Print />
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <CssBaseline />
                <MuiThemeProvider theme={uitheme}>
                    <Switch>
                        <PublicRoute exact path="/" component={Login} />
                        <PublicRoute exact path="/login" component={Login} />
                        <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
                        <PublicRoute exact path="/change-password/:hash" component={ChangePassword} />
                        <PrivateRoute exact path="/dashboard" component={Dashboard} />
                        <PrivateRoute exact path="/dashboardho" component={DashboardHO} />
                        <PrivateRoute path="/projects" component={ProjectsRouter} />
                        <PrivateRoute path="/users" component={UsersRouter} />
                        <PrivateRoute path="/buildings" component={BuildingRouter} />
                        <PrivateRoute path="/units" component={UnitsRouter} />
                        <PrivateRoute path="/sites" component={SitesRouter} />
                        <PrivateRoute path="/occupants" component={OccupantsRouter} />
                        <PrivateRoute path="/organizations" component={OrganizationsRouter} />
                        <PrivateRoute path="/ua" component={UARouter} />
                        <PrivateRoute exact path="/compliance" component={Compliance} />
                        <PrivateRoute path="/explore" component={ExploreRouter} />
                        <PrivateRoute exact path="/compliance/setting" component={ComplianceSetting} />
                        <PrivateRoute path="/configurations" component={Configurations} />
                        <PrivateRoute path="/help/:type" component={HelpRouter} />
                        <PrivateRoute path="/upload" component={Upload} />
                        <PrivateRoute path="/table1-import" component={Table1Import} />
                        <PrivateRoute path="/table1-import-success" component={Table1ImportSuccessPage} />
                        <PrivateRoute path="/table1-import-log" component={Table1LogRouter} />
                        <PrivateRoute path="/merge" component={MergeRouter} />
                        <PrivateRoute path="/archives" component={Archives} />
                        <PrivateRoute path="/mailTemplate" component={MailRouter} />
                        <PrivateRoute path="/loanProjects" component={LoanProjectsRouter} />
                        <PrivateRoute path="/bulk-upload" component={BulkUploadRouter} />
                        <PrivateRoute path="/reportActivityImport" component={ReportActivityImport} />
                    </Switch>
                </MuiThemeProvider>
                <React.Fragment>
                    {alert && alert.type &&
                        <SnackBarComponent type={alert.type} message={alert.message} open={true} onClose={this.handleClose} />
                    }
                </React.Fragment>
                <PageLoader show={isLoading} />
                <Print />
            </React.Fragment>
        )
    }
}
/**
 * Map State to Props
 * @param {*} state 
 * @returns 
 */
function mapStateToProps(state) {
    const { dashboard, occupants, alert, maintenance: { data },table1Log } = state;
    return {
        alert,
        maintenance: data,
        isLoading: state.projects.isLoading
            || state.sites.isLoading
            || state.buildings.isLoading
            || state.units.isLoading
            || state.occupants.isLoading
            || state.organizations.isLoading
            || state.users.isLoading
            || state.compliance.isLoading
            || dashboard.projectSummary.isLoading
            || state.reportTables.isLoading
            || state.reportActivity.isLoading
            || state.reportTables.table1.isLoading
            || state.reportTables.table2.isLoading
            || state.reportTables.table3.isLoading
            || state.reportTables.table4.isLoading
            || state.help.isLoading
            || state.print.isLoading
            || occupants.isLoading
            || state.projects.isReportListLoading
            || state.mailTemplates.isLoading
            || state.maintenance.isLoading
            || state.loanProjects.isLoanProjectLoading
            || state.contract.isLoading
            || table1Log.isLoading
    };
}
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        maintanance: () => dispatch(maintenanceActions.getMaintenanceStatus()),
        clearAlerts: () => dispatch(alertActions.clear())
    }
};
/** Export Component */
const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export { connectedApp as App };
