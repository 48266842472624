//import services
import { mergeService } from '../services/merge';
import { Table4Service, projectsService } from "../services";

//import constants
import { mergeConstants } from '../constants/merge';

//import actions
import { alertActions } from './';

export const mergeActions = {
    getProjectsByName,
    getSitesByProjectId,
    getBuildings,
    getUnits,
    projectMerge,
    siteMerge,
    buildingMerge,
    unitMerge,
    clearMergeSelectionDate,
    getOrganizationList,
    organizationMerge,
}

/**
 *get list of sites based on project id
 *
 * @param {*} name - project name
 * @param {*} id - project_id
 * @returns
 */
function getSitesByProjectId(name, id) {
    return dispatch => {
        dispatch(request(name));
        mergeService.getSitesByProjectId(name, id)
            .then(
                sites => {
                    sites.data.map((rt) => {
                        rt.label = rt.name;
                        rt.value = rt._id;
                        return rt;
                    });
                    dispatch(success(name, sites.data))
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request(name) { return { type: mergeConstants.GET_SITE_NAME_REQUEST, name } }
    function success(name, sites) { return { type: mergeConstants.GET_SITE_NAME_SUCCESS, name, sites } }
    function failure(error) { return { type: mergeConstants.GET_SITE_NAME_FAILURE, error } }
}

/**
 * get list of projects 
 * @param  text 
 */
function getProjectsByName(text = '') {
    return dispatch => {
        dispatch(request());

        mergeService.getProjectsByName(text)
            .then(
                projects => {
                    dispatch(success(projects.data))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: mergeConstants.GET_ALL_PROJECTS_NAME_REQUEST } }
    function success(suggestions) { return { type: mergeConstants.GET_ALL_PROJECTS_NAME_SUCCESS, suggestions } }
    function failure(error) { return { type: mergeConstants.GET_ALL_PROJECTS_NAME_FAILURE, error } }
}

/**
 * get list of organizations 
 */

function getOrganizationList(){
    return dispatch =>{
        dispatch(request());

        mergeService.getOrganizationName()
        .then(
            org=>{
                dispatch(success(org.data))
            },
            error=>dispatch(failure(error.toString()))
            )
    }

    function request(){return {type: mergeConstants.GET_ALL_ORGANIZATION_NAME_REQUEST}}
    function success(orglist) {return {type:mergeConstants.GET_ALL_ORGANIZATION_NAME_SUCCESS,orglist}}
    function failure(error){return {type:mergeConstants.GET_ALL_PROJECTS_NAME_FAILURE,error}}
}

/**
 *get list of buildings based on site id
 *
 * @param {*} name - site name
 * @param {*} id - site_id
 * @returns
 */
function getBuildings(name, id) {
    return dispatch => {
        dispatch(request(name));
        mergeService.getBuildings(name, id)
            .then(
                buildings => {
                    buildings.data.map((rt) => {
                        rt.label = rt.name ? rt.name : rt.bin;
                        rt.value = rt._id;
                        return rt;
                    });
                    dispatch(success(name, buildings.data))
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request(name) { return { type: mergeConstants.GET_BUILDING_NAME_REQUEST, name } }
    function success(name, buildings) { return { type: mergeConstants.GET_BUILDING_NAME_SUCCESS, name, buildings } }
    function failure(error) { return { type: mergeConstants.GET_BUILDING_NAME_FAILURE, error } }
}

/**
 *get list of units based on building id
 *
 * @param {*} name - building name
 * @param {*} id - building_id
 * @returns
 */
function getUnits(name, id) {
    return dispatch => {
        dispatch(request(name));
        mergeService.getUnits(name, id)
            .then(
                units => {
                    units.data.map((rt) => {
                        rt.label = rt.common_id;
                        rt.name = rt.common_id;
                        rt.value = rt._id;
                        return rt;
                    });
                    dispatch(success(name, units.data))
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request(name) { return { type: mergeConstants.GET_UNIT_NAME_REQUEST, name } }
    function success(name, units) { return { type: mergeConstants.GET_UNIT_NAME_SUCCESS, name, units } }
    function failure(error) { return { type: mergeConstants.GET_UNIT_NAME_FAILURE, error } }
}

/**
 *Merge two projects
 *
 * @param {*} formdata
 * @param {*} callback
 * @returns
 */
function projectMerge(formdata, callback) {
    return dispatch => {
        dispatch(request());
        mergeService.projectMerge(formdata)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success("Project Merged Successfully"));
                    callback()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: mergeConstants.MERGE_PROJECT_REQUEST } }
    function success(data) { return { type: mergeConstants.MERGE_PROJECT_SUCCESS, data } }
    function failure(error) { return { type: mergeConstants.MERGE_PROJECT_FAILURE, error } }
}

/**
 *Merge organizations
 *
 * @param {*} formdata
 * @param {*} callback
 * @returns
 */
 function organizationMerge(formdata, callback) {
    return dispatch => {
        dispatch(request());
        mergeService.organizationMerge(formdata)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success("Organizations Merged Successfully"));
                    callback()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: mergeConstants.MERGE_ORGANIZATION_REQUEST } }
    function success(data) { return { type: mergeConstants.MERGE_ORGANIZATION_SUCCESS, data } }
    function failure(error) { return { type: mergeConstants.MERGE_ORGANIZATION_FAILURE, error } }
}

/**
 *Merge two sites in a project
 *
 * @param {*} formdata
 * @param {*} callback
 * @returns
 */
function siteMerge(formdata, callback) {
    return dispatch => {
        dispatch(request());
        mergeService.siteMerge(formdata)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success("Site Merged Successfully"));
                    callback()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: mergeConstants.MERGE_SITE_REQUEST } }
    function success(data) { return { type: mergeConstants.MERGE_SITE_SUCCESS, data } }
    function failure(error) { return { type: mergeConstants.MERGE_SITE_FAILURE, error } }
}

/**
 * Merge two buildings in a site
 * 
 * Building Merge Function
 */
function buildingMerge(formdata, callback) {
    return dispatch => {
        dispatch(request());
        mergeService.buildingMerge(formdata)
            .then(
                data => {
                    projectsService.getProjectReport(1, 1, 'year', 'desc', '', '', data.data.details.project2,false,{isDelete: false}).then((res)=>{
                        Table4Service.getConvertedTable4Data(res.data&&res.data[0]._id, 'projects')
                    })
                    dispatch(success(data));
                    dispatch(alertActions.success("Building Merged Successfully"));
                    callback()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: mergeConstants.MERGE_BUILDING_REQUEST } }
    function success(data) { return { type: mergeConstants.MERGE_BUILDING_SUCCESS, data } }
    function failure(error) { return { type: mergeConstants.MERGE_BUILDING_FAILURE, error } }
}

/**
 * Merge two units in a building
 */
function unitMerge(formdata, callback) {
    return dispatch => {
        dispatch(request());
        mergeService.unitMerge(formdata)
            .then(
                data => {
                    projectsService.getProjectReport(1, 1, 'year', 'desc', '', '', data.data.details.project2,false,{isDelete: false}).then((res)=>{
                        Table4Service.getConvertedTable4Data(res.data&&res.data[0]._id, 'projects')
                    })
                    dispatch(success(data));
                    dispatch(alertActions.success("Unit Merged Successfully"));
                    callback()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: mergeConstants.MERGE_UNIT_REQUEST } }
    function success(data) { return { type: mergeConstants.MERGE_UNIT_SUCCESS, data } }
    function failure(error) { return { type: mergeConstants.MERGE_UNIT_FAILURE, error } }
}

function clearMergeSelectionDate(tabName) {
    return { type: mergeConstants.CLEAR_MERGE_SELECTION_DATA, tabName }
}




